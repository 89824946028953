<!-- BCD 안내 모달 -->
<div class="modal-content">
    <div class="modal-body bg-stand relative rounded ">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">BCD에 대하여</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-4 pb-6 ">
            <div class="relative">
                <img src="/assets/custom_img/bonus/bcd-1.webp" alt="">
                <a href="javascript:;" class="absolute left-11 bottom-1/3 text-white">컨트랙트 보기 <svg class="inline-flex w-4 h-4 fill-white"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a>
            </div>
            <div class="mt-4 bg-back p-4 font-medium">
                
                <span class="text-tit">BCD 란?</span><br/><br/>

                BCD(BC 달러)는 KBC GAME에서 출시한 특별 통화입니다. BCD로 게임, 팁, 코인 드롭, 레인을 즐길 수 있습니다.<br/><br/>

                <span class="text-tit">BCD 를 다른 가상화폐로 환전할 수 있나요?</span><br/><br/>

                전적으로! BCD를 <button class="text-primary" onclick="tabchange('swap')">BC 스왑</button>로 언제든지 다른 통화로 교환할 수 있습니다.<br/><br/>

                <span class="text-tit">BCD 의 특별한 점은 무엇인가요??</span><br/><br/>

                볼트프로에 BCD를 저장하시면 최대 10%의 연간 수익률을 누릴 수 있습니다. BCD 수집을 즐기십시오!
            </div>
        </div>
    </div>
</div>
