import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { ModalService } from '../../common/modal/modal.service';

declare const Swiper:any

@Component({
  selector: 'app-affiliate',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './affiliate.component.html',
  styles: ``
})
export class AffiliateComponent {
    private subs: Subscription[] = [];

    currentUser!:currentUserInfo | null;

    get modal() { return this.modalService }
    constructor(
        private authService: AuthService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngAfterContentInit() {
        var swiper = new Swiper(".live_slide_box", {
            direction: "vertical",
           slidesPerView: 1,
           autoplay:{
               delay: 1000,
               disableOnInteraction: false,
           },
       });
    }
    
}
