import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, ViewChild } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { CommonModule } from '@angular/common';
import { ModalService } from '../../common/modal/modal.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Swiper, SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { SwiperNoticeComponent } from './swiper-notice.component';
import { PopupComponent } from '../../common/popup/popup.component';
import { ContestComponent } from './contest.component';
import { BigWonService } from '../../service/big_won.service';
import { ApiService } from '../../service/api.service';
import { ImgxDirective } from '@app/shared/lib/directive/imgx.directive';


@Component({
    selector: 'app-main',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        SwiperNoticeComponent,
        ContestComponent,
        SwiperDirective,
        PopupComponent,
        ImgxDirective
    ],
    templateUrl: './main.component.html',
    styles: ``,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainComponent {
    private subs: Subscription[] = [];

    currentUser!:currentUserInfo | null;

    bigWonOptions!:SwiperOptions
    bigWonItems!:any
    bigWonSwiper!:Swiper;

    bigWonMobileOptions!:SwiperOptions
    bigWonMobileItems!:any
    bigWonMobileSwiper!:Swiper;
    
    liveCasinoOptions!:SwiperOptions
    liveCasinoItems!:any

    slotLobbyOptions!:SwiperOptions
    slotLobbyItems!:any

    kbcgameOptions!:SwiperOptions
    kbcgameItems!:any


    get modal() { return this.modalService }
    constructor(
        private authService: AuthService,
        private modalService: ModalService,
        private router: Router,
        private bigWonService: BigWonService,
        private apiService: ApiService
    ) {
        
    }

    ngOnInit(): void {
        // onSameUrlNavigation
        this.subs.push(
            this.router.events.subscribe((e: any) => {
                if(e instanceof NavigationEnd) {
                    this.fnGetData()
                }
            })
        )

        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        // 빅원 - 모바일
        this.bigWonMobileOptions = {
            direction: "vertical",
            slidesPerView: 3,
            spaceBetween: 10,
            autoplay:{
                delay: 300,
                disableOnInteraction: false,
            },
            on: {
                init: (swiper:Swiper)  => {
                    if(this.bigWonItems.length > 0) {
                        swiper.params.loop = true;
                        swiper.update()
                        swiper.autoplay.start();
                    }

                    this.bigWonMobileSwiper = swiper;
                },
            },
        }
        
        // 빅원
        this.bigWonOptions = {
            slidesPerView: 2,
            spaceBetween: 10,
            autoplay:{
                delay: 300,
                disableOnInteraction: false,
                // reverseDirection: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                },
                815:{
                    slidesPerView: 4,
                },
                990:{
                    slidesPerView: 5,
                },
                1100:{
                    slidesPerView: 6,
                },
                1335:{
                    slidesPerView: 7,
                }
            },
            on: {
                init: (swiper:Swiper)  => {
                    if(this.bigWonItems.length > 0) {
                        swiper.params.loop = true;
                        swiper.update()
                        swiper.autoplay.start();
                    }

                    this.bigWonSwiper = swiper;
                },
            },
        }
        
        // 라이브 카지노
        this.liveCasinoOptions = {
            slidesPerView: 4,
            spaceBetween: 10,
            breakpoints: {
                768: {
                    slidesPerView: 4,
                },
                815:{
                    slidesPerView: 4,
                },
                990:{
                    slidesPerView: 5,
                },
                1100:{
                    slidesPerView: 6,
                },
                1335:{
                    slidesPerView: 7,
                }
            },
            navigation: {
                nextEl: ".casino_con02 .swiper-next",
                prevEl: ".casino_con02 .swiper-prev",
            },
        }

        this.slotLobbyOptions = { ...this.liveCasinoOptions, navigation: { nextEl: ".casino_con03 .swiper-next", prevEl: ".casino_con03 .swiper-prev" }};
        this.kbcgameOptions =  { ...this.liveCasinoOptions, navigation: { nextEl: ".casino_con04 .swiper-next", prevEl: ".casino_con04 .swiper-prev" }};

        

        this.bigWonService.getData().subscribe(v => {
            this.bigWonItems = v;
            this.bigWonMobileItems = v;

            if(this.bigWonSwiper && this.bigWonItems.length > 0) {
                this.bigWonSwiper.params.loop = true;
                this.bigWonSwiper.update()
                this.bigWonSwiper.autoplay.start()
            }

            if(this.bigWonMobileSwiper && this.bigWonMobileItems.length > 0) {
                this.bigWonMobileSwiper.params.loop = true;
                this.bigWonMobileSwiper.update();
                this.bigWonMobileSwiper.autoplay.start()
            }
            // console.log(this.bigWonSwiper)
        })

        this.fnGetData();
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }


    fnGetData() {
        this.apiService.get('/main', false).subscribe((jsondata:any) => {
            if(jsondata.success && jsondata.data) {
                for (const [key, value] of Object.entries(jsondata.data)) {
                    if(key == 'live_casino') this.liveCasinoItems = value;
                    else if(key == 'slot_lobby') this.slotLobbyItems =value;
                    else if(key == 'kbcgame') this.kbcgameItems =value;
                }
            }
        })
    }

    Go($event:any = null, url:string='') {
        if($event) $event.preventDefault();
        if(!url) url = ($event.currentTarget as HTMLInputElement).getAttribute('href') || ''

        this.router.navigateByUrl(url);
    }
}
