import { ChangeDetectorRef, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './loading.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'common-loading',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './common-loading.component.html',
  styleUrls: [`./common-loading.component.css`],
})
export class CommonLoadingComponent {
    private subs: Subscription[] = [];

    loading:number = 0;
    constructor(
        private loadingService: LoadingService,
    ) {

    }

    ngOnInit(): void {
        this.subs.push(
            this.loadingService.getLoading().subscribe((v:boolean) => {
                if(v) this.loading++
                else this.loading--

                if(this.loading < 0) this.loading = 0;
            })
        );

    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
