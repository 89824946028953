import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApiService } from '../../service/api.service';

@Injectable({
  providedIn: 'root'
})
export class DirectMessageService {
    private subject:BehaviorSubject<Array<any>> = new BehaviorSubject<any>([]);

    constructor(
        private apiService: ApiService
    ) {

    }

    public getData() {
        return this.subject.asObservable() 
    }

    public init() {
        this.subject.next([])
    }

    public update() {
        this.apiService.get('/direct-message').subscribe(jsondata => {
            if(jsondata.success) {
                const items = jsondata.data.items.map((item:any) => {
                    return item = { ...item, CMD:'MESSAGE'}
                })
                this.subject.next(items)
            }
        })
    }
}