import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router  } from '@angular/router';
import { AuthService, currentUserInfo } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { environment, environmentOptions } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { GetLevelImage } from '../../../common/common';
import { ModalService } from '../../../common/modal/modal.service';


@Component({
    selector: 'app-vip-club-modal',
    standalone: true,
    imports: [
        CommonModule
    ],
    templateUrl: './vip-club-modal.component.html',
    styles: ``
})
export class VipClubModalComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    
    tab:string = ''

    currentUser!:currentUserInfo | null;
    LEVEL_IMAGE:environmentOptions = environment.LEVEL_IMAGE
    GetLevelImage = GetLevelImage
    constructor(
        private router: Router,
        private authService: AuthService,
        private modalService: ModalService
    ) {
        
    }

    ngOnInit(): void {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
                if(!this.tab) {
                    if(this.currentUser) {
                        if(this.currentUser.level == 1) this.tab = 'bronze'
                        else if(this.currentUser.level == 2) this.tab = 'silver'
                        else if(this.currentUser.level == 3) this.tab = 'gold'
                        else if(this.currentUser.level == 4) this.tab = 'platinum'
                        else if(this.currentUser.level == 5) this.tab = 'diamond'
                    } else {
                        this.tab == 'bronze'
                    }
                }
                
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
    
    Go(url:string, $event:any = null): void {
        if($event) $event.preventDefault();

        this.router.navigateByUrl(url)
        this.onClose.emit()
    }

    GoModal(modal:string, $event:any = null): void {
        if($event) $event.preventDefault();

        this.onClose.emit()
        this.modalService.open(null, modal)
    }
}
