<!-- 레퍼럴 이용약관 모달 -->
<div class="modal-content">
    <div class="modal-body bg-stand relative rounded">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">레퍼럴 이용약관</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-4 py-6 max-h-[660px] overflow-y-auto scrollbar">
            <div>
                이 웹사이트는 KBC GAME 예측 플랫폼에서 지원합니다. 플랫폼 게임에는 Crash, Classic Dice 등이 있습니다.
                <br/><br/>
                KBC GAME 제휴 프로그램(이하 제휴 프로그램) 신청서를 작성하고 등록 양식에서이용약관(이하약관)에 동의합니다를 클릭하면 귀하(이하제휴사)가 준수하는 데 동의합니다. 본 계약에 명시된 모든 조건에 따라수수료 규칙의 수수료 구조도 본 계약의 불가분의 일부입니다. 본 계약에 명시된 조건에 따라 제휴사에 사전 통지 없이 언제든지 KBC GAME은 본 계약의 조항을 단독 재량으로 수정, 변경, 삭제 또는 확장할 수 있는 권리를 보유합니다. 귀하는 이에 동의합니다.
                <br/><br/>
                1. 제휴 프로그램 참여<br/>
                2. KBC GAME 제휴 웹사이트 및/또는 KBC GAME 마케팅 도구(이하 정의됨) 사용.<br/>
                3. KBC GAME의 제휴 커미션 수락이 본 계약 및 이에 대한 수정 사항에 대한 취소 불가능한 수락을 확인한다는 조건.<br/>
                따라서 귀하는 본 계약의 조건을 지속적으로 준수하고 KBC GAME 웹사이트의 일반 이용약관 및 개인정보 보호정책은 물론, 이후에 나온 기타 모든 규칙 및/또는 지침을 준수해야 합니다. 회사와 제휴사 간의 계약은 제휴 신청이 승인된 날부터 효력이 발생합니다.
                <br/><br/>
                1. 목적
                <br/><br/>
                1.1. 제휴사는 인터넷에서 하나 이상의 웹사이트(이하 웹사이트로 통칭)를 유지 및 운영하며, 다른 채널을 통해 잠재 고객을 추천합니다.<br/>
                1.2. 본 계약은 제휴사(이하 KBC GAME)의 웹사이트 KBC GAME 프로모션과 관련된 이용약관에 적용되며, 제휴사는 트래픽에 따라 본 계약에서 정의한 수수료를 지급받습니다. KBC GAME 및 본 계약 조건으로 전송됩니다.<br/>
                1.3. 순 게임 수익이라는 용어의 정의는 본 계약의 20조에서 찾을 수 있습니다. 향후 다른 제품 또는 제품 그룹이 도입되는 경우 KBC GAME은 각 제품에 대해 순 게임 수익이라는 용어의 개별 정의를 사용할 권리를 보유합니다.
                <br/><br/>
                2. 계열사 수락
                <br/><br/>
                2.1. 회사는 단독적이고 절대적인 재량으로 등록을 거부할 권리가 있습니다.
                <br/><br/>
                3. 자격 조건
                <br/><br/>
                3.1. 계열사는 다음을 보증합니다.:<br/>
                a) 계약에 동의하고 계약을 체결할 수 있는 해당 관할권의 법적 연령입니다.<br/>
                b) 법적 구속력이 있는 계약을 체결할 자격이 있고 정당한 권한이 있습니다.<br/>
                c) 본 계약의 조항에 따라 KBC GAME을 마케팅, 판촉 및 광고할 수 있는 모든 권리, 라이선스 및 허가의 소유자입니다.<br/>
                d) KBC GAME의 판촉과 관련하여 적용 가능한 모든 규칙, 법률 및 규정을 준수합니다.<br/>
                e) 계약 조건을 완전히 이해하고 수락합니다.
                <br/><br/>
                4. 회사의 책임과 의무
                <br/><br/>
                4.1. 회사는 추적 링크 구현에 필요한 모든 정보와 마케팅 자료를 제휴사에 제공해야 합니다.<br/>
                4.2. 회사는 추적 링크를 통해 발생하는 매출을 관리하고 링크를 통해 얻은 수익과 총 수수료를 기록하고 제휴사에 수수료 통계를 제공하며 비즈니스와 관련된 모든 고객 서비스를 처리합니다. 추천된 모든 고객에게 고유한 추적 식별 코드가 할당됩니다.<br/>
                4.3. 회사는 본 계약 조건에 따라 생성된 트래픽에 따라 지불해야 하는 금액을 제휴사에 지불합니다..
                <br/><br/>
                5. 계열사의 책임 및 의무ate
                <br/><br/>
                5.1. 계열사는 다음을 보증합니다.<br/>
                a) 양 당사자의 이익을 극대화하고 때때로 제시될 수 있는 회사의 지침을 준수하기 위해 KBC GAME을 최대한 광범위하게 적극적이고 효과적으로 광고, 마케팅 및 홍보하기 위해 최선의 노력을 다합니다. /또는 온라인에 게시.<br/>
                b) 자체 비용과 비용으로 잠재적인 플레이어를 KBC GAME에 마케팅하고 추천합니다. 제휴사는 마케팅 활동의 배포, 콘텐츠 및 매너에 대해 전적으로 책임을 집니다. 계열사의 모든 마케팅 활동은 관련 법률에 따라 전문적이고 적절하며 합법적이어야 하며 본 계약을 준수해야 합니다.<br/>
                c) 제휴 프로그램의 범위 내에서 제공된 추적 링크만 사용합니다. 그렇지 않으면 적절한 등록 및 판매 회계에 대해 어떠한 보장도 할 수 없습니다. 또한 회사의 사전 서면 승인 없이 링크 또는 마케팅 자료를 변경하거나 수정하지 않습니다.<br/>
                d) 웹 사이트의 개발, 운영 및 유지 관리는 물론 웹 사이트에 표시되는 모든 자료에 대한 책임을 집니다.<br/>
                5.2. 계열사는 다음을 보증합니다.
                <br/><br/>
                a) 비방, 차별, 외설, 불법 또는 기타 부적절하거나 성적으로 노골적이거나 음란하거나 폭력적인 자료가 포함된 행위를 하지 않습니다.<br/>
                b) 합법적인 도박 연령 미만인 사람을 적극적으로 타겟팅하지 않습니다.<br/>
                c) 도박 및 그 판촉이 불법인 관할권을 적극적으로 타겟팅하지 않습니다.<br/>
                d) 불법적이거나 사기적인 활동으로 KBC GAME에 대한 트래픽을 생성하지 않으며, 특히 다음 사항에 국한되지 않습니다.
                I. 스팸을 보냄.
                <br/><br/>
                II. 잘못된 메타태그.
                <br/><br/>
                III. 자신의 개인적 사용 및/또는 친척, 친구, 직원 또는 기타 제3자의 사용을 위해 플레이어로 등록하거나 트래커를 통해 플레이어 계정에 직간접적으로 입금하거나 다른 방법으로 시도하는 행위 지불해야 할 수수료를 인위적으로 늘리거나 회사를 사취하는 행위. 이 조항의 위반은 사기로 간주됩니다.
                <br/><br/>
                e) KBC GAME 및/또는 회사와 혼동의 위험을 야기할 수 있는 방식으로 웹사이트를 표시하지 않으며 계약 당사자의 웹사이트가 부분적으로 또는 전체적으로 KBC GAME에서 유래했다는 인상을 전달하지 않습니다.<br/>
                f) 회사에서 전달하고/하거나 웹사이트 https://kbcgaming.com/를 통해 온라인으로 제공할 수 있는 마케팅 자료를 침해하지 않고 계열사는 KBC GAME 또는 다음과 같은 기타 용어, 상표 및 기타 지적 재산권을 사용할 수 없습니다. 회사가 서면으로 동의하지 않는 한 회사에 귀속됩니다.
                
                <br/><br/>6. 지불
                <br/><br/>
                6.1. 회사는 제휴사의 웹사이트 및/또는 기타 채널에서 추천한 신규 고객으로부터 생성된 게임 배팅 금액을 기반으로 제휴사에 수수료를 지불하는 데 동의합니다. 신규 고객은 아직 게임 계정이 없고 추적 링크를 통해 웹사이트에 액세스하고 적절하게 등록하고 KBC GAME 베팅 계정에 최소 입금액과 동일한 비트코인 이체를 하는 회사의 고객입니다. 수수료는 해당되는 경우 부가가치세 또는 기타 세금을 포함하는 것으로 간주됩니다.<br/>
                6.2. 커미션은 특정 제품에 대한 커미션 구조에 명시된 내용에 따라 게임 배팅 금액의 백분율입니다. 계산은 제품별로 이루어지며 모든 제품별 커미션 구조에 명시되어 있습니다. (자세한 내용은 커미션 규칙참조)<br/>
                6.3. 사용자는 언제든지 에이전트 시스템에서 수수료를 인출할 수 있습니다. 수수료는 플랫폼 지갑으로 인출됩니다. 사용자는 플랫폼 지갑을 언제든지 원하는 주소로 인출할 수도 있습니다. (에이전트 시스템에서 수수료 추출을 확인하고 플랫폼 지갑에서 거래 기록을 볼 수 있습니다).<br/>
                6.4. 수수료는 디지털 통화로 수집되며 플랫폼 지갑에서만 수령됩니다. 출금 신청 시 출금페이지에서 올바른 지갑 주소를 제출해야 합니다. 수수료 계산에 오류가 있는 경우 회사는 언제든지 금액을 수정하고 미지급 차액을 대리인에게 즉시 정산하거나 초과 지급된 잔액을 대리인으로부터 회수할 수 있는 권리가 있습니다.<br/>
                6.5. 제휴사에 의한 대리인 철회 수수료는 전체로 간주되며 표시된 기간 동안 미지불 잔액이 최종 결제됩니다.<br/>
                6.6. 제휴사가 보고된 미지불 잔액에 동의하지 않는 경우, 칠(7)일 이내에 admin&#64;KBC GAME으로 회사에 이메일을 보내고 분쟁 이유를 표시해야 합니다. 또는 Telegram을 통해 고객 서비스에 문의하십시오. 규정된 기한 내에 이메일을 보내지 않거나 Telegram을 통해 고객 서비스에 연락하지 않는 경우 표시된 기간 동안 미결제 잔액에 대한 취소 불가능한 승인으로 간주됩니다.<br/>
                6.7. 해당 거래가 약관의 규정을 준수하는지 조사 및 확인하는 동안 대리인을 통한 출금 요청을 최대 60일 동안 지연할 수 있습니다.<br/>
                6.8. 생성된 트래픽이 불법이거나 본 약관의 조항을 위반하는 경우 지불해야 합니다.<br/>
                6.9. 제휴사는 사기 또는 위조된 거래를 기반으로 받은 모든 수수료와 법률이 허용하는 최대 범위 내에서 제휴사에 대해 제기될 수 있는 법적 원인 또는 조치에 대한 모든 비용을 반환하는 데 동의합니다.<br/>
                6.10. 명확성을 기하기 위해 당사자는 일방 당사자가 본 계약을 종료할 때 이전의 미해결 수수료 외에 대리인의 수수료가 더 이상 결제되지 않는다는 데 구체적으로 동의합니다.<br/>
                6.11.제휴사는 모든 세금, 부과금, 수수료, 요금 및 기타 현지 및 해외(있는 경우)에 지불해야 하는 금액을 제휴사가 세무 당국, 부서 또는 기타 권한 있는 기관으로 지불할 책임이 있습니다. 본 계약에 따라 생성된 수수료의 결과입니다. 회사는 미지급 금액에 대해 어떠한 책임도 지지 않지만 계열사가 지불해야 하는 것으로 확인된 금액에 대해 책임을 지지 않으며 이에 따라 계열사는 이와 관련하여 회사를 면책합니다.
                <br/><br/>
                7. 종료
                <br/><br/>
                본 계약은 일방 당사자가 상대방에게 삼십(30)일 서면 통지를 함으로써 종료될 수 있습니다. 서면 통지는 이메일로 할 수 있습니다.<br/>
                7.2. 계약 당사자는 본 계약 종료 시 다음 사항에 동의합니다.<br/>
                a) 제휴사는 커뮤니케이션이 상업적이든 비상업적이든 관계없이 제휴자의 웹사이트 및/또는 기타 마케팅 채널 및 커뮤니케이션에서 KBC GAME에 대한 모든 참조를 제거해야 합니다.<br/>
                b) 본 계약에 따라 계열사에 부여된 모든 권리와 라이선스는 즉시 종료되며 모든 권리는 해당 라이선스 제공자에게 귀속되며 계열사는 회사에 귀속된 상표, 서비스 마크, 로고 및 기타 명칭의 사용을 중단합니다.<br/>
                c) 제휴사는 해지 발효일 현재 적립 및 미지급 수수료에 대해서만 자격이 있습니다. 제휴사는 이 종료일 이후에 커미션을 받거나 받을 자격이 없습니다.<br/>
                d) 본 계약이 계열사의 위반에 근거하여 회사에 의해 종료되는 경우, 회사는 그러한 위반으로 인해 발생하는 모든 청구에 대한 담보로 종료 날짜를 기준으로 계열사의 수익이지만 미지급된 수수료를 보류할 수 있습니다. 또한 계열사가 본 계약의 조항을 위반하여 회사가 종료하는 경우 통지 기간이 필요하지 않으며 그러한 종료는 회사가 계열사에 간단히 통지하는 즉시 효력을 발생합니다.<br/>
                e) 계열사는 계열사가 소유, 보관 및 관리하는 모든 기밀 정보(및 모든 사본 및 파생물)를 회사에 반환해야 합니다.<br/>
                f) 계열사는 본질적으로 해지 후에도 존속하도록 설계된 의무를 제외하고 그러한 해지일 이후에 발생하거나 발생하는 모든 의무와 책임에서 회사를 면제합니다. 해지로 인해 해지 이전에 발생한 본 계약 위반으로 인해 발생하는 책임 및/또는 본 계약 종료 후 언제든지 위반이 발생하더라도 기밀 정보 위반으로 인해 발생하는 책임에서 계열사가 면제되지는 않습니다. 회사에 대한 계열사의 기밀 유지 의무는 본 계약이 종료된 후에도 지속됩니다.
                <br/><br/>
                8. 보증
                <br/><br/>
                8.1. 제휴사는 인터넷 사용에 따른 위험 부담을 감수하고 이 제휴 프로그램이 명시적이든 묵시적이든 어떠한 보증이나 조건 없이 있는 그대로그리고사용 가능한 상태로제공된다는 점을 명시적으로 인정하고 동의합니다. 특정 시간이나 특정 위치에서 웹사이트에 액세스할 수 있다는 보장은 없습니다.<br/>
                8.2. 회사는 어떠한 경우에도 KBC GAME 웹사이트 또는 계열사의 실패, 지연 또는 중단으로 인해 전체 또는 부분적으로 발생한 부정확성, 오류 또는 누락, 손실, 부상 또는 손해에 대해 계열사 또는 다른 사람에 대해 책임을 지지 않습니다. 프로그램.<br/>
                9.1. 계열사는 합리적인 변호사 및 전문가 수임료를 포함하여 모든 청구 및 책임으로부터 회사와 회사의 계열사, 승계인, 임원, 직원, 대리인, 이사, 주주 및 변호사를 무료로 보호하고 면책하고 면책하는 데 동의합니다. , 다음과 관련되거나 다음과 관련하여 발생합니다.
                <br/><br/>
                a) 본 계약에 따른 계열사의 진술, 보증 또는 계약 위반.<br/>
                b) 제휴사의 마케팅 자료 사용(또는 오용).<br/>
                c) 제휴사의 사용자 ID 및 비밀번호로 발생하는 모든 행위 및 활동.<br/>
                d) 제휴자의 웹사이트에 포함되거나 제휴자의 정보 및 데이터의 일부로 포함된 모든 명예 훼손, 비방 또는 불법 자료.<br/>
                e) 계열사의 웹사이트 또는 계열사의 정보 및 데이터가 제3자의 특허, 저작권, 상표 또는 기타 지적 재산권을 침해하거나 제3자의 개인 정보 보호 또는 퍼블리시티권을 침해한다는 주장 또는 주장.<br/>
                f) 제휴사의 웹사이트 또는 제휴사의 정보 및 데이터에 대한 제3자 액세스 또는 사용.<br/>
                g) 제휴 웹사이트와 관련된 모든 클레임.<br/>
                h) 본 계약의 모든 위반.<br/>
                9.2. 회사는 모든 문제를 방어하기 위해 자체 비용으로 참여할 권리를 보유합니다.
                <br/><br/>
                10. 회사 권리
                <br/><br/>
                10.1. 회사 또는 KBC GAME 정책을 준수하고 회사 또는 KBC GAME의 이익을 보호하기 위해 회사 또는 KBC GAME은 모든 플레이어를 거부하거나 플레이어 계정을 폐쇄할 수 있습니다.
                <br/><br/>
                10.2. T회사는 회사의 정책을 준수하고 회사의 이익을 보호하기 위해 필요한 경우 신청자를 거부하거나 계열사의 계정을 폐쇄할 수 있습니다. 계열사가 본 계약 또는 회사의 약관 또는 회사의 기타 규칙, 정책 및 지침을 위반하는 경우, 회사는 계열사의 계정을 폐쇄하는 것 외에도 이익을 보호하기 위해 법적으로 다른 조치를 취할 수 있습니다.
                <br/><br/>
                11. 수수료 구성
                <br/><br/>
                11.1.에이전트에게 지불되는 수수료는 게임 베팅 금액의 백분율입니다. 정확한 수수료 구조는 이 계약의 일부입니다. 자세한 내용은 수수료 규칙조항을 참조하십시오. 이 경우 수수료는 플레이어의 플랫폼 지갑(디지털 통화)으로 인출되지만 다른 주소로 직접 인출되지는 않습니다.
                <br/><br/>
                12. 할당
                <br/><br/>
                12.1. 제휴사는 회사의 사전 서면 동의 없이 법률 또는 기타 방법으로 본 계약을 양도할 수 없습니다. 계열사가 KBC GAME의 다른 계열사를 인수하거나 다른 방식으로 통제하는 경우 계정은 개별 조건에 따라 공존합니다.<br/>
                12.2. 회사는 법률 또는 기타 방법에 따라 제휴사의 사전 동의 없이 언제든지 본 계약을 양도할 수 있습니다.
                <br/><br/>
                13. 포기하지 않음
                <br/><br/>
                13.1. 회사가 본 계약에 요약된 조건에 대한 계열사의 준수를 시행하지 않는다고 해서 언제든지 해당 조건을 시행할 회사의 권리를 포기하는 것은 아닙니다.
                <br/><br/>
                14. 불가항력
                <br/><br/>
                14.1. 이러한 지연 또는 실패가 노동 분쟁을 포함하되 이에 국한되지 않는 합당한 통제 범위를 벗어난 원인으로 인해 발생하고 해당 당사자의 잘못이 아닌 경우 어느 당사자도 본 계약에 따른 의무를 이행하지 않거나 지연된 것에 대해 상대방에 대해 책임을 지지 않습니다. , 파업, 산업 혼란, 천재지변, 테러 행위, 홍수, 번개, 유틸리티 또는 통신 장애, 지진 또는 기타 사상자. 불가항력 사건이 발생하는 경우, 불이행 당사자는 불가항력 사건에 의해 금지된 이행이 방지된 범위 내에서 면제됩니다. 단, 불가항력 상황이 삼십(30)일을 초과하는 기간 동안 지속되는 경우 일방 당사자는 통지 없이 계약을 해지할 수 있습니다.
                <br/><br/>
                15. 당사자의 관계
                <br/><br/>
                15.1. 본 계약에 포함된 어떠한 내용이나 본 계약의 당사자가 취한 조치는 일방 당사자(또는 해당 당사자의 직원, 대리인 또는 대표자)의 직원 또는 상대방의 법적 대리인을 구성하는 것으로 간주되지 않습니다. 당사자 간 또는 당사자 간에 파트너십, 합작 투자, 협회 또는 신디케이션을 생성하지 않으며, 당사자를 대신하여 계약 또는 약속을 체결할 명시적 또는 묵시적 권리, 권한 또는 권한을 부여하지 않습니다(또는 의무를 부과하지 않음). 상대방.
                <br/><br/>
                16. 분리 가능성/포기
                <br/><br/>
                16.1. 가능한 한 본 계약의 각 조항은 해당 법률에 따라 효과적이고 유효한 방식으로 해석되지만, 본 계약의 조항이 어떤 면에서든 무효, 불법 또는 시행 불가능한 것으로 판명되면 해당 조항은 무효가 됩니다. 본 계약의 나머지 부분을 무효화하지 않고 그러한 무효성 또는 집행 불가능한 범위 내에서만. 권리를 행사하지 않거나 시행하지 않음으로 인해 권리 포기가 암시되지 않으며 유효하려면 서면으로 작성해야 합니다.
                <br/><br/>
                17. 기밀성
                <br/><br/>
                17.1. 비즈니스 및 재무, 고객 및 구매자 목록, 가격 및 판매 정보 및 제품, 기록, 운영, 사업 계획, 프로세스, 제품 정보, 비즈니스 노하우 또는 논리와 관련된 모든 정보를 포함하되 이에 국한되지 않는 모든 정보 , 영업 비밀, 시장 기회 및 회사의 개인 데이터는 기밀로 취급됩니다. 그러한 정보는 회사가 사전에 명시적이고 서면으로 동의한 경우를 제외하고는 자신의 상업적 또는 기타 목적으로 사용하거나 직간접적으로 다른 사람이나 제3자에게 누설해서는 안 됩니다. 이 조항은 본 계약이 종료된 후에도 유효합니다.<br/>
                17.2. 계열사는 본 계약에 따른 의무 이행 이외의 목적으로 기밀 정보를 사용하지 않을 의무가 있습니다.<br/>
                <br/><br/>
                18. 본 계약의 변경 사항
                <br/><br/>
                18.1. 회사는 본 계약에 명시된 조건에 따라 제휴사에 사전 통지 없이 언제든지 단독 재량으로 본 계약의 조항을 수정, 변경, 삭제 또는 추가할 수 있는 권리를 보유합니다. 이러한 변경 사항은 KBC GAME에 게시됩니다.<br/>
                18.2. 본 계약의 번역된 버전의 의미 사이에 불일치가 있는 경우 영어 버전이 우선합니다.<br/><br/>
                19. 상표
                <br/><br/>
                19.1. 본 계약에 포함된 어떠한 내용도 상대방의 상표, 상호, 서비스 마크 또는 기타 지적 재산권[이하 간단히 '상표'라고 함]에 대한 권리, 소유권 또는 이익을 어느 한쪽 당사자에게 부여하지 않습니다. 기간 중 또는 그 이후에는 어느 당사자도 상대방 또는 상대방 회사 그룹 내 회사의 상표에 대해 다른 사람이 이의를 제기하거나 등록하거나 등록을 시도하거나 이의를 제기하거나 지원하거나 허용하지 않습니다. 단, 양 당사자는 기본적으로 상대방 또는 상대방의 회사 그룹에 포함된 회사에 속한 상표와 기본적으로 유사하거나 혼동을 일으킬 정도로 유사한 상표를 등록하거나 등록하려고 시도하지 않습니다.

            </div>
        </div>
    </div>
</div>
