import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Observable, of } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ValidatorService {

    constructor(
        private apiService: ApiService
    ) {}

    compare(matchName:string, message:string='비밀번호 확인이 다릅니다.') {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const match = control.parent?.get(matchName)

            if(control.value !== match?.value) return of({ compare_error:  message })

            return of(null)
        }
    }

    // 라이브 인증은 위험하므로 패스
    auth_code(matchName:string):AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const match = control.parent?.get(matchName)
            return of(this.apiService.post('/auth/auth-code', { hp: match?.value, auth_code: control.value }).subscribe(jsondata => {
                console.log(jsondata)
                return null;
            }))
        }
    }

    // auth_code(control: AbstractControl): Observable<ValidationErrors | null> {
    //     const v: number = control.value;
    //     // if (v !== 10) {
    //     //     // Emit an object with a validation error.
    //     //     return of({ 'notTen': true, 'requiredValue': 10 });
    //     // }
    //     // Emit null, to indicate no error occurred.
    //     return of(null);
    // }
    
}