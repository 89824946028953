import { Component } from '@angular/core';

@Component({
  selector: 'app-redirest',
  standalone: true,
  imports: [],
  template: '',
  styles: ``
})
export class RedirestComponent {
    constructor() {}

    ngOnInit() {
        // const iframe = window.self !== window.top;
        (window as any).top.location = '/';
    }
}
