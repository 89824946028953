import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TurnstileComponent } from '@app/shared/lib/turnstile/turnstile.component';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { passwordView } from '../../../common/common';
import { ModalService } from '../../../common/modal/modal.service';
import { CountdownDirective } from '@app/shared/lib/directive/countdown.directive';

@Component({
    selector: 'app-sign-in-modal',
    standalone: true,
    imports: [
        CommonModule,
        TurnstileComponent,
        ReactiveFormsModule,
        CountdownDirective
    ],
    templateUrl: './sign-in-modal.component.html',
    styles: ``
})
export class SignInModalComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    siginInForm!: FormGroup;
    siginIn2FaForm!: FormGroup;
    sigining = false;
    submitted = false;
    returnUrl: string;
    hostname: string = '';
    error = '';

    siteKey = environment.CLOUDFLARE.TURNSTILE.SITE_KEY;
    token:string = '';

    recaptchaReady = false;

    tab:string = 'signin'

    get f() { return this.siginInForm.controls; }
    get f2() { return this.siginIn2FaForm.controls; }    
    get passwordView() { return passwordView }

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private modalService: ModalService
    ) {
        this.siginInForm = this.formBuilder.group({
            uid: ['', Validators.required],
            pwd: ['', Validators.required]
        });

        this.siginIn2FaForm = this.formBuilder.group({
            auth_code: ['', Validators.required],
        });

        this.returnUrl = sessionStorage.getItem('returnUrl') || '/';
        this.hostname = window.location.hostname.replace('www.', '')

        this.clearStorage();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    sendCaptchaResponse(captchaResponse: any) {
        this.recaptchaReady = true;
        this.token = captchaResponse;
    }

    clearStorage() {
        const signout = sessionStorage.getItem('signout');
        if(signout) {
            const d = JSON.parse(signout);
            this.error = d.message;

            sessionStorage.removeItem('signout');
        }
        
        this.authService.clear();
    }

    onEnter($event:any):void {
        if($event) $event.preventDefault();
        this.onSignIn()
    }

    onSignIn() {
        this.submitted = true;
        if(!this.recaptchaReady) return;
        if(this.siginInForm.invalid) return;

        this.sigining = true;
        const uid = this.siginInForm.controls['uid'].value
        const pwd = this.siginInForm.controls['pwd'].value
        this.authService.signIn(uid, pwd, this.token).subscribe({
            next: (jsondata) => {
                if(jsondata.success) {
                    this.sigining = false;
                    this.submitted = false;
                    this.recaptchaReady = false;
                    if(jsondata.data.users.twofa_token) {
                        this.tab = 'signin-2fa';
                    } else {
                        this.onClose.emit('success')
                    }
                } else {
                    this.raiseError(jsondata.message);
                    this.modalService.open(null, 'message', { title: `로그인 실패`, message: jsondata.message })
                }
            },
            error: (err) => {
                this.raiseError(err.message || err);
                this.modalService.open(null, 'message', { title: `로그인 실패`, message: err.message || err })
            },
        })
    }

    onSignIn2Fa() {
        this.submitted = true;
        if(!this.recaptchaReady) return;
        if(this.siginIn2FaForm.invalid) return;

        this.sigining = true;
        this.authService.totp(this.siginIn2FaForm.controls['auth_code'].value, this.token).subscribe({
            next: (jsondata:any) => {
                if(jsondata.success) {
                    this.sigining = false;
                    this.onClose.emit('success')
                } else {
                    this.raiseError(jsondata.message);
                    this.modalService.open(null, 'message', { title: `로그인 실패`, message: jsondata.message })
                }
            }, error: (err:any) => {
                this.raiseError(err.message || err);
                this.modalService.open(null, 'message', { title: `로그인 실패`, message: err.message || err })
            } 
        })
    }


    GoModal(next:string, $event:any = null): void {
        if($event) $event.preventDefault()

        this.onClose.emit()
        this.modalService.open(null, next)
    }

    raiseError(error:any) {
        this.error = error;
        this.sigining = false;
    }

    onTab(target:string='') {
        const elem = document.querySelector(`[data-tw-target="#${target}"`)
        // const elem = <HTMLElement>$event.target
        // const id = elem.getAttribute(`data-tw-target`)

        elem?.addEventListener(`show.tw.tab`, (e) => {

        })

        elem?.addEventListener(`hide.tw.tab`, (e) => {

        })

        const tab = (window as any).tailwind.Tab.getOrCreateInstance(elem)
        if(tab) tab.show()
    }
}
 