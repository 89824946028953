import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-bonus-roll-modal',
  standalone: true,
  imports: [],
  templateUrl: './bonus-roll-modal.component.html',
  styles: ``
})
export class BonusRollModalComponent {
    constructor() {
    }
    
    // 롤 숫자 이동
    rollHandle(): void {
        const box:NodeListOf<HTMLElement> = document.querySelectorAll('.roll-modal .scroll_box')
        setTimeout(()=>{
            box[0].style.top = "-720px"
            box[1].style.top = "-720px"
            box[2].style.top = "-720px"
        })
    }
}
