<!-- 좌측메뉴 - 스핀 -->
<div class="modal-content overflow-hidden relative rounded" [ngClass]="{ 'on': modalTab }">
    <!-- 기본 -->
    <div class="modal-body relative">
        <button class="absolute right-1 top-2 basic-hover px-1 close_btn" data-tw-dismiss="modal"><svg class="w-4 h-4 cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>

        <div class="spin_box py-6 p-5">
            <div class="bg-1"></div>
            <div class="bg-2"></div>
            <div class="flex items-center gap-1">
                <div class="flex items-center justify-between gap-1 w-1/2 bg-[#151617] p-1 spin_btn rounded">
                    <button class="rounded p-1 w-1/3" [ngClass]="{'active': spin == 'LUCKY'}" (click)="spin = 'LUCKY'; errMessage = ''">
                        <img src="/assets/custom_img/spin/tbtn_luckspin_1.webp" />
                        <img class="active" src="/assets/custom_img/spin/tbtn_luckspin_2.webp" />
                    </button>
                    <button class="rounded p-1 w-1/3" [ngClass]="{'active': spin == 'SUPER'}" (click)="spin = 'SUPER';errMessage = ''">
                        <img src="/assets/custom_img/spin/tbtn_superspin_1.webp" />
                        <img class="active" src="/assets/custom_img/spin/tbtn_superspin_2.png" />
                    </button>
                    <button class="rounded p-1 w-1/3" [ngClass]="{'active': spin == 'MEGA'}" (click)="spin = 'MEGA';errMessage = ''">
                        <img src="/assets/custom_img/spin/tbtn_megaspin_1.webp" />
                        <img class="active" src="/assets/custom_img/spin/tbtn_megaspin_2.webp" />
                    </button>
                </div>
                <div class="w-1/2 bg-[#151617] p-1 rounded ">
                    <div class="text-center rounded py-2 spin_info" [style.backgroundColor]="spinColor[spin]">
                        <div class="tag-img" [style.color]="spinColor[spin]">{{ spinText[spin] }}</div>
                        <b class="font-extrabold text-white">{{ spinLevel[spin] }}</b>
                    </div>
                </div>
            </div>
            <div class="spin_wrap mt-4 mx-auto">
                <!-- lucky spin -->
                <div class="spin_bronze" [ngClass]="{'active': spin == 'LUCKY'}">
                    <div class="spin_area relative mx-auto">
                        <div class="relative">
                            <div class="zoom_box">
                                <img class="spin-img mx-auto" src="/assets/custom_img/spin/spin_bronze.webp" alt="">
                                <img class="spin-light absolute left-0 top-0" src="/assets/custom_img/spin/spinLight.webp" alt="">
                                <img class="absolute left-0 top-0" src="/assets/custom_img/spin/diamond_fuck.webp" alt="">
                                <div class="spin-item flex items-center gap-1 justify-end" style="opacity: 1; height: 24px;" [style.transform]="'rotate(' + item.rotate + 'deg)'" *ngFor="let item of spinData.LUCKY">
                                    <span class="amount text-white font-semibold">{{ item.amount | number}}</span>
                                    <img class="coin-icon w-6" [src]="'/assets/custom_img/coin/' + item.code + '.webp'">
                                </div>
                            </div>
                            <img class="absolute left-[63%] top-[36.5%] w-[46%]" src="/assets/custom_img/spin/point_bronze.webp" alt="" style="opacity: 1; transform: none;">
                        </div>
                        <button class="z-20 absolute left-1/2 top-1/2 w-[30%] -translate-x-1/2 -translate-y-1/2">
                            <img src="/assets/custom_img/spin/spin_center1.webp" alt="">
                            <img class="absolute left-1/2 top-1/2  btn-txt" src="/assets/custom_img/spin/btn_luckspin.webp" alt="">
                        </button>
                    </div>
                    <div class="spin_logo relative z-10 -mt-8"><img class="w-full mx-auto" src="/assets/custom_img/spin/banner_bronze.webp" /></div>
                    <button [disabled]="sigining" class="block btn-bronze w-2/3 h-12 mx-auto mt-4 font-extrabold rounded" (click)="rollingSpin('spin_bronze')">
                        <div *ngIf="sigining" class="e9sva loading">
                            <svg viewBox="0 0 84 24" fill="#fff">
                                <circle cx="18" cy="12" r="6"></circle>
                                <circle cx="18" cy="12" r="6"></circle>
                                <circle cx="42" cy="12" r="6"></circle>
                                <circle cx="66" cy="12" r="6"></circle>
                            </svg>
                        </div>
                        <span *ngIf="!sigining">스핀 돌리기</span>
                    </button>
                    <div class="font-extrabold text-white flex items-center justify-center" *ngIf="errMessage">{{errMessage}}</div>
                </div>
                <!-- super spin -->
                <div class="spin_gold" [ngClass]="{'active': spin == 'SUPER'}">
                    <div class="spin_area relative mx-auto">
                        <div class="relative">
                            <div class="zoom_box">
                                <img class="spin-img mx-auto" src="/assets/custom_img/spin/spin_bronze.webp" alt="">
                                <img class="spin-light absolute left-0 top-0" src="/assets/custom_img/spin/spinLight.webp" alt="">
                                <div class="spin-item flex items-center gap-1 justify-end" style="opacity: 1; height: 24px;" [style.transform]="'rotate(' + item.rotate + 'deg)'" *ngFor="let item of spinData.SUPER">
                                    <span class="amount text-white font-semibold">{{ item.amount | number}}</span>
                                    <img class="coin-icon w-6" [src]="'/assets/custom_img/coin/' + item.code + '.webp'">
                                </div>
                            </div>
                            <img class="absolute left-[63%] top-[36.5%] w-[46%]" src="/assets/custom_img/spin/point_gold.png" alt="" style="opacity: 1; transform: none;">
                        </div>
                        <button class="z-20 absolute left-1/2 top-1/2 w-[30%] -translate-x-1/2 -translate-y-1/2">
                            <img src="/assets/custom_img/spin/spin_center1.webp" alt="">
                            <img class="absolute left-1/2 top-1/2  btn-txt" src="/assets/custom_img/spin/btn_superspin.png" alt="">
                        </button>
                    </div>
                    <div class="spin_logo relative z-10 -mt-8"><img class="w-full mx-auto" src="/assets/custom_img/spin/banner_gold.png" /></div>
                    <button [disabled]="sigining" class="block btn-yellow w-2/3 h-12 mx-auto mt-4 font-extrabold rounded" (click)="rollingSpin('spin_gold')">
                        <div *ngIf="sigining" class="e9sva loading">
                            <svg viewBox="0 0 84 24" fill="#fff">
                                <circle cx="18" cy="12" r="6"></circle>
                                <circle cx="18" cy="12" r="6"></circle>
                                <circle cx="42" cy="12" r="6"></circle>
                                <circle cx="66" cy="12" r="6"></circle>
                            </svg>
                        </div>
                        <span *ngIf="!sigining">스핀 돌리기</span>
                    </button>
                    <div class="font-extrabold text-white flex items-center justify-center" *ngIf="errMessage">{{errMessage}}</div>
                </div>
                <!-- mega spin -->
                <div class="spin_diamond" [ngClass]="{'active': spin == 'MEGA'}">
                    <div class="spin_area relative mx-auto">
                        <div class="relative">
                            <div class="zoom_box">
                                <img class="spin-img mx-auto" src="/assets/custom_img/spin/spin_bronze.webp" alt="">
                                <img class="spin-light absolute left-0 top-0" src="/assets/custom_img/spin/spinLight.webp" alt="">
                                <div class="spin-item flex items-center gap-1 justify-end" style="opacity: 1; height: 24px;" [style.transform]="'rotate(' + item.rotate + 'deg)'" *ngFor="let item of spinData.MEGA">
                                    <span class="amount text-white font-semibold">{{ item.amount | number}}</span>
                                    <img class="coin-icon w-6" [src]="'/assets/custom_img/coin/' + item.code + '.webp'">
                                </div>
                            </div>
                            <img class="absolute left-[63%] top-[36.5%] w-[46%]" src="/assets/custom_img/spin/point_diamond.webp" alt="" style="opacity: 1; transform: none;">
                        </div>
                        <button class="z-20 absolute left-1/2 top-1/2 w-[30%] -translate-x-1/2 -translate-y-1/2">
                            <img src="/assets/custom_img/spin/spin_center1.webp" alt="">
                            <img class="absolute left-1/2 top-1/2  btn-txt" src="/assets/custom_img/spin/btn_megaspin.webp" alt="">
                        </button>
                    </div>
                    <div class="spin_logo relative z-10 -mt-8"><img class="w-full mx-auto" src="/assets/custom_img/spin/banner_diamond.webp" /></div>
                    <button [disabled]="sigining" class="block btn-purple w-2/3 h-12 mx-auto mt-4 font-extrabold rounded" (click)="rollingSpin('spin_diamond')">
                        <div *ngIf="sigining" class="e9sva loading">
                            <svg viewBox="0 0 84 24" fill="#fff">
                                <circle cx="18" cy="12" r="6"></circle>
                                <circle cx="18" cy="12" r="6"></circle>
                                <circle cx="42" cy="12" r="6"></circle>
                                <circle cx="66" cy="12" r="6"></circle>
                            </svg>
                        </div>
                        <span *ngIf="!sigining">스핀 돌리기</span>
                    </button>
                    <div class="font-extrabold text-white flex items-center justify-center" *ngIf="errMessage">{{errMessage}}</div>
                </div>
            </div>

            <div class="flex gap-1 mt-4 rounded bg-[#151617] p-1 ">
                <div class="flex items-center justify-center flex-col p-2 px-4 text-center" style="background-color: rgb(25, 26, 27);">
                    <p class="text-xs">스핀 보너스 총계</p>
                    <b class="text-base" style="color:#e9d317;">${{ total_hit | number }}</b>
                </div>
                <div class="relative flex flex-1 items-start gap-2 p-4 cursor-pointer" style="background-color: rgb(25, 26, 27);"  (click)="modalInHandle('spin_bonus-body')">
                    <svg class="absolute right-2 top-1/2 -translate-y-1/2 w-5 h-5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    <img class="w-7 h-7 rounded-full" [src]="topItem.profile_image" alt="" profile *ngIf="topItem">
                    <div class="font-medium" *ngIf="topItem">
                        <p>{{ topItem.nick }}</p>
                        <p class="text-sub">Win: <span class="text-primary">{{ topItem.hit_amount | number }}</span> <span class="text-white px-1">{{ topItem.wallet_code }}</span></p>
                        <p class="text-sub">in <b style="color:rgb(255, 90, 196);">{{ topItem.spin }}</b></p>
                        <!-- <p class="text-sub">in <b style="color:rgb(255, 172, 4);">SUPER SPIN</b></p> -->
                        <!-- <p class="text-sub">in <b style="color:rgb(105, 14, 224);">MEGA SPIN</b></p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- 스핀 보너스 -->
    <div class="spin_bonus-body modal-in modal-body relative rounded bg-modaldark" [ngClass]="{ 'open': modalTab == 'spin_bonus-body'}">
        <div class="relative flex items-center justify-between p-2 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('spin_bonus-body')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
            </div>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="w-full p-6 pb-2 px-4 overflow-y-auto scrollbar h-[690px]">
            <div>
                <img class=" w-12 mx-auto" src="/assets/custom_img/crown.png"/>
                <div class="flex items-center justify-center mt-1 gap-1">
                    <img class="w-3" src="/assets/custom_img/grass_left.svg"/>
                    <b class="text-tit text-xl font-extrabold">스핀 보너스</b>
                    <img class="w-3" src="/assets/custom_img/grass_right.svg"/>
                </div>
            </div>
            <div class="bg-stand mt-4 p-2 rounded">
                <div class="flex items-center justify-between py-3">
                    <p class="text-left w-[30%]">유저이름</p>
                    <p class="text-center w-[30%]">스핀 레벨</p>
                    <p class="text-right w-[40%]">상품/상금</p>
                </div>
                <div class="spin_bonus_wrap h-[500px] overflow-hidden">
                    <swiper-container swiper init="false" [swiperOptions]="swiperOptions" style="width:100%;height:100%;" *ngIf="swiperItems.length > 0">
                        <swiper-slide class="swiper-slide" *ngFor="let item of swiperItems">
                            <div class="flex items-center justify-between py-3">
                                <p class="text-left w-[30%] text-tit font-extrabold truncate pr-8">{{ item.nick }}</p>
                                <p class="text-center w-[30%] text-tit">{{ item.spin }}</p>
                                <p class="text-right w-[40%] flex items-center justify-end gap-1"><span class="text-primary">{{ item.amount | number }}</span> <img class="w-5" [src]="'/assets/custom_img/coin/' + item.wallet_code + '.webp'"></p>
                            </div>
                        </swiper-slide>
                      </swiper-container>
                </div>
            </div>
        </div>
    </div>

</div>
