<!-- 보너스정보 모달 -->
<div class="modal-content overflow-hidden relative rounded">
    <div class="modal-body">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">보너스 정보</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>

        <div class="w-full p-6 overflow-y-auto scrollbar h-auto sm:h-[640px]">

            <div class="text-tit font-bold text-base">보너스 카테고리</div>
            <div class="bg-modaldark dark:bg-backlight p-3 rounded flex flex-wrap gap-y-4">
                <div class="w-1/5">
                    <p class="flex items-center justify-center text-xs gap-1"><img class="w-5" src="/assets/custom_img/bonus/icon/quests.webp" alt=""><span class="truncate">퀘스트</span></p>
                    <b class="block mt-1 pl-2 text-tit font-bold">$0.00</b>
                </div>
                <div class="w-1/5">
                    <p class="flex items-center justify-center text-xs gap-1"><img class="w-5" src="/assets/custom_img/bonus/icon/spin.webp" alt=""><span class="truncate">럭키스핀</span></p>
                    <b class="block mt-1 pl-2 text-tit font-bold">$5.00</b>
                </div>
                <div class="w-1/5">
                    <p class="flex items-center justify-center text-xs gap-1"><img class="w-5" src="/assets/custom_img/bonus/icon/roll.webp" alt=""><span class="truncate">롤</span></p>
                    <b class="block mt-1 pl-2 text-tit font-bold">$0.00</b>
                </div>
                <div class="w-1/5">
                    <p class="flex items-center justify-center text-xs gap-1"><img class="w-5" src="/assets/custom_img/bonus/icon/recharge.webp" alt=""><span class="truncate">재충전</span></p>
                    <b class="block mt-1 pl-2 text-tit font-bold">$0.00</b>
                </div>
                <div class="w-1/5">
                    <p class="flex items-center justify-center text-xs gap-1"><img class="w-5" src="/assets/custom_img/bonus/icon/weekly.webp" alt=""><span class="truncate">주간 캐시백</span></p>
                    <b class="block mt-1 pl-2 text-tit font-bold">$0.00</b>
                </div>
                <div class="w-1/5">
                    <p class="flex items-center justify-center text-xs gap-1"><img class="w-5" src="/assets/custom_img/bonus/icon/monthly.webp" alt=""><span class="truncate">월간 캐시백</span></p>
                    <b class="block mt-1 pl-2 text-tit font-bold">$0.00</b>
                </div>
                <div class="w-1/5">
                    <p class="flex items-center justify-center text-xs gap-1"><img class="w-5" src="/assets/custom_img/bonus/icon/sports.webp" alt=""><span class="truncate">스포츠 주간 캐쉬백</span></p>
                    <b class="block mt-1 pl-2 text-tit font-bold">$5.00</b>
                </div>
                <div class="w-1/5">
                    <p class="flex items-center justify-center text-xs gap-1"><img class="w-5" src="/assets/custom_img/bonus/icon/levelup.webp" alt=""><span class="truncate">레벨업 보너스</span></p>
                    <b class="block mt-1 pl-2 text-tit font-bold">$0.00</b>
                </div>
                <div class="w-1/5">
                    <p class="flex items-center justify-center text-xs gap-1"><img class="w-5" src="/assets/custom_img/bonus/icon/freespin.webp" alt=""><span class="truncate">무료 스핀</span></p>
                    <b class="block mt-1 pl-2 text-tit font-bold">$0.00</b>
                </div>
                <div class="w-1/5">
                    <p class="flex items-center justify-center text-xs gap-1"><img class="w-5" src="/assets/custom_img/bonus/icon/deposit.webp" alt=""><span class="truncate">입금 보너스</span></p>
                    <b class="block mt-1 pl-2 text-tit font-bold">$0.00</b>
                </div>

            </div>

            <div class="flex items-center justify-between mt-5">
                <div class="text-tit text-base font-bold">보너스 거래 내역</div>

                <div class="custom_select w-48">
                    <button class="btn h-10 flex px-4 items-center justify-between border-none bg-modaldark dark:bg-backlight">
                        <span>총 보너스</span>
                        <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                    </button>
                    <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                        <li class="py-2 on">총 보너스</li>
                        <li class="py-2">퀘스트</li>
                        <li class="py-2">럭키스핀</li>
                        <li class="py-2">롤</li>
                        <li class="py-2">입금 보너스</li>
                        <li class="py-2">재충전</li>
                        <li class="py-2">주간 캐쉬백</li>
                        <li class="py-2">월간 캐쉬백</li>
                        <li class="py-2">스포츠 주간 캐쉬백</li>
                        <li class="py-2">Levelup Bonus</li>
                    </ul>
                </div>
            </div>
            <div class="mt-1">최근 60일 거래만 아래에 표시됩니다.</div>

            <div class="p-3 mt-2 bg-modaldark dark:bg-backlight rounded">
                <div class="py-2">
                    <table class="table noline pad-s table-sm ">   
                        <colgroup>
                            <col width="20%">
                            <col width="30%">
                            <col width="50%">
                        </colgroup>
                        <thead class="bg-back">
                            <tr class="text-center text-xs">
                                <th class="whitespace-nowrap text-left">보너스 유형</th>
                                <th class="whitespace-nowrap">수령한 금액</th>
                                <th class="whitespace-nowrap text-right">시간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-left">럭키 스핀</td>
                                <td class="text-center">5.0038<img class="inline-flex items-center ml-1 w-5" src="/assets/custom_img/coin/BTC.webp" alt=""></td>
                                <td class="text-right">2023. 11. 8. 오전 10:21:12</td>
                            </tr>
                            <tr>
                                <td class="text-left">럭키 스핀</td>
                                <td class="text-center">5.0038<img class="inline-flex items-center ml-1 w-5" src="/assets/custom_img/coin/BTC.webp" alt=""></td>
                                <td class="text-right">2023. 11. 8. 오전 10:21:12</td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>

            <div class="flex gap-2 items-center justify-end mt-4">
                <p class="text-xs">총 1</p>
                <div class="flex gap-0 text-base px-4 py-1 bg-back2 rounded font-medium">
                    <button class="w-6 h-6 basic-hover active font-extrabold">1</button>
                    <button class="w-6 h-6 basic-hover">2</button>
                    <button class="w-6 h-6 basic-hover">3</button>
                </div>
                <div class="flex gap-1">
                    <button class="btn-normal w-8 h-8 basic-hover rounded"><svg class="w-4 h-4 mx-auto rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                    <button class="btn-normal w-8 h-8 basic-hover rounded"><svg class="w-4 h-4 mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                </div>
            </div>

        </div>
    </div>
</div>
