import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-rollover-modal',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './rollover-modal.component.html',
  styles: ``
})
export class RolloverModalComponent {
    
    
    modalTab = ''
    

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
    }
}
