import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { CommonModule } from '@angular/common';
import { ModalService } from '../../common/modal/modal.service';

@Component({
  selector: 'app-bonus',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bonus.component.html',
  styles: ``
})
export class BonusComponent {
    private subs: Subscription[] = [];

    currentUser!:currentUserInfo | null;

    get modal() { return this.modalService }
    constructor(
        private authService: AuthService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    // 롤 숫자 이동
    rollHandle(): void {
        const box:NodeListOf<HTMLElement> = document.querySelectorAll('.roll-modal .scroll_box')
        setTimeout(()=>{
            box[0].style.top = "-720px"
            box[1].style.top = "-720px"
            box[2].style.top = "-720px"
        })
    }
}
