import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-message-modal',
  standalone: true,
  imports: [],
  templateUrl: './message-modal.component.html',
  styles: ``
})
export class MessageModalComponent {
    @Input() optParam:any = {}

    title = `안내`
    constructor() {
    }
    

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes)
        if(changes['optParam']) {
            const prev = changes['optParam'].previousValue;
            const current = changes['optParam'].currentValue
            if(current) {
                console.log(current)
            }
        }
    }
}
