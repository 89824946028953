<div class="modal-content h-full sm:h-auto">
    <div class="modal-head" style="position: sticky;">
        <div class="flex items-center justify-between p-2">
            <button class="basic-hover" data-tw-dismiss="modal">
                <svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
            </button>
            <button class="basic-hover" data-tw-dismiss="modal">
                <svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg>
            </button>
        </div>
    </div>
    <div class="modal-body flex flex-wrap content-start relative h-auto sm:h-auto">
        <iframe #iframeEl [src]="options.url | sanitize:'url'" width="100%" (load)="iframeLoad($event)" [style]="options.style" [style.height]="iframeHeight" [style.transform]="'scale(0.95)'"></iframe>
    </div>
</div>
