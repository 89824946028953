import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface MessageOption {
    message: string;
    onEvt?: EventEmitter<any>;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {
    private subject = new BehaviorSubject<MessageOption | null>(null)
    
    constructor() {}

    public getData() {
        return this.subject.asObservable() 
    }

    public open(message:string,  onEvt?:EventEmitter<any> ) {
        const option:MessageOption = {
            message: message,
            onEvt: onEvt
        }

        this.subject.next(option);
    }
}