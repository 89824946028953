import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-twofa-active-modal',
  standalone: true,
  imports: [],
  templateUrl: './twofa-active-modal.component.html',
  styles: ``
})
export class TwofaActiveModalComponent {
    get window() { return (window as any)}
    constructor() {}
}
