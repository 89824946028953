import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Output, EventEmitter, Input, Renderer2, Inject } from '@angular/core';

@Directive({
    selector: '[level-icon]',
    standalone: true,
  })
export class LevelIconDirective {
    @Input() level!:number;
    @Input() size!:string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    ngOnInit() {
          
        let imgPath:string
        if(this.level == 1) imgPath = '/assets/custom_img/profile/bronze_medal.png'
        else if(this.level == 2) imgPath = '/assets/custom_img/profile/silver_medal.png'
        else if(this.level == 3) imgPath = '/assets/custom_img/profile/gold_medal.webp'
        else if(this.level == 4) imgPath = '/assets/custom_img/profile/platinum_medal.png'
        else if(this.level == 5) imgPath = '/assets/custom_img/profile/diamond_medal.webp'
        else imgPath = '/assets/custom_img/profile/nostage.webp'

        const div = this.document.createElement('div')
        div.classList.add('relative', this.size)

        const levelImage = this.document.createElement('img')
        levelImage.classList.add(`w-100`)
        levelImage.setAttribute(`src`, imgPath)
        this.renderer.appendChild(div, levelImage)

        const lightImage = this.document.createElement('img')
        lightImage.classList.add(`absolute`,`left-0`,`top-0`,`w-full`,`h-full`)
        lightImage.setAttribute(`src`, `/assets/custom_img/profile/light.svg`)
        this.renderer.appendChild(div, lightImage)

        this.el.nativeElement.appendChild(div)
    }
}