import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-rollover-detail-modal',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './rollover-detail-modal.component.html',
  styles: ``
})
export class RolloverDetailModalComponent {
    
    modalTab = ''

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
    }
}
