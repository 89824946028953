<!-- 최신베팅&레이스 > 롤링대회 > 내역 모달 -->
<div class="modal-content">
    <div class="modal-body bg-stand relative rounded">
        <ng-template [ngIf]="isLoading">
            <app-loading />
        </ng-template>
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-3">
                <p class="text-tit font-extrabold text-base">내역</p>
                <span>{{ date | moment:'YYYY.MM.DD'}}</span>
            </div>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-5">
            <div class="overflow-x-auto scrollbar_x">
                <table class="table table-hover noline mo_pad-s rounded min-w-[580px]">
                    <thead class="text-center text-xs text-sub">
                        <tr>
                            <th class="whitespace-nowrap text-left">#</th>
                            <th class="whitespace-nowrap">플레이어</th>
                            <th class="whitespace-nowrap">롤링된</th>
                            <th class="whitespace-nowrap text-right">상품/상금</th>
                        </tr>
                    </thead>
                    <tbody class="font-extrabold text-center cursor-pointer">
                        <ng-container *ngFor="let items of historyItems; let i = index;">
                            <tr>
                                <td class="text-left font-normal">
                                    <ng-template [ngIf]="items.rank <= 3">
                                        <img class="w-5" [src]="medalImg[items.rank - 1]" />
                                    </ng-template>
                                    <ng-template [ngIf]="items.rank > 3">
                                        {{ items.rank }}th
                                    </ng-template>
                                </td>
                                <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" [src]="" [uidx]="items.uidx" profile /><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: items.uidx })">{{ items.nick }}</b></td>
                                <td><b class="text-primary">₩{{ items.total_bet | number:'1.0-2'}}</b></td>
                                <td class="text-right text-primary">₩{{ items.prize_amount | number:'1.0-2'}} <span class="text-sub">({{items.prize_ratio}}%)</span></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
