import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ModalService } from '../../common/modal/modal.service';


@Component({
  selector: 'app-vip',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './vip.component.html',
  styles: ``
})
export class VipComponent {

    get modal() { return this.modalService }
    constructor(
        private modalService: ModalService
    ){}
}
