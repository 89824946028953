import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ManagerOptions, Socket, SocketOptions, Manager, io } from "socket.io-client";
import * as customParser from 'socket.io-msgpack-parser'

@Injectable({
    providedIn: 'root'
})
export class SocketIoService {

    constructor() {
    }

    connect(url:string, opts?: Partial<ManagerOptions & SocketOptions>, parser:boolean = true) {
        const options =  { ...opts }
        if(parser) options.parser = customParser

        const socket = io(url, options)

        const observable = new Observable<{ cmd:string, data:any }>(observer => {
            socket.onAny((cmd:string, data:any) => {
                observer.next({ cmd: cmd, data: data });
            });
            return () => { socket.disconnect(); };  
        });

        return observable;
    }
}