<!-- 롤 모달 -->
<div class="modal-content roll-modal overflow-hidden relative rounded">
    <div class="modal-body">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">롤</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>

        <div class="w-full p-6 overflow-y-auto scrollbar h-auto sm:h-[640px]">
            <div>
                <h4 class="text-tit text-base font-extrabold">롤 포인트 게임</h4>
                <p class=text-xs>베팅을 한 플레이어는 매일 한 번의 롤 기회를 갖습니다.<b class="text-primary font-bold">(VIP3)</b></p>
            </div>

            <div class="my-5 bg-modaldark rounded p-2 py-10">
                <div class="relative flex justify-center">
                    <div class="absolute left-0 top-0 w-full h-full flex items-center justify-center text-center overflow-hidden font-bold">
                        <div class="w-16 mx-4">
                            <div class="scroll_box relative" style="top:80px">
                                <div class="num_box">
                                    <span>1</span>
                                    <span>2</span>
                                    <span>3</span>
                                    <span>4</span>
                                    <span>5</span>
                                    <span>6</span>
                                    <span>7</span>
                                    <span>8</span>
                                    <span>9</span>
                                    <span>0</span>
                                </div>
                            </div>
                        </div>
                        <div class="w-16 mx-4">
                            <div class="scroll_box type02 relative" style="top:160px">
                                <div class="num_box">
                                    <span>1</span>
                                    <span>2</span>
                                    <span>3</span>
                                    <span>4</span>
                                    <span>5</span>
                                    <span>6</span>
                                    <span>7</span>
                                    <span>8</span>
                                    <span>9</span>
                                    <span>0</span>
                                </div>
                            </div>
                        </div>
                        <div class="w-16 mx-4">
                            <div class="scroll_box type03 relative" style="top:0">
                                <div class="num_box">
                                    <span>1</span>
                                    <span>2</span>
                                    <span>3</span>
                                    <span>4</span>
                                    <span>5</span>
                                    <span>6</span>
                                    <span>7</span>
                                    <span>8</span>
                                    <span>9</span>
                                    <span>0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img class="w-16 mx-4" src="/assets/custom_img/bonus/egg.png" alt="">
                    <img class="w-16 mx-4" src="/assets/custom_img/bonus/egg.png" alt="">
                    <img class="w-16 mx-4" src="/assets/custom_img/bonus/egg.png" alt="">
                </div>
            </div>

            <div class="text-center">
                <button class="btn-green w-4/5 h-12 font-bold" disabled>롤</button>
            </div>
            <div class="flex items-center justify-between py-5 text-xs border-b border-slate-300 border-opacity-40">
                <p>카운트다운: 21:55:33</p>
                <p>남은번호: <span class="text-primary">0</span></p>
            </div>

            <div class="mt-6 text-tit text-xs">오늘의 보상: <span class="text-primary">50 BCD</span></div>

            <div class="py-4">
                <table class="table noline table-striped striped-dark rounded text-xs pad-bl">   
                    <thead>
                        <tr class="text-center">
                            <th class="whitespace-nowrap text-left">랭킹</th>
                            <th class="whitespace-nowrap">플레이어</th>
                            <th class="whitespace-nowrap">결과</th>
                            <th class="whitespace-nowrap text-right">보너스</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr>
                            <td>1</td>
                            <td class="text-tit font-bold">Orengenas</td>
                            <td>998</td>
                            <td class="text-right text-tit font-bold">20 BCD <img class="inline-flex w-5" src="/assets/custom_img/coin/BCD.webp" alt=""></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td class="text-tit font-bold">Xhyaigvfnyb</td>
                            <td>997</td>
                            <td class="text-right text-tit font-bold">10 BCD <img class="inline-flex w-5" src="/assets/custom_img/coin/BCD.webp" alt=""></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td class="text-tit font-bold">orignal007</td>
                            <td>997</td>
                            <td class="text-right text-tit font-bold">5 BCD <img class="inline-flex w-5" src="/assets/custom_img/coin/BCD.webp" alt=""></td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td class="text-tit font-bold">Ciko☘️</td>
                            <td>997</td>
                            <td class="text-right text-tit font-bold">2.5 BCD <img class="inline-flex w-5" src="/assets/custom_img/coin/BCD.webp" alt=""></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td class="text-tit font-bold">✦𝐊𝐄𝐕𝐈𝐍✦</td>
                            <td>997</td>
                            <td class="text-right text-tit font-bold">2.5 BCD <img class="inline-flex w-5" src="/assets/custom_img/coin/BCD.webp" alt=""></td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td class="text-tit font-bold">infinite19</td>
                            <td>997</td>
                            <td class="text-right text-tit font-bold">2 BCD <img class="inline-flex w-5" src="/assets/custom_img/coin/BCD.webp" alt=""></td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td class="text-tit font-bold">Ldwckxpinyb</td>
                            <td>996</td>
                            <td class="text-right text-tit font-bold">2 BCD <img class="inline-flex w-5" src="/assets/custom_img/coin/BCD.webp" alt=""></td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td class="text-tit font-bold">SøFìYâÑ</td>
                            <td>995</td>
                            <td class="text-right text-tit font-bold">2 BCD <img class="inline-flex w-5" src="/assets/custom_img/coin/BCD.webp" alt=""></td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td class="text-tit font-bold">bigger117</td>
                            <td>995</td>
                            <td class="text-right text-tit font-bold">2 BCD <img class="inline-flex w-5" src="/assets/custom_img/coin/BCD.webp" alt=""></td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td class="text-tit font-bold">Milky Gunter</td>
                            <td>995</td>
                            <td class="text-right text-tit font-bold">2 BCD <img class="inline-flex w-5" src="/assets/custom_img/coin/BCD.webp" alt=""></td>
                        </tr>
                    </tbody>
                </table>
                
            </div>


            <div>
                <h6 class="font-bold">롤 시간</h6>
                <p class="mt-1 text-xs">매일 UTC+0 02:00부터 02:10까지 시작; 보상은 나중에 분배됩니다.</p>
                <h6 class="mt-3 font-bold">롤 게임 방법</h6>
                <ul class="pl-4 list-decimal text-xs mt-1">
                    <li>플랫폼 다른 통화와 금액을 보상으로 받습니다.</li>
                    <li>베팅 플레이어는 (JB) 플레이어를 제외하고 상위 10개 롤 포인트를 기준으로 보상을 받을 기회를 얻을 수 있습니다.</li>
                    <li>롤은 0에서 999 사이의 임의의 숫자입니다. 행운을 빕니다!</li>
                    <li>다른 플레이어라면 같은 양을 굴리면 시간에 따라 순위가 결정됩니다.</li>
                </ul>
            </div>
            
            

        </div>
    </div>
</div>
