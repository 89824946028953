<div class="modal-content h-full sm:h-auto">
    <div class="modal-head" style="position: sticky;">
        <div class="flex items-center justify-between p-4">
            <a href="javascript:;" class="text-tit font-extrabold text-base" data-tw-dismiss="modal">
                <img src="/assets/custom_img/logo_small.webp" alt="" class="w-6" loading="lazy">
            </a>
            <button class="basic-hover" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
    </div>
    <div class="modal-body flex flex-wrap content-start relative h-full sm:h-auto">
        <iframe #iframeEl [src]="options.url | sanitize:'url'" width="100%" (load)="iframeLoad($event)" [style]="options.style || 'height:100vh;margin:0.5rem;'"></iframe>
    </div>
</div>
