import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { SanitizePipe } from '@app/shared/lib/pipe/sanitize.pipe';
import { PopupService } from './popup.service';
import { fadeIn, fadeOut } from '@app/shared/lib/animation';

@Component({
    selector: 'app-popup',
    standalone: true,
    imports: [
        CommonModule,
        SanitizePipe
    ],
    templateUrl: './popup.component.html',
    styles: `
.popup-container {
    box-sizing: border-box;
    margin-top: 50px;
    display: inline-block;
    width: 30%;
    max-width: 380px;
    z-index: 12;
    background-color: #191a21;
    color: #fff;
    font-size: 12px;
    padding-top: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
}

.popup-container .close-button {
    height: 30px;
    line-height: 30px;
    box-sizing: border-box
}

.popup-container .close-button-wrap button {
    height: 30px;
    line-height: 33px;
    border: 0;
    outline: 0;
    background-color: transparent;
    font-weight: normal;
    color: #ffce43;
    float: right;
    cursor: pointer
}

.popup-container p {
    font-size: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.04);
    box-sizing: border-box
}

.popup-container p img {
    width: 100% !important
}

@media only screen and (max-width:980px) {
    .popup-container {
        box-sizing: border-box;
        margin-top: 50px;
        display: inline-block;
        width: 80%;
        z-index: 12;
        background-color: #313238;
        color: #fff;
        font-size: 12px;
        padding-top: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%)
    }
}    
    `,
    animations:[
        fadeIn,
        fadeOut,
    ]
})
export class PopupComponent {
    popup = {
        items: <any>[],
    }
    constructor(
        private readonly popupService: PopupService
    ) {}

    ngOnInit() {
        this.popupService.getData().subscribe(v => {
            this.popup.items = []
            const session = sessionStorage.getItem('disable_popup');
            const disable = (session) ? JSON.parse(session) : []
            v.map((v:any) => {
                const k = disable.find((idx:any) => idx == v.idx)
                if(!k) {
                    this.popup.items.push(v);
                }
            })
        })
    }

    close(idx:string | undefined) {
        const index = this.popup.items.findIndex((v:any) => v.idx == idx)
        if(index !== -1) this.popup.items.splice(index, 1);

        const session = sessionStorage.getItem(`disable_popup`)
        const disable = (session) ? JSON.parse(session) : []
        disable.push(idx)

        sessionStorage.setItem('disable_popup', JSON.stringify(disable));
    }
}
