<!-- 입금 보너스 모달 -->
<div class="modal-content overflow-hidden relative rounded">
    <div class="modal-body">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">입금 보너스 규칙</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>

        <div class="w-full p-6 overflow-y-auto scrollbar h-auto sm:h-[640px]">
            <div>KBC GAME은 독점적인 4개의 입금 추가 보너스를 제공합니다. 이 환상적인 기회를 활용하여 KBC GAME의 탁월한 보너스로 지갑의 보유량을 늘리십시오.롤링규정(슬롯&미니게임 100%, 스포츠 조합 100%, 스포츠 단폴 200%, 카지노 300%) 보너스 머니 포함</div>
            <div class="grid grid-cols-2 gap-3 mt-4">
                <div class="rounded bg-backlight pb-3">
                    <div class="relative text-black">
                        <img src="/assets/custom_img/bonus/1st.webp" alt="">
                        <span class="absolute left-0 top-4 w-full text-center text-base font-bold">1st 입금</span>
                        <p class="absolute left-0 bottom-4 w-full text-center text-lg font-bold"><b class="block text-4xl">5%</b>보너스</p>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>최소 입금(원화기준)</span>
                        <b class="text-tit font-bold">10,000원</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>최소 입금(USDT)</span>
                        <b class="text-tit font-bold">10 USDT</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>획득 가능한 최대 수량</span>
                        <b class="text-tit font-bold">300,000원</b>
                    </div>
                </div>
                <div class="rounded bg-backlight pb-3">
                    <div class="relative text-black">
                        <img src="/assets/custom_img/bonus/2nd.webp" alt="">
                        <span class="absolute left-0 top-4 w-full text-center text-base font-bold">2nd 입금</span>
                        <p class="absolute left-0 bottom-4 w-full text-center text-lg font-bold"><b class="block text-4xl">10%</b>보너스</p>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>최소 입금(원화기준)</span>
                        <b class="text-tit font-bold">50,000원</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>최소 입금(USDT)</span>
                        <b class="text-tit font-bold">50 USDT</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>획득 가능한 최대 수량</span>
                        <b class="text-tit font-bold">300,000원</b>
                    </div>
                </div>
                <div class="rounded bg-backlight pb-3">
                    <div class="relative text-black">
                        <img src="/assets/custom_img/bonus/3rd.webp" alt="">
                        <span class="absolute left-0 top-4 w-full text-center text-base font-bold">3rd 입금</span>
                        <p class="absolute left-0 bottom-4 w-full text-center text-lg font-bold"><b class="block text-4xl">15%</b>보너스</p>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>최소 입금(원화기준)</span>
                        <b class="text-tit font-bold">100,000원</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>최소 입금(USDT)</span>
                        <b class="text-tit font-bold">100 USDT</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>획득 가능한 최대 수량</span>
                        <b class="text-tit font-bold">300,000원</b>
                    </div>
                </div>
                <div class="rounded bg-backlight pb-3">
                    <div class="relative text-black">
                        <img src="/assets/custom_img/bonus/4th.webp" alt="">
                        <span class="absolute left-0 top-4 w-full text-center text-base font-bold">4th 입금</span>
                        <p class="absolute left-0 bottom-4 w-full text-center text-lg font-bold"><b class="block text-4xl">20%</b>보너스</p>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>최소 입금(원화기준)</span>
                        <b class="text-tit font-bold">200,000원</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>최소 입금(USDT)</span>
                        <b class="text-tit font-bold">200 USDT</b>
                    </div>
                    <div class="flex justify-between mt-1 text-xs px-2">
                        <span>획득 가능한 최대 수량</span>
                        <b class="text-tit font-bold">300,000원</b>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
