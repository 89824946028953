import { ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router, RouterLink, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { ApiService } from '../../service/api.service';
import { Subscription, filter, map, pairwise, startWith } from 'rxjs';
import moment from 'moment-timezone';
import { TimerService } from '../../service/timer.service';
import { CasinoComponent } from '../casino/casino.component';
import { ModalService } from '../../common/modal/modal.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NoticeService } from '../../service/notice.service';
import { SocketIoService } from '../../service/socket-io.service';
import { SanitizePipe } from '@app/shared/lib/pipe/sanitize.pipe';
import { environment } from '../../environments/environment';
import { fadeIn, fadeOut } from '@app/shared/lib/animation';
// import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ImgLoadedDirective } from '@app/shared/lib/directive/img.directive';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';
import { MessageService } from '../../common/message/message.service';
import { SettingsService } from '../auth/settings.service';
import { FilterPipe } from '@app/shared/lib/pipe/filter.pipe';
import { SpinService } from '../../service/spin.service';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { WalletPipe } from '@app/shared/lib/pipe/wallet.pipe';
import { DirectMessageService } from '../../service/direct-message.service';
import { EmojiComponent } from '../../common/emoji/emoji.component';
import { GiphyComponent } from '../../common/emoji/giphy.component';
import { LoadingService } from '../../common/loading/loading.service';
import { LoadingComponent } from '../../common/loading/loading.component';
import { ProfileDirective } from '@app/shared/lib/directive/profile.directive';
import { DirectMessageComponent } from '../../common/direct-message/direct-message.component';
import { SlotsComponent } from '../slots/slots.component';
import { EventHandlerPayload, LiveChatWidgetModule } from "@livechat/widget-angular";

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
        RouterOutlet,
        RouterLink,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DirectMessageComponent,
        EmojiComponent,
        GiphyComponent,
        SanitizePipe,
        MomentPipe,
        ImgLoadedDirective,
        FilterPipe,
        SwiperDirective,
        WalletPipe,
        LoadingComponent,
        ProfileDirective,
        LiveChatWidgetModule,
    ],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './home.component.html',
    styleUrls: [`./home.component.scss`],
    animations:[
        fadeIn,
        fadeOut,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeComponent {
    private subs: Subscription[] = [];
    
    isLoading:boolean=true;
    currentMenu:string = '';
    currentSubMenu:string = '';
    casinoSearch:boolean=false;

    currentUser!:currentUserInfo | null;
    authedTime = 0;
    tokenExpired!:string | null;

    notice_items:any
    quick_top:boolean = false;

    side_nav_bg:boolean = false;
    sideNavFold = false;

    search_keyword:Set<any> = new Set()
    is_searching:boolean = false;
    searchForm!: FormGroup;
    search_items:any = []
    assetsUrl = environment.assetsUrl

    rightOpen_item=""
    tab:string = 'tab-1'
    chat_tab:string = 'notice'
    isDarkMode:boolean = true;
    currency!:string
    currencyInfo = environment.CURRENCY;
    cryptoInfo = environment.CRYPTO;
    search_currency!:string

    isSpin:boolean = false;

    casinoRecomOptions!:SwiperOptions
    casinoRecomItems!:any
    LIVECHAT_LICENSE = environment.LIVECHAT

    onModalEvent: EventEmitter<any> = new EventEmitter<any>()

    
    @ViewChild('searchInputEl') searchInputEl!: ElementRef;

    @HostListener('window:scroll', ['$event']) 
    onScroll($event:any){
        this.quick_top = (window.scrollY > 100) ? true : false;
    }

    @HostListener('window:resize', ['$event'])
    onResize($event:any) {
        if($event.target.innerWidth > 1200) {
            this.side_nav_bg = false;
        } else {
            // this.side_nav_bg = this.sideNavFold ? true : false;
            const sideNav = document.querySelector(".side-nav");
            if(sideNav) {
                this.side_nav_bg = !sideNav.classList.contains('fold') ? true : false;
            }
        }
    }

    get modal() { return this.modalService }
    constructor(
        private authService: AuthService,
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private timerService: TimerService,
        private modalService: ModalService,
        private translateService: TranslateService,
        private messageService: MessageService,
        private noticeService: NoticeService,
        private socketIoService: SocketIoService,
        private formBuilder: FormBuilder,
        private settingsService: SettingsService,
        private spinService: SpinService,
        private directMessageService: DirectMessageService,
        private loadingService: LoadingService
    ) {
        this.searchForm = this.formBuilder.group({
            keyword: ['', Validators.required]
        });

        this.subs.push(
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute.snapshot),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                })
            ).subscribe((route: ActivatedRouteSnapshot) => {
                const url = this.router.routerState.snapshot.url || ''
                this.fnGetNav(url)

                console.log(url)

                this.currentSubMenu = route.data['submenu'] || null
            })
        )

        const search_keyword = sessionStorage.getItem('search_keyword');
        if(search_keyword) this.search_keyword = new Set(JSON.parse(search_keyword))
    }

    ngOnInit(): void {
        this.subs.push(
            this.settingsService.getValue().subscribe(v => {
                this.isDarkMode = v?.dark_mode ? true : false;
            })
        )

        this.subs.push(
            this.authService.getPairwise().subscribe(([prev, current]) => {
                if(!prev && current) {
                    this.directMessageService.update()
                } else if(prev && !current) {
                    this.directMessageService.init()                  
                }

                this.currentUser = current;
                // if(LiveChatWidget) LiveChatWidget.call(`${this.currentUser ? 'hide' : 'minimize'}`)
                if(prev && current) {
                    if(prev.access_token != current.access_token) {
                        console.log(`prev: ${prev.access_token}`)
                        console.log(`current: ${current.access_token}`)
                    }
                }
            })
        )
        

        this.subs.push(
            this.noticeService.getData().subscribe(v => {
                this.notice_items = v;
            })
        )


        // this.subs.push(
        //     this.router.events.pipe(
        //         filter(event => event instanceof NavigationEnd),
        //         map(() => this.activatedRoute.snapshot),
        //         map(route => {
        //             while (route.firstChild) {
        //                 route = route.firstChild;
        //             }
        //             return route;
        //         })
        //     ).subscribe((route: ActivatedRouteSnapshot) => {
        //         this.currentSubMenu = route.data['sub'] || null
        //     })
        // )

        this.subs.push(
            this.timerService.getDate().subscribe(d => {
                const time = d.unix();
                if(this.authedTime + (10) <= time) {
                    this.authedTime = time;

                    if(this.currentUser) {
                        // 토큰 체크하여 만료 10분전에 업데이트
                        // this.tokenExpired = this.authService.getTokenExpirationDate()
                        // if(this.tokenExpired) {
                        //     const d1 = moment.tz(time, 'X', 'Asia/Seoul')
                        //     const d2 = moment.tz(this.tokenExpired, 'Asia/Seoul').add('-10', 'minute')
                        //     if(d2.isBefore(d1)) {
                        //         this.authService.updateToken().subscribe(v => {
                        //         })
                        //     }
                        // }

                        this.authService.authenticated().subscribe({
                            next: (v:any)=> {

                            },
                            error: (err:any) => {
                                console.log(err.statusText)
                                // this.alertService.open(null, err.statusText)
                            }
                        })
                    }
                }
            })
        )

        this.subs.push(
            this.settingsService.getValue().subscribe(v => {
                this.currency = v.currency
            })
        )

        this.subs.push(
            this.onModalEvent.subscribe(v => {
                const { component, event, optParam } = v;
                if(event == 'close') {
                    if(this.currentUser) this.modal.open(null, optParam.next)
                }
            })
        )

        this.subs.push(
            this.spinService.getData().subscribe(v => {
                if(v) {
                    this.modal.open(null, 'spin')
                }
            })
        )

        this.socketIoService.connect(environment.wsUrl, {
            transports: ["websocket"]
        }).subscribe(v => {
            // console.log(v)
        })
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
        console.log(`ngOnDestroy`)
    }

    ngAfterContentInit() {
        console.log(`ngAfterContentInit`)

        // 페이지 로딩이 시작될 때 실행할 함수
        const showLoadingSvg = () => {
            const loadingContainer = document.getElementById("loadingContainer");
            if(loadingContainer) {
                loadingContainer.style.display = "flex";
            }
        }

        showLoadingSvg()

        // 페이지 로딩이 완료된 후 실행할 함수
        const hideLoadingSvg = () => {
            const loadingContainer = document.getElementById("loadingContainer");
            if(loadingContainer) {
                loadingContainer.style.display = "none";
            }
        }        

        



    
        // const foldBtn = document.querySelector(".fold_btn");
        // const sideBg = document.querySelector(".side-nav-bg");
        // if(foldBtn) foldBtn.addEventListener("click", toggleSideNav);
        // if(sideBg) sideBg.addEventListener("click", toggleSideNav);
        
        // 탑바 슬롯 슬라이드
        
        this.isLoading = true;
        hideLoadingSvg();
    }

    ngAfterViewInit(): void {
        // console.log(`ngAfterViewInit`)
    }

    ngAfterViewChecked(): void {
        // console.log(`ngAfterViewChecked: ${moment.tz().toISOString()}`)
    }

    fnGoTop() {
        const element = document.getElementById("page-top");
        if(element){
            element.scrollIntoView({ behavior: "smooth",block: "start", inline: "nearest" })
        }    
    }

    loadScript(url: string) {
        const body = <HTMLDivElement> document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = false;
        body.appendChild(script);
    }

    fnGetNav(url:string): void {
        const sideNavFold = sessionStorage.getItem('sideNavFold')
        this.casinoSearch = false;
        if(url.startsWith('/casino')) {
            this.currentMenu = 'casino';
            this.sideNavFold = sideNavFold ? true : false
        } else if(url.startsWith('/slots')) {
            this.currentMenu = 'slots';
            this.sideNavFold = sideNavFold ? true : false
        } else if(url.startsWith('/sports')) {
            this.currentMenu = 'sports';
            this.sideNavFold = true
        } else if(url.startsWith('/vsports')) {
            this.currentMenu = 'vsports';  
            this.sideNavFold = sideNavFold ? true : false
        } else if(url.startsWith('/powerball')) {
            this.currentMenu = 'powerball';  
            this.sideNavFold = sideNavFold ? true : false            
        } else if(url.startsWith('/minigame')) {
            this.currentMenu = 'minigame';  
            this.sideNavFold = sideNavFold ? true : false            
        } else {
            this.currentMenu = ''
            this.sideNavFold = sideNavFold ? true : false
        }
    }

     // fold 클래스 체크 함수
    checkFold() {
        const sideMenus = document.querySelectorAll(".side-menu");
        const sideNav = document.querySelector(".side-nav");

        if(sideNav && sideNav.classList.contains("fold")) {
            sideMenus.forEach((menu:any) => {
                if (menu._tippy != undefined) {
                    menu._tippy.enable();
                }
            });
        } else {
            sideMenus.forEach((menu:any) => {
                if (menu._tippy != undefined) {
                    menu._tippy.disable();
                }
            });
        }
    }

    toggleSideNav() {
        this.sideNavFold = !this.sideNavFold
        sessionStorage.setItem('sideNavFold', this.sideNavFold ? '1': '')

        // fold 클래스 변경 체크 함수 호출
        this.checkFold();
    }

    fnCasinoSearch() {
        this.searchForm.controls['keyword'].patchValue('')
        this.search_items = []
        this.casinoSearch = !this.casinoSearch
    }

    fnCasinoSearchClose() {
        this.searchForm.controls['keyword'].patchValue('')
        this.search_items = []
        this.casinoSearch = false;
    }

    componentInit(componentRef:any) {
        // if(!(componentRef instanceof CasinoComponent)) return;
        if(componentRef instanceof CasinoComponent || componentRef instanceof SlotsComponent) {
            this.subs.push(
                componentRef.onSearchBtn.subscribe((v) => {
                    if(v == 'casino') {
                        this.casinoSearch = true
                        const elem = this.searchInputEl.nativeElement
                        if(elem) elem.focus()
                    }
                })
            )
        }
    }

    /**
     * Enter Key만 저장
     */
    onKeyUp($event:any) {
        if($event.keyCode == 13)  {
            const keyword = this.searchForm.controls['keyword'].value
            if(keyword.length < 3) return;

            // this.search_keyword.add(keyword)
            // sessionStorage.setItem('search_keyword', JSON.stringify([...this.search_keyword]))
        }

        this.onSearch()
    }

    onSearch() {
        const keyword = this.searchForm.controls['keyword'].value
        if(keyword.length < 3) {
            this.search_items = []
            return;
        }

        this.apiService.post('/casino/search', { keyword: keyword }, false).subscribe(jsondata => {
            if(jsondata.success) {
                if(jsondata.data.length > 0) {
                    this.search_keyword.add(keyword)
                    sessionStorage.setItem('search_keyword', JSON.stringify([...this.search_keyword]))
                }

                this.search_items = jsondata.data
            }
        })
    }

    clearSearchHistory(keyword: string = '') {
        if(!keyword) {
            this.search_keyword = new Set()
            sessionStorage.setItem('search_keyword', JSON.stringify([]))
        } else {
            // this.search_keyword.splice(index, 1)
            this.search_keyword.delete(keyword)
            console.log(this.search_keyword)
        }
    }

    rightOpen(target:string=''){
        const content = document.querySelector('.content');
        const right = document.querySelector('.right-area')

        if(target == "close" || target == this.rightOpen_item){
            // 닫기버튼 or 같은버튼을 두번 눌렀을때,
            content?.classList.remove('right_open')
            right?.classList.remove('open')

            const use = document.querySelector('.top-bar .chat_btn use')
            if(use) use.setAttribute('xlink:href', '/assets/custom_img/symbol-defs.svg#icon_Chat')

            this.rightOpen_item = "";
        }else{
            content?.classList.add('right_open')
            right?.classList.add('open')

            const el = <HTMLElement>document.querySelector(`.right-area .${target}`)
            if(el) {
                el.style.removeProperty('display')
                const siblings = Array.prototype.slice.call(el.parentNode?.children);
                siblings.forEach((sibling) => {
                    if (sibling !== el) sibling.style.display = 'none';
                })
            }
        
            if(target == "chat_area"){
                const use = document.querySelector('.top-bar .chat_btn use')
                if(use) use.setAttribute('xlink:href', '/assets/custom_img/symbol-defs.svg#icon_CloseChat')
            }
            this.rightOpen_item = target;
        }
    }

    openModal($event:any, modal:string) {
        if(this.currentUser) this.modal.open($event, modal) 
        else {
            this.modal.open($event, 'sign-in', { next: modal }, this.onModalEvent)
        }
    }

    setDarkMode(isDark:boolean) {
        this.settingsService.setValue({ dark_mode: isDark })

        this.authService.update({ dark_mode:isDark }).subscribe()
    }

    setCurrency(currency:string): void {
        this.settingsService.setValue({ currency: currency })
        this.authService.update({ currency: currency }).subscribe()

        const el = document.querySelector('.walletDropdown')
        if(el) el.classList.remove('show')
    }

    notFoundCurrency() {
        if(!this.search_currency) return false;
        const v = this.search_currency
        const currency = this.currencyInfo.filter(it => it.is_major === true && it['code'].toString().toUpperCase().indexOf(v.toString().toUpperCase()) !== -1);
        if(currency.length> 0) return false;

        const crypto = this.cryptoInfo.filter(it => it.is_show === true && it['code'].toString().toUpperCase().indexOf(v.toString().toUpperCase()) !== -1);
        if(crypto.length > 0) return false;

        return true;
    }

    handleNewEvent(event: EventHandlerPayload<"onNewEvent">) {
        console.log("LiveChatWidget.onNewEvent", event);
      }
}
