import { Component } from '@angular/core';
import { MemoListComponent } from './memo-list.component';
import { MemoReadComponent } from './memo-read.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-memo',
    standalone: true,
    imports: [
        CommonModule,
        MemoListComponent,
        MemoReadComponent
    ],
    templateUrl: './memo.component.html',
    styles: ``
})
export class MemoComponent {
    page!:number;
    idx!:number | null

    read(idx:number) {
        this.idx = idx
    }

    list() {
        this.idx = null
    }
}
