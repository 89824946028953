import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-spin-rolling2-modal',
    standalone: true,
    imports: [
        CommonModule
    ],
    templateUrl: './spin-rolling2-modal.component.html',
    styles: ``
})
export class SpinRolling2ModalComponent {
    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    
    constructor() {}
}
