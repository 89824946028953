import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../auth/settings.service';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-currency-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './currency-modal.component.html',
  styles: ``
})
export class CurrencyModalComponent {
    
    @Input() options:any = {};
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    
    lang!:string
    currency!:string
    langOption = environment.LANG
    currencyOption = environment.CURRENCY
    constructor(
        private settingsService: SettingsService,
        private authService: AuthService
    ) {
        this.options['tab'] = 'lang-tab-1'
    }

    ngOnInit(): void {
       this.settingsService.getValue().subscribe(v => {
            this.lang = v.lang
            this.currency = v.currency
       })
    }
    
    setLang(lang:string): void {
        this.settingsService.setValue({ lang: lang })
        this.authService.update({ lang:lang }).subscribe()
        
        this.onClose.emit();
    }

    setCurrency(currency:string): void {
        this.settingsService.setValue({ currency: currency })
        this.authService.update({ currency: currency }).subscribe()
        
        this.onClose.emit();
    }

    modalTab($event:any) {
        this.options['tab'] = $event;
    }
}
