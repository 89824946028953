import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SanitizePipe } from '@app/shared/lib/pipe/sanitize.pipe';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-iframe-modal',
    standalone: true,
    imports: [
        SanitizePipe
    ],
    templateUrl: './iframe-modal.component.html',
    styles: ``
})
export class IframeModalComponent {
    private subs: Subscription[] = [];
    
    @Input() options:any;
    @Input() eventEmitter:EventEmitter<any> = new EventEmitter<any>()

    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();


    iframeHeight:string = '100vh';

    @ViewChild('iframeEl') iframeEl!: ElementRef;

    @HostListener('window:resize', ['$event'])
    onResize($event:any) {
        const innerHeight = $event.target.innerHeight;
        this.iframeHeight = innerHeight - 65 + 'px';
    }

    constructor() {
        
    }

    ngOnInit() {
        this.iframeHeight = window.innerHeight - 65 + 'px';
    }

    ngAfterViewInit() {
        
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['eventEmitter']) {
            const prev = changes['eventEmitter'].previousValue;
            const current = changes['eventEmitter'].currentValue
            if(current) {
                this.subs.push(
                    this.eventEmitter.subscribe(v => {
                        const { event } = v
                        if(event == 'close_iframe') this.onClose.emit('close')
                    })
                )
            }
        }
    }

    iframeLoad($event:any): void {

    }
}
