import { Directive, ElementRef, HostListener, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import moment, { Moment } from 'moment-timezone'
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';

@Directive({
    selector: '[countdown]',
    standalone: true,
  })
export class CountdownDirective {
    @Input('countdown') countdown!: string;
    @Input('tz') tz:string = 'Asia/Seoul'

    timer$: Observable<number> = timer(0, 100);
    
    sub!:Subscription
    remain_x!:number
    constructor(
        private elementRef: ElementRef,
    ) {

    }
  
    ngOnInit() {
        const el = this.elementRef.nativeElement

        this.remain_x = moment.tz(this.countdown, this.tz).unix()

        this.sub = this.timer$.subscribe(v => {
            const now_x = moment.tz('Asia/Seoul').unix()
            const remain = this.remain_x - now_x;
            if(remain > 0) {
                const minutes = Math.floor(remain / 60);
                const seconds = String(remain % 60).padStart(2, "0");
                el.innerHTML = `${minutes}분 ${seconds}초`
            } else {
                el.innerHTML = `인증시간이 만료 되었습니다.`
            }
        })
    }

    ngOnDestroy() {
        if(this.sub) this.sub.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['countdown']) {
            const prev = changes['countdown'].previousValue;
            const current = changes['countdown'].currentValue
            if(current) {
                this.remain_x = moment.tz(current, this.tz).unix()
            }
        }
    }
}