import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-jb-info-modal',
  standalone: true,
  imports: [],
  templateUrl: './jb-info-modal.component.html',
  styles: ``
})
export class JbInfoModalComponent {
    
}
