import { animate, style, transition, trigger } from "@angular/animations";

export const fadeIn = trigger('fadeIn', [
    transition(':enter', [ // :enter is alias to 'void => *'
        style({
                opacity: 0
        }),
        animate('200ms ease-in', style({
                opacity: 1
        }))
    ])
]);
  
export const fadeOut = trigger('fadeOut', [
    transition(':leave', [ // :leave is alias to '* => void'
        style({
            opacity: 1
        }),
        animate('200ms ease-out', style({
            opacity: 0
        }))
    ])
]);

export const fadeRight = [
    trigger("fadeRight", [
        transition(":enter", [
            style({ opacity: 0, transform: "translateX(-100%)" }), //apply default styles before animation starts
            animate(
                "200ms ease-in-out",
                style({ opacity: 1, transform: "translateX(0)" })
            )
        ]),
        transition(":leave", [
            style({ opacity: 1, transform: "translateX(0)" }), //apply default styles before animation starts
            animate(
                "200ms ease-in-out",
                style({ opacity: 0, transform: "translateX(-100%)" })
            )
        ])
    ])
];
  
export const fadeLeft = [
    trigger("fadeLeft", [
        transition(":enter", [
            style({ opacity: 0, transform: "translateX(100%)" }), //apply default styles before animation starts
            animate(
                "200ms ease-in-out",
                style({ opacity: 1, transform: "translateX(0)" })
            )
        ]),
        transition(":leave", [
            style({ opacity: 1, transform: "translateX(0)" }), //apply default styles before animation starts
            animate(
                "200ms ease-in-out",
                style({ opacity: 0, transform: "translateX(100%)" })
            )
        ])
    ])
];
  