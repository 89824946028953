<!-- 플레이 방법 모달 -->
<div class="modal-content overflow-hidden relative rounded" [ngClass]="{ 'on': modalTab }">
    <!-- 플레이방법 -->
    <div class="modal-body bg-stand relative rounded">
        <div class="relative flex items-center justify-between p-4 ">
            <div class="w-5 h-5"></div>
            <p class="text-tit text-lg">플레이 방법</p>
            <button class="basic-hover" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-4 py-6 h-[660px] overflow-y-auto scrollbar">
            <div class="relative overflow-hidden play_slide">
                <ul class="swiper-wrapper">
                    <li class="swiper-slide">
                        <img class="w-56 mx-auto" src="/assets/custom_img/lottery/buyticket.png" alt="">
                        <p class="text-lg font-bold text-tit text-center">Buy Tickets</p>
                        <p class="mt-2 text-center">Buy a ticket for $0.1 and choose numbers for the ticket.</p>
                    </li>
                    <li class="swiper-slide">
                        <img class="w-56 mx-auto" src="/assets/custom_img/lottery/draw.png" alt="">
                        <p class="text-lg font-bold text-tit text-center">Wait For The Draw</p>
                        <p class="mt-2 text-center">Wait for the draw at 00:00, 08:00, 16:00 UTC+0 daily</p>
                    </li>
                    <li class="swiper-slide">
                        <img class="w-56 mx-auto" src="/assets/custom_img/lottery/checkprizes.png" alt="">
                        <p class="text-lg font-bold text-tit text-center">Check the Prizes</p>
                        <p class="mt-2 text-center">Once the draw is over, come back to this page and check your prize.</p>
                    </li>
                </ul>
                <button class="absolute left-0 prev_btn bg-modaldark w-8 h-8 rounded"><svg class="w-4 h-4 fill-basic mx-auto rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <button class="absolute right-0 next_btn bg-modaldark w-8 h-8 rounded"><svg class="w-4 h-4 fill-basic mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
            </div>
            <div class="border-b border-slate-300 my-5"></div>
            <p class="text-tit text-lg text-center">Lottery Rules</p>
            <p class="text-tit font-bold">Lottery Instructions</p>
            <ul class="list-disc pl-5 mt-2">
                <li class="mb-2">A provably fair algorithm is used to draw the lottery prizes.</li>
                <li class="mb-2">Prizes are drawn every 8 hours, at 00:00, 08:00, 16:00 UTC+0.</li>
                <li class="mb-2">You can buy a ticket for $0.1. The sale of tickets stops at 14:55 UTC+0 every day.</li>
                <li class="mb-2">The player chooses six numbers for each ticket, the first five are from 1 to 36, and the last one is from 1 to 10.</li>
                <li class="mb-2">You can choose numbers manually or automatically.</li>
                <li class="mb-2">If you have __BC_BCL__, you can use __BC_BCL__ to redeem Tickets.</li>
                <li class="mb-2">Each draw produces six numbers.</li>
                <li class="mb-2">The more numbers you match in the first five numbers, greater prize you will win.</li>
            </ul>
            <button (click)="modalInHandle('provably_modal')" class="h-12 w-1/2 bg-back rounded text-primary font-bold">
                Provably Fair <svg class="inline-flex w-4 h-4 fill-primary "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
            </button>

            <p class="text-tit font-bold mt-10">Winning Prize Details:</p>
            <div class="bg-modaldark rounded">
                <table class="table mo_pad-s pad-s lg:text-sm text-xs">
                    <thead>
                        <tr>
                            <th class="whitespace-nowrap">시합</th>
                            <th class="whitespace-nowrap text-center">수</th>
                            <th class="whitespace-nowrap text-right">보상</th>
                        </tr>
                    </thead>
                    <tbody class="text-tit">
                        <tr>
                            <td class="text-primary font-bold">
                                <div class="flex items-center gap-1">
                                    <img class="w-5 animate-spin" src="/assets/custom_img/lottery/star.png" alt="">
                                    <p>5 수<br/>JACKPOT BALL</p>
                                </div>
                            </td>
                            <td>
                                <div class="flex gap-1.5">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                </div>
                            </td>
                            <td class="text-right text-primary font-bold">$100000.00 <svg class="w-3 h-3 ml-auto mr-0 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites1"></use></svg></td>
                        </tr>
                        <tr>
                            <td><p class="pl-6">5 수</p></td>
                            <td>
                                <div class="flex gap-1.5">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                </div>
                            </td>
                            <td class="text-right ">$3000.00</td>
                        </tr>
                        <tr>
                            <td><p class="pl-6">4 수</p></td>
                            <td>
                                <div class="flex gap-1.5">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                </div>
                            </td>
                            <td class="text-right ">$20.00</td>
                        </tr>
                        <tr>
                            <td><p class="pl-6">4 수</p></td>
                            <td>
                                <div class="flex gap-1.5">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                </div>
                            </td>
                            <td class="text-right ">$1.00</td>
                        </tr>

                        <tr>
                            <td><p class="pl-6">No numbers</p></td>
                            <td>
                                <div class="flex gap-1.5">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                    <img class="w-5 opacity-50" src="/assets/custom_img/lottery/empty_ball.png" alt="">
                                </div>
                            </td>
                            <td class="text-basic text-right"><img class="w-8 ml-auto mr-0" src="/assets/custom_img/lottery/bonus.png" alt=""> +1 ticket</td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    </div>

    <!-- provably -->
    <div class="provably_modal modal-in modal-body bg-stand relative rounded" [ngClass]="{ 'open': modalTab == 'provably_modal'}">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('provably_modal')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="./assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">Provably Fair</p>
            </div>
            <div>
                <button (click)="modalInHandle('fair_modal')">
                    <svg class="w-5 h-5 fill-basic opacity-70"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Fairness"></use></svg>
                </button>
                <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
            </div>
        </div>
        <div class="p-4 py-6 h-[660px] overflow-y-auto scrollbar">
            Lottery is drawn in 5+1 balls rule, with five regular balls taken from 36 numbers and a jackpot ball taken from 10 numbers.<br/><br/>
            Server seed: Each game generates a new server seed and publishes the string after Hash256. Client Seeds: Get the ETH height at the end of the daily ticket purchase deadline, and use Hash after 10 blocks as the client seed.<br/><br/>
            First, use HMAC_SHA256 to calculate the hash value, which gives us a 64-bit character hex string:
            <div class="p-3 my-2 border border-slate-300 border-opacity-50 bg-back text-primary">
                hash = HMAC_SHA256 (clientSeed, serverSeed)
            </div>
            Then, take the 8 characters of hash and convert it to an int32 value. We divide the converted value by the 0x100000000, multiply by the number of balls, and the number is the winning position, according to which the corresponding ball is obtained.
        </div>
    </div>

    <!-- 공정성 -->
    <div class="fair_modal modal-in modal-body bg-stand relative rounded" [ngClass]="{ 'open': modalTab == 'fair_modal'}">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex gap-2 items-center">
                <button (click)="modalInHandle('fair_modal')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="./assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">복권 공정성</p>
            </div>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-4 py-6 max-h-[660px] overflow-y-auto scrollbar">
            <div class="border border-primary rounded bg-primary bg-opacity-10 px-5 py-3 text-xs text-primary">
                BC.GAME Lottery is provably fair which means you can examine the results usingand <a class="underline text-sm font-bold" href="javascript:;">온라인 검증 <svg class="inline-flex w-3 h-3 mx-auto fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a> following data. The whole data including the file with purchased tickets is available only when ticket sales stopped.
            </div>
            <div class="mt-3 flex items-center gap-2">
                서버시드 (hash)
                <div class="tooltip_custom pt-1.5">
                    <button><svg class="w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>
                    <div class="hover_box top bg-back w-40 rounded p-2 text-center">서버 시드의 SHA-256</div>
                </div>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02" value="vaser15wersgwer6asdgerhy6uty" readonly>
                <button class="absolute right-3 top-[14px] basic-hover" onclick="viewAlert('copy_alert')"><svg class="w-4 h-4"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Copy"></use></svg></button>
            </div>

            <div class="mt-3 flex items-center gap-2">
                서버시드 
                <div class="tooltip_custom pt-1.5">
                    <button><svg class="w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>
                    <div class="hover_box top bg-back w-40 rounded p-2 text-center">서버에서 무작위로 생성된 문자열</div>
                </div>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02" value="vaser15wersgwer6asdgerhy6uty" readonly>
            </div>

            <div class="mt-3 flex items-center gap-2">
                블록 멈추기 
                <div class="tooltip_custom pt-1.5">
                    <button><svg class="w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>
                    <div class="hover_box top bg-back w-40 rounded p-2 text-center">추첨일 14:55 UTC+0의 ETH 블록체인 높이</div>
                </div>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02" value="56198651" readonly>
            </div>

            <div class="mt-3 flex items-center gap-2">
                고객 시드 잠김
                <div class="tooltip_custom pt-1.5">
                    <button><svg class="w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>
                    <div class="hover_box top bg-back w-40 rounded p-2 text-center">스톱 블록에서 10 블록 후 ETH 블록 체인 높이</div>
                </div>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02" value="56198651" readonly>
            </div>

            <div class="mt-3 flex items-center gap-2">
                클라이언트 시드(hashed)를 선택하세요.
                <div class="tooltip_custom pt-1.5">
                    <button><svg class="w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>
                    <div class="hover_box top bg-back w-40 rounded p-2 text-center">ETH 블록체인의 목표 블록</div>
                </div>
                <button class="hover:text-primary"><svg class="inline-flex w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Download"></use></svg> 모든 티켓 목록 파일</button>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02" value="56198651" readonly>
            </div>
            <div class="mt-10 text-center">
                <button class="btn-green w-2/3 h-12 font-bold">인증 <svg class="inline-flex w-3 h-3 mx-auto fill-title"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></button>
            </div>
        </div>
    </div>
</div>
