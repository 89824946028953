<!-- 입금 모달 -->
<div class="modal-content overflow-hidden relative rounded">
    <ng-template [ngIf]="isLoading">
        <app-loading />
    </ng-template>
    
    <!-- 기본 -->
    <div class="modal-body relative">
        <div class="relative px-5 bg-modaldark rounded-t">
            <div class="flex items-center justify-between py-3">
                <p class="text-tit font-extrabold text-base">출금</p>
                <button class="basic-hover px-2 ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
            </div>
            <div class="sm:px-5 pb-3">
                <ul class="nav nav-boxed-tabs bg-slate-200 dark:bg-back" role="tablist">
                    <li class="nav-item w-full" role="presentation">
                        <button class="w-full h-9" [ngClass]="{'active': tab == 'currency'}"  type="button" (click)="tab='currency'">법정화폐</button>
                    </li>
                    <li class="nav-item w-full" role="presentation">
                        <button class="w-full h-9" [ngClass]="{'active': tab == 'crypto'}" type="button" (click)="tab='crypto'">가상화폐</button>
                    </li>
                </ul>
            </div>

        </div>
        <div class="tab-content overflow-y-auto scrollbar h-auto sm:h-[680px] text-sub">
            <div class="tab-pane leading-relaxed p-6 sm:px-10" [ngClass]="{'active': tab == 'currency'}" >
                <form role="form" [formGroup]="form1" (ngSubmit)="onSubmit()">
                    <div class="modal-tab-wrap">
                        <!-- 입금 -->
                        <div class="deposit-detail">
                            <div class="flex items-center mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold" *ngIf="in_progress">
                                <div class="flex-1">출금신청이 진행중 입니다.</div>
                                <button type="button" class="btn btn-purple2 p-1" (click)="onCancelProc()">취소하기</button>
                            </div>
                            <div class="mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold">
                                참고: <span class="text-tit">출금계좌 변경은 고객센터를 통해서만 가능합니다.</span>
                            </div>
                            <div class="flex items-center justify-between mt-1 bg-modaldark p-1 rounded">
                                <div class="flex items-center gap-4 text-base">
                                    <p class="pl-4 text-tit">₩</p>
                                    <input type="text" formControlName="amount" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" placeholder="" required autocomplete="off" numbersOnly />
                                </div>
                            </div>
                            <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                                <div *ngIf="f['amount'].errors">{{ f['amount'].errors['check_error']}}</div>
                            </div>
                            <div class="cash_btn_box mt-2">
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(10000)">₩ 10,000</button>
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(50000)">₩ 50,000</button>
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(100000)">₩ 100,000</button>
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(300000)">₩ 300,000</button>
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(500000)">₩ 500,000</button>
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(1000000)">₩ 1,000,000</button>
                                <button type="button" class="btn-green h-12 font-bold" (click)="f['amount'].patchValue(0);">초기화</button>
                            </div>
                            <div class="border border-slate-300 bg-tab rounded-lg px-5 py-1 mt-3">
                                <!-- <div class="border-b border-slate-300 pb-2">
                                    <h3 class="text-base text-tit font-extrabold ">롤링정보</h3>
                                </div> -->

                                <div class="flex items-center justify-between pt-2">
                                    <div>
                                        <b class="text-tit">적용중 보너스 롤링</b>
                                    </div>
                                    <div *ngIf="rollingInfo">보너스:{{ rollingInfo['bonus_rate'] | number:'1.0-2' }}% ({{ rollingInfo['rolling_times'] * 100 | number:'1.0-2'}}% 롤링) </div>
                                </div>
                                <div class="flex items-center justify-between pt-2">
                                    <div>
                                        <b class="text-tit">롤링 등록 시간</b>
                                    </div>
                                    <div *ngIf="rollingInfo">{{ rollingInfo['rollingStartedAt'] | moment:'YYYY-MM-DD HH:mm:ss' }}</div>
                                </div>
                                <div class="flex items-center justify-between pt-2">
                                    <div>
                                        <b class="text-tit">롤링 등록 이후 총 충전액</b>
                                    </div>
                                    <div *ngIf="rollingInfo">{{ rollingInfo['rolling_deposit'] | number:'1.0-2'}}</div>
                                </div>
                                <div class="flex items-center justify-between pt-2">
                                    <div>
                                        <b class="text-tit">롤링 등록 이후 총 롤링액 (환전까지 필요한 추가 롤링액)</b>
                                    </div>
                                    <div *ngIf="rollingInfo">{{ rollingInfo['total_rolling'] | number:'1.0-2' }} ({{ this.rollingInfo['need'] | number:'1.0-2'}})</div>
                                </div>
                                <div class="flex items-center justify-between pt-2">
                                    <div>
                                        <b class="text-tit">현재까지 롤링 요율 (환전까지 필요한 추가 롤링 요율)</b>
                                    </div>
                                    <div *ngIf="rollingInfo">{{ this.rollingInfo['complete'] }}% ({{ this.rollingInfo['need_per'] }}%)</div>
                                </div>
                            </div>

                            <!-- <div class="mt-3" *ngIf="is_show_charge">입금 계좌</div> -->
                            <!-- <input *ngIf="is_show_charge" class="mt-1 form-control bg-modaldark nofocus pl-2 text-tit" value="{{ charge_bank_name }} {{ charge_bank_account }} {{ charge_bank_owner }}" /> -->
                            <div class="text-center mt-10" *ngIf="rollingInfo">
                                <button type="submit" class="btn-green w-1/2 h-12 font-bold" [disabled]="in_progress || CompleteRolling > rollingInfo.total_rolling">출금 신청</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="tab-pane leading-relaxed p-6 sm:px-10" [ngClass]="{'active': tab == 'crypto'}">
                <div class="flex items-center mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold" *ngIf="in_progress">
                    <div class="flex-1">출금신청이 진행중 입니다.</div>
                    <button type="button" class="btn btn-purple2 p-1" (click)="onCancelProc()">취소하기</button>
                </div>
                <div class="mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold">
                    참고: <span class="text-tit">출금계좌 변경은 고객센터를 통해서만 가능합니다.</span>
                </div>
                <div>출금화폐</div>
                <div class="flex flex-wrap gap-1 items-center mt-1 font-medium">
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="setCtypto('USDT')"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/USDT.webp" /> USDT</button>
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="setCtypto('USDT')"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/TRX.webp" /> TRX</button>
                    <!-- <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="setCtypto('XRP')"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/XRP.webp" /> XRP</button> -->
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="setCtypto('USDT')"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/ETH.webp" /> ETH</button>
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="setCtypto('USDT')"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/BTC.webp" /> BTC</button>
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="setCtypto('USDT')"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/BNB.webp" /> BNB</button>
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="btnCrypto.click()">더보기 <img class="w-3.5 ml-1" src="/assets/custom_img/coin/TRX.webp" /><img class="w-3.5 -ml-1" src="/assets/custom_img/coin/DOGE.webp" /><img class="w-3.5 -ml-1" src="/assets/custom_img/coin/LTC.webp" /> <svg class="w-3 ml-1 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                </div>
                <div class="md:flex items-center justify-between gap-1">
                    <div class="flex-1">
                        <div class="mt-3">Withdraw Currency</div>
                        <div class="ng-select2 flex-1 mt-3" ng-select>
                            <button class="btn w-32 h-12 flex px-4 items-center justify-between border-none bg-modaldark" #btnCrypto>
                                <div class="flex items-center gap-3 text-tit name text-base ">
                                    <img class="w-7" [src]="'/assets/custom_img/coin/' + currentCrypto +'.webp'" />
                                    {{currentCrypto}}
                                </div>
                                <div class="flex items-center gap-1">
                                    <!-- 잔고현황 -->
                                    <!-- <div class="cash text-tit">{{currentUser?.Wallets | wallet:currentCrypto || '0.0000'}}</div> -->
                                    <i class="ml-2"><svg class="w-3.5 h-3.5 fill-basic arr"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                                </div>
                            </button>
                            <div class="bg-white dark:bg-back2 shadow-lg">
                                <div class="p-4 pb-2">
                                    <div class="relative w-full">
                                        <label>
                                            <svg class="absolute left-3 top-2.5 w-5 h-5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Search"></use></svg>
                                            <input type="text" class="form-control bg-modaldark pl-10" placeholder="검색" [(ngModel)]="keyword" />
                                        </label>
                                    </div>
                                </div>
                                <ul class="overflow-y-auto scrollbar h-64 p-2 rounded text-sub">
                                    <li class="p-2 px-4 flex items-center justify-between" *ngFor="let item of cryptoItems | filter:'code':keyword" (click)="OnSelect2($event, item)" [ngClass]="{'on':item.code == currentCrypto}">
                                        <div class="flex items-center gap-2 text-tit font-extrabold name">
                                            <img class="w-7" [src]="'/assets/custom_img/coin/' + item.code +'.webp'" />
                                            {{ item.code }}
                                        </div>
                                        <div class="text-right text-xs">
                                            <!-- <b class="block text-tit text-sm">{{currentUser?.Wallets | wallet:item.code || '0.0000'}}</b> -->
                                            <!-- {{currentUser?.wallets[item.code]}} -->
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1">
                        <div class="mt-3">Choose Network</div>
                        <div class="ng-select flex-1 mt-3" ng-select>
                            <button class="btn w-32 h-12 flex px-4 items-center justify-between border-none bg-modaldark">
                                <span>{{ currentNetwork }}</span>
                                <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                            </button>
                            <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                                <li class="py-2" *ngFor="let item of cryptoNetworks" (click)="OnSelect($event, item)">{{ item }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <form role="form" [formGroup]="form1" (ngSubmit)="onCryptoSubmit()">
                    <div class="flex items-center justify-between bg-modaldark mt-3" *ngIf="!withdraw_address">
                        <div class="flex flex-1 items-center text-base">
                            <input type="text" formControlName="withdraw_address" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" placeholder="출금주소를 등록하세요." autocomplete="false" />
                        </div>
                        <button type="button" class="btn-yellow font-bold p-1 px-2" (click)="SetWithdrawAddress()">출금주소등록</button>
                    </div>
                    <div class="mt-4 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold" *ngIf="withdraw_address">
                        출금주소: <span class="text-yellow mr-1">{{withdraw_address}}</span>
                    </div>
                    <div class="flex items-center justify-between mt-1 bg-modaldark p-1 rounded">
                        <div class="flex items-center gap-4 text-base">
                            <p class="pl-4 text-tit">₩</p>
                            <input type="text" formControlName="amount" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" placeholder="" required autocomplete="off" numbersOnly />
                        </div>
                    </div>
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                        <div *ngIf="f['amount'].errors">{{ f['amount'].errors['check_error']}}</div>
                    </div>
                    <div class="cash_btn_box mt-2">
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(10000)">₩ 10,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(50000)">₩ 50,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(100000)">₩ 100,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(300000)">₩ 300,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(500000)">₩ 500,000</button>
                        <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(1000000)">₩ 1,000,000</button>
                        <button type="button" class="btn-green h-12 font-bold" (click)="f['amount'].patchValue(0);">초기화</button>
                    </div>
                    <div class="mt-4 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold">
                        예상코인: <span class="text-yellow mr-1">{{currentCrypto}}</span> <span class="text-tit">{{ currentRateCoin }}</span>
                    </div>
                    <div class="text-center mt-10" *ngIf="rollingInfo">
                        <button type="submit" class="btn-green w-1/2 h-12 font-bold" [disabled]="in_progress || CompleteRolling > rollingInfo.total_rolling">출금 신청</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
