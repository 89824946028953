<!-- 2fa 활성화 -->
<div class="modal-content overflow-hidden relative rounded">
    <div class="modal-body bg-back relative rounded">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base"></p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="relative p-6 overflow-y-auto scrollbar h-[660px]">
            <div class="text-tit text-base font-bold">2FA 활성화</div>
            <div class="mt-1">무단 액세스로부터 계정을 보호하려면 이중 인증을 활성화하세요.</div>

            <div class="bg-back2 p-3 mt-2">
                <div class="w-36 h-36 p-3 rounded bg-white mx-auto">
                    <img class="w-full" src="/assets/custom_img/bc.png" alt="">
                </div>
                <div class="mt-2 text-xs text-tit">Google Authenticator 앱</div>
                <div class="flex items-center gap-3 mt-2">
                    <button class="bg-list flex items-center justify-center gap-3 h-9 w-full rounded" (click)="window.open('https://apps.apple.com/us/app/google-authenticator/id388497605', '_blank')">
                        <img class="w-6" src="/assets/custom_img/profile/apple.png" alt="">
                        <p class="text-left text-xs">Free Download <b class="block text-tit">Apple Store</b></p>
                    </button>
                    <button class="bg-list flex items-center justify-center gap-3 h-9 w-full rounded" (click)="window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share', '_blank')">
                        <img class="w-6" src="/assets/custom_img/profile/google-play.png" alt="">
                        <p class="text-left text-xs">Free Download <b class="block text-tit">Google Play</b></p>
                    </button>
                </div>
                <div class="text-xs text-tit mt-3">으로 QR 코드를 스캔하거나 비밀 키를 수동으로 입력하세요.</div>
                <div class="relative mt-2">
                    <input type="text" class="form-control form-control-rounded bg-back2 type02" value="vaser15wersgwer6asdgerhy6uty">
                    <button class="absolute right-3 top-[14px] basic-hover" onclick="viewAlert('copy_alert')"><svg class="w-4 h-4"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Copy"></use></svg></button>
                </div>
                <div class="mt-2 text-xs">이 코드를 적어두고 다른 사람에게 절대 공개하지 마십시오. 인증자에 대한 액세스 권한이 없는 경우 이를 사용하여 계정에 대한 액세스 권한을 다시 얻을 수 있습니다.</div>
            </div>

            <div class="text-tit font-bold mt-3">2FA 인증 코드</div>
            <div class="flex gap-2 justify-between mt-2">
                <div class="w-12">
                    <input type="text" class="form-control type02 !h-14 !border-slate-300" >
                </div>
                <div class="w-12">
                    <input type="text" class="form-control type02 !h-14 !border-slate-300" >
                </div>
                <div class="w-12">
                    <input type="text" class="form-control type02 !h-14 !border-slate-300" >
                </div>
                <div class="w-12">
                    <input type="text" class="form-control type02 !h-14 !border-slate-300" >
                </div>
                <div class="w-12">
                    <input type="text" class="form-control type02 !h-14 !border-slate-300" >
                </div>
                <div class="w-12">
                    <input type="text" class="form-control type02 !h-14 !border-slate-300" >
                </div>
            </div>
            <button class="btn-green w-full h-12 font-extrabold mt-5">활성화</button>
        </div>
    </div>

</div>
