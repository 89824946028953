import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'shortNumbers',
  standalone: true,
})
export class ShortNumbersPipe implements PipeTransform {
    constructor(
        private decimalPipe: DecimalPipe
    ) {}

    transform(value: number | null | undefined, min: number = 10000): unknown {
        if(!value) return value;
        if(min && value < min) return this.decimalPipe.transform(value, '1.0-2');

        return this.displayedAt(value);
    }

    /**
     * TODO
     * 만, 만, 10K
     * 천천, 백만, 1M
     * 만만, 억, 100M
     * 만만만, 조, 
     * 경은 number의 한계로 연산 방법을 바꿔야 함
     */
    displayedAt(num:number) {
        if(num < 1000) return Number(num);
        let unit:string = '천'
        let strNum:string;
        // 만보다 작으면 1000으로 나누고 크면 만으로 나눈다.
        if(num < 10000) {
            strNum = String(num / 1000)
        } else if(num < 100000000) {
            unit = '만'
            strNum = String(num / 10000)
        } else if(num < 1000000000000) {
            unit = '억'
            strNum =  String(num / 100000000)
        } else {
            unit = '조'
            strNum = String(num / 1000000000000)
        }

        // 소수점
        const commaIdx = strNum.indexOf('.');
        // 소수점 존재시 첫쨰자리 까지 슬라이스
        const isComma = commaIdx !== -1;
        if (isComma) {
            // 첫째자리가 0인경우 -> 소숫점 이하 다 버려야함
            const isCommaZero = strNum.charAt(commaIdx + 1) === '0'; // ex) 1.03
            const sliceIdx = isCommaZero ? commaIdx : commaIdx + 2;
            strNum = strNum.substring(0, sliceIdx);
        }

        
        return this.decimalPipe.transform(strNum) + unit;    
    };
}
