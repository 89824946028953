<!-- BCD 레이크백 모달 -->
<div class="modal-content bcd-modal overflow-hidden relative rounded" [ngClass]="{ 'on': modalTab }">
    <!-- 기본 -->
    <div class="modal-body">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">BCD 레이크백</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>

        <div class="w-full p-3 overflow-y-auto scrollbar h-[660px]">

            <div class="text-center pt-1 pb-5 bcd_bg relative">
                <img class="w-14 mx-auto" src="/assets/custom_img/bonus/bcd.webp" alt="">
                <p class="mt-1">잠금해제 BCD</p>
                <div class="flex items-center justify-center gap-2 mt-2 w-1/2 h-11 mx-auto bg-white dark:bg-black bg-opacity-30 dark:bg-opacity-40 rounded">
                    <img class="w-6" src="/assets/custom_img/coin/BCD.webp">
                    <span class="text-primary text-xl font-bold">0.00</span>
                </div>
                <button type="button" class="btn-yellow h-11 w-48 mt-4 font-bold" disabled>수령</button>
                <div class="mt-2 text-xs">최소 수령: <span class="text-tit">5 BCD</span></div>
            </div> 

            <div class="flex items-center justify-between mb-3 p-4 px-8 border border-slate-300 border-opacity-50 bg-backlight rounded">
                <div>
                    <svg class="inline-flex w-3 h-3 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Locked"></use></svg>
                    잠금 BCD:
                </div>
                <p class="text-tit"><b class="text-lg">0</b> BCD</p>
            </div>

            <div class="py-2 text-center text-tit font-bold text-base">수령 방법</div>
            <div class="bg-back p-6">
                <p class="text-tit">잠긴금액 = 롤링금액 * 1% * <b>20%</b></p>
                <div class="mt-2">잠금 해제 가능한 최소 금액인 ₩3,286,490에 도달하려면 여전히 5 BCD. 정도 더 베팅해야 합니다. <button class="text-primary hover:underline" (click)="modalInHandle('bcd_lakeback')">상세 보기</button></div>
                <div class="w-full h-[1px] my-4 bg-place opacity-50"></div>
                <div class="flex gap-3">
                    <button class="btn-purple w-full h-11 font-extrabold">카지노로 가기</button>
                    <button class="btn-green w-full h-11 font-extrabold">스포츠 가기</button>
                </div>
            </div>
            <div class="mt-4 text-center">
                <button class="btn-normal p-2 px-4 rounded" (click)="modalInHandle('bcd_bonus_recode')">BCD 보너스 기록 <svg class="inline-flex w-3 h-3 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
            </div>
            

        </div>
    </div>

    <!-- BCD 보너스 기록 -->
    <div class="modal-body bcd_bonus_recode modal-in bg-stand rounded" [ngClass]="{ 'open': modalTab == 'bcd_bonus_recode'}">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('bcd_bonus_recode')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">BCD 보너스 기록</p>
            </div>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="w-full p-3 overflow-y-auto scrollbar h-[660px]">
            <div class="flex flex-col justify-center text-center py-10">
                <img class="w-48 mx-auto" src="/assets/custom_img/empty.webp">
                <div class="-mt-5 text-basic opacity-70">웁스! 아직 데이터가 없습니다!</div>
            </div>
        </div>
    </div>


    <!-- BCD 레이크백 -->
    <div class="modal-body bcd_lakeback modal-in bg-stand rounded" [ngClass]="{ 'open': modalTab == 'bcd_lakeback'}">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('bcd_lakeback')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">BCD 레이크백</p>
            </div>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="w-full p-3 overflow-y-auto scrollbar h-[660px]">
            <div class="relative">
                <img src="/assets/custom_img/bonus/bcd-1.webp" alt="">
                <a href="javascript:;" class="absolute left-11 bottom-1/3 text-white">컨트랙트 보기 <svg class="inline-flex w-4 h-4 fill-white"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a>
            </div>

            <div class="accordian_list mt-6">
                <div class="accordian_item">
                    <div class="title flex items-center justify-between bg-back shadow py-3 px-4 text-base text-tit font-bold cursor-pointer">
                        BCD을(를) 잠금 해제하고 청구하는 방법은 무엇입니까?
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden bg-backlight px-4 py-6 font-medium">
                        <p>
                            <span class="text-tit">BCD 를 어떻게 잠금해제 하나요?</span><br/><br/>
                            잠긴 BCD는 예금 보너스 및 럭키 스핀과 같은 특정 보너스를 통해 얻습니다.<br/><br/>
                            BCD 잠금 해제는 쉽습니다! 본질적으로 레이크백과 동일하며 베팅을 통해 비례적으로 잠금 해제됩니다.
                        </p>
                        <div class="bg-primary bg-opacity-20 my-2 text-tit font-extrabold px-4 py-2 rounded">잠긴금액 = 롤링금액 * 1% * 20%</div>
                        <p>
                            0 BCD를 모두 잠금 해제하려면 0.00 베팅이 필요합니다.<br/><br/>
                            <span class="text-tit">잠금 해제된 BCD를 청구하는 방법은 무엇입니까?</span><br/><br/>

                            베팅을 하면 <span class="text-primary">BC 스왑</span> 보물 상자가 잠금 해제된 로 채워집니다.<br/><br/>

                            보물 상자를 최소 5BCD로 채운 후 '청구'를 클릭하면 잠금 해제된 BCD이(가) 잔액으로 바로 전송됩니다.<br/><br/>

                            쉽죠?! 이제 BCD를 즉시 사용할 수 있습니다!
                        </p>
                    </div>
                </div>

                <div class="accordian_item mt-2">
                    <div class="title flex items-center justify-between bg-back shadow py-3 px-4 text-base text-tit font-bold cursor-pointer">
                        BCD 에 대하여
                        <svg class="arrow w-6 h-6 fill-basic opacity-50"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </div>
                    <div class="summary hidden bg-backlight px-4 py-6 font-medium">
                        <p>
                            <span class="text-tit">BCD 를 다른 가상화폐로 환전할 수 있나요?</span><br/><br/>

                            전적으로! BCD를 <span class="text-primary">BC 스왑</span>로 언제든지 다른 통화로 교환할 수 있습니다.<br/><br/>

                            <span class="text-tit">BCD 의 특별한 점은 무엇인가요??</span><br/><br/>

                            볼트프로에 BCD를 저장하시면 최대 10%의 연간 수익률을 누릴 수 있습니다. BCD 수집을 즐기십시오!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
