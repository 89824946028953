import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { JwtInterceptor, JwtInterceptorProviders } from '../interceptor/jwt.interceptor';
import { ErrorInterceptor, ErrorInterceptorProviders } from '../interceptor/error.interceptor';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DecimalPipe } from '@angular/common';

export function tokenGetter() {
    return localStorage.getItem("access_token");
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, `/assets/i18n/`, `.json`);
}  

export const appConfig: ApplicationConfig = {
    providers: [
        DecimalPipe,
        provideAnimations(),
        provideRouter(routes,
            withRouterConfig({
                paramsInheritanceStrategy: 'always',
                onSameUrlNavigation: 'reload',
            }),
        ),
        provideHttpClient(
            withInterceptorsFromDi(),
            withInterceptors([JwtInterceptor, ErrorInterceptor])
        ),
        // JwtInterceptorProviders,
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        importProvidersFrom(
            JwtModule.forRoot({
                config: {
                    tokenGetter: tokenGetter
                }
            })
        ),
        importProvidersFrom(
            TranslateModule.forRoot({
                useDefaultLang: true,
                defaultLanguage: 'ko',
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            })
        ),
        provideAnimations()
    ]
};
