import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService, currentUserInfo } from '../../auth/auth.service';
import { ApiService } from '../../../service/api.service';
import { Subscription } from 'rxjs';
import { NgSelectDirective } from '@app/shared/lib/directive/ng-select.directive';
import { FilterPipe } from '@app/shared/lib/pipe/filter.pipe';
import { LoadingComponent } from '../../../common/loading/loading.component';
import { RemainDirective } from '@app/shared/lib/directive/remain.directive';
import { SettingsService } from '../../auth/settings.service';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';


@Component({
  selector: 'app-withdraw-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectDirective,
    FilterPipe,
    LoadingComponent,
    RemainDirective,
    MomentPipe
  ],
  templateUrl: './withdraw-modal.component.html',
  styles: ``,
})
export class WithdrawModalComponent {
    private subs: Subscription[] = [];

    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    modalTab = ''
    tab = 'currency'
    
    form1!: FormGroup;
    submitted = false;
    sigining = false;

    currentUser!:currentUserInfo | null

    in_progress:any
    min_withdraw!:number        // 최소 출금액



    keyword:string=''
    currentCrypto = 'USDT'
    currentNetwork = 'ERC-20'
    cryptoItems:any = []
    cryptoNetworks:any = ['ERC-20', 'TRC-20', 'BEP-20']
    currentRateCoin!:string;

    walletInfo:any = []
    withdraw_address!:string | null

    rollingInfo:any;

    currency!:string
    isLoading:boolean = true;

    @ViewChild('btnCrypto') btnCrypto!: ElementRef;

    get f() { return this.form1.controls }
    constructor(
        private formBuilder: FormBuilder,
        private decimalPipe: DecimalPipe,
        private authService: AuthService,
        private apiService: ApiService,
        private settingsService: SettingsService
    ) {
        this.form1 = this.formBuilder.group({
            wallet_code: [''],
            network: [''],
            is_crypto: ['0'],
            amount: ['0', Validators.required],
            is_bonus_rolling: [true, Validators.required ],
            is_first: ['0'],
            withdraw_address: [''],
        });
    }

    ngOnInit(): void {
        this.subs.push(
            this.settingsService.getValue().subscribe(v => {
                if(v) this.currency = v.currency
            })
        )

        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        this.subs.push(
            this.form1.valueChanges.subscribe(form => {
                if(form.amount) {
                    const value = form.amount.toString().replace(/[^0-9]/g,"");
                    this.f['amount'].patchValue(this.decimalPipe.transform(value), { emitEvent: false });
                }
            })
        )

        this.fnGetData()

        // this.qrAddress = '0xbef975A42C979EA9bbFd0CC6F5EAb8FC79664325';
        // QRCode.toDataURL(this.qrAddress).then(url => {
        //     this.qrImage = url
        // })

        // this.qrExpiredAt = moment().add('+10', 'seconds')
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.get("/withdraw/info-withdraw", false).subscribe({
            next: jsondata => {
                this.isLoading = false;
                if(jsondata.success) {
                    this.min_withdraw = Number(jsondata.data.min_withdraw);
                    this.in_progress = jsondata.data.history;
                    this.cryptoItems = jsondata.data.crypto;
                    this.walletInfo = jsondata.data.wallet_info;
                    this.rollingInfo = jsondata.data.rolling_info;
                    this.calc_rolling()
                }

                if(jsondata.message) (window as any).alert(jsondata.message);
            }, error: (err) => {
                this.isLoading = false;
            }
        });
    }


    fnSetAmount(amount:number) {
        const value = this.f['amount'].value.toString().replace(/[^0-9]/g,"");
        let total = (+value) + amount;

        this.f['amount'].patchValue(total)
    }


    onSubmit() {
        this.submitted = true;

        const value = +(this.f['amount'].value.toString().replace(/[^0-9]/g,""));
        if(value < this.min_withdraw) {
            this.f['amount'].setErrors({ 'check_error': `최소 출금액은 ${ this.decimalPipe.transform(this.min_withdraw) }원 입니다.` });
            return;
        }

        const balance = this.currentUser?.point
        if(value >  Number(balance)) {
            this.f['amount'].setErrors({ 'check_error': `보유금액은 ${ this.decimalPipe.transform(balance) }원 입니다.` });
            return;
        }

        this.f['wallet_code'].patchValue(this.currency)
        if(this.form1.invalid) return;

        // if(!confirm(`출금신청을 하시겠습니까?`)) return;
        this.sigining = true;

        this.isLoading = true;
        this.apiService.post('/withdraw/process', this.form1.value, false).subscribe({
            next: jsondata => {
                this.isLoading = false;
                this.sigining = false;
                this.submitted = false;
                if(jsondata.message) (window as any).alert(jsondata.message);
                if(jsondata.success) {
                    (window as any).alert(`입금신청을 완료했습니다.`);
                    // this.onClose.emit('close')
                }
            }, error: err => {
                this.isLoading = false;
            }
        })
    }

    onCryptoSubmit() {
        if(!this.currentCrypto) {
            (window as any).alert('출금화폐를 선택해 주세요.')
            return;
        }

        this.submitted = true;

        const value = +(this.f['amount'].value.replace(/[^0-9]/g,""));
        if(value < this.min_withdraw) {
            this.f['amount'].setErrors({ 'check_error': `최소 출금액은 ${ this.decimalPipe.transform(this.min_withdraw) } 원 입니다.` });
            return;
        }

        const balance = this.currentUser?.balance;
        if(value >  Number(balance)) {
            this.f['amount'].setErrors({ 'check_error': `보유금액은 ${ this.decimalPipe.transform(balance) }원 입니다.` });
            return;
        }

        if(this.form1.invalid) return;

        // if(!confirm(`출금신청을 하시겠습니까?`)) return;
        this.sigining = true;

        this.f['wallet_code'].patchValue(this.currentCrypto);
        this.f['network'].patchValue(this.currentNetwork)

        this.isLoading = true;
        this.apiService.post('/withdraw/process', this.form1.value, false).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                this.sigining = false;
                this.submitted = false;
                if(jsondata.message) (window as any).alert(jsondata.message);
                if(jsondata.success) {
                    this.in_progress = jsondata.data.history;
                }
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }


    onCancelProc() {
        // if(!confirm(`출금신청을 취소 하시겠습니까?`)) return;
        this.isLoading = true;
        this.apiService.post('/withdraw/cancel', { idx: this.in_progress['idx']}, false).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.success) {
                    this.in_progress = null
                }
                if(jsondata.message) (window as any).alert(jsondata.message)

            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    SetWithdrawAddress() {

    }


    setCtypto(crypto:string) {
        const item = this.cryptoItems.find((v:any) => v.code == crypto)
        if(item) {
            this.currentCrypto = item.code
            this.cryptoNetworks = item.network
            this.currentNetwork = item.network[0]
        }
    }
    

    OnSelect($event:any, item:any){
        const el = $event.target
        const parent = el.closest('.ng-select');
        if(parent) parent.classList.remove('on')
        
        this.currentNetwork = item

        this.check_withdraw_address()
    }

    OnSelect2($event:any, item:any){
        const el = $event.target
        const parent = el.closest('.ng-select2');
        if(parent) parent.classList.remove('on')

        this.currentCrypto = item.code
        this.cryptoNetworks = item.network
        this.currentNetwork = item.network[0]

        this.check_withdraw_address()
    }

    check_withdraw_address() {
        const wallet = this.walletInfo.find((v:any) => v['wallet_code'] == this.currentCrypto)
        if(!wallet) {
            this.withdraw_address = null
        } else {
            const addr = JSON.parse(wallet['crypto_address'])
            if(!addr) this.withdraw_address = null
            else {
                this.withdraw_address = addr[this.currentNetwork]
            }
        }
    }

    calc_rolling() {
        const complete = this.CompleteRolling()
        this.rollingInfo.need = this.NeedRolling(complete)
        this.rollingInfo.complete = (complete > 0) ? (this.rollingInfo.total_rolling / complete) * 100 : 0
        this.rollingInfo.need_per = (complete > 0) ? ((complete - this.rollingInfo.total_rolling) / complete) * 100 : 0;
    }

    CompleteRolling() {
        let complete:number = 0;
        if(!this.rollingInfo) complete = 0; // 롤링 정보가 없으면
        if(!this.rollingInfo.rolling_times) complete = 0; // 롤링 타겟이 없으면,
        if(!this.rollingInfo.rolling_deposit) complete = 0; // 입금액이 없으면.
        complete = this.rollingInfo.rolling_deposit * this.rollingInfo.rolling_times;
        
        return complete;
    }
    
    NeedRolling(complete:number) {
        let need:number = 0;
        if(complete == 0) need = 0;
        if(this.rollingInfo.total_rolling > complete) need = 0;
        need = complete - this.rollingInfo.total_rolling;

        return need;
    }
}
