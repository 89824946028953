import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-detail-share-modal',
  standalone: true,
  imports: [],
  templateUrl: './detail-share-modal.component.html',
  styles: ``
})
export class DetailShareModalComponent {
    @Input() options:any;
    
    hostname:string;
    title:string='';
    constructor() {
        const protocol = window.location.protocol
        const hostname = window.location.hostname.replace('www.', '') 
        this.hostname = `${protocol}://${hostname}`
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if(changes['options']) {
            const prev = changes['options'].previousValue;
            const current = changes['options'].currentValue
            if(current) {
                this.title = encodeURIComponent(current.title)
            }
        }
    }
}
