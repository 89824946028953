import { Component } from '@angular/core';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [],
  templateUrl: './error.component.html',
  styles: ``
})
export class ErrorComponent {
    message:string;

    constructor() {
        this.message = JSON.stringify({
            code: 1901,
            message: `Oops, coco is gone!`,
            data: {}
        })
    }
    ngOnInit(): void {
        
    }
}
