import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-lottery-provably-modal',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './lottery-provably-modal.component.html',
  styles: ``
})
export class LotteryProvablyModalComponent {
   
    
    modalTab = ''
    constructor() {
    }
    
   

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
    }
}
