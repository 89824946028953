import { Component } from '@angular/core';

@Component({
  selector: 'app-racing',
  standalone: true,
  imports: [],
  templateUrl: './racing.component.html',
  styles: ``
})
export class RacingComponent {

}
