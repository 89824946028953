import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-bonus-deposit-modal',
  standalone: true,
  imports: [],
  templateUrl: './bonus-deposit-modal.component.html',
  styles: ``
})
export class BonusDepositModalComponent {
    constructor() {
    }
    
}
