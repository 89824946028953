<!-- 프로필 - vip 클럽 - vip 레벨 시스템 -->
<div class="modal-content">
    <div class="modal-body bg-modaldark relative rounded">
        <div class="flex items-center justify-between p-4">
            <div class="flex items-center gap-2">
                <button (click)="GoModal('vip-club')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">VIP 레벨 시스템</p>
            </div>
            <button class="basic-hover" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="w-full p-6 overflow-y-auto scrollbar h-auto sm:h-[640px]">
            <!-- 브론즈 -->
            <div class="py-2 vip-info bg-slate-200 dark:bg-back2 rounded">
                <div class="flex justify-between cursor-pointer on" onclick="vipLevelHandle(this)">
                    <div class="vip-cont flex items-center gap-2">
                        <div class="vip_flag small bronze w-7 "></div>
                        <p class="text-tit font-bold">브론즈 VIP 2-7</p>
                    </div>
                    <i class="p-2"><svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                </div>
                <div class="overflow-hidden open">
                    <table class="table table-sm table-striped noline rounded"> 
                        <thead> 
                            <tr> 
                                <th class="whitespace-nowrap text-left">레벨</th> 
                                <th class="whitespace-nowrap text-center">XP 요구됨</th> 
                                <th class="whitespace-nowrap text-right">레벨업 보너스 (BCD)</th> 
                            </tr> 
                        </thead> 
                        <tbody> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/bronze_medal.png" alt="">VIP 2</td>
                                <td class="text-center">100</td> 
                                <td class="text-right text-tit">0.04</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/bronze_medal.png" alt="">VIP 3</td>
                                <td class="text-center">200</td> 
                                <td class="text-right text-tit">0.05</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/bronze_medal.png" alt="">VIP 4</td>
                                <td class="text-center">1000</td> 
                                <td class="text-right text-tit">0.1</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/bronze_medal.png" alt="">VIP 5</td>
                                <td class="text-center">2000</td> 
                                <td class="text-right text-tit">0.2</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/bronze_medal.png" alt="">VIP 6</td>
                                <td class="text-center">3000</td> 
                                <td class="text-right text-tit">0.3</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/bronze_medal.png" alt="">VIP 7</td>
                                <td class="text-center">4000</td> 
                                <td class="text-right text-tit">0.35</td> 
                            </tr> 
                        </tbody> 
                    </table> 
                </div>
            </div>
            <!-- 실버 -->
            <div class="py-2 vip-info mt-3 bg-slate-200 dark:bg-back2 rounded">
                <div class="flex justify-between cursor-pointer" onclick="vipLevelHandle(this)">
                    <div class="vip-cont flex items-center gap-2">
                        <div class="vip_flag small silver w-7 "></div>
                        <p class="text-tit font-bold">실버 VIP 8-21</p>
                    </div>
                    <i class="p-2"><svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                </div>
                <div class="overflow-hidden">
                    <table class="table table-sm table-striped noline rounded"> 
                        <thead> 
                            <tr> 
                                <th class="whitespace-nowrap text-left">레벨</th> 
                                <th class="whitespace-nowrap text-center">XP 요구됨</th> 
                                <th class="whitespace-nowrap text-right">레벨업 보너스 (BCD)</th> 
                            </tr> 
                        </thead> 
                        <tbody> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 8</td>
                                <td class="text-center">5000</td> 
                                <td class="text-right text-tit">0.7</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 9</td>
                                <td class="text-center">7000</td> 
                                <td class="text-right text-tit">0.8</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 10</td>
                                <td class="text-center">9000</td> 
                                <td class="text-right text-tit">0.9</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 11</td>
                                <td class="text-center">11000</td> 
                                <td class="text-right text-tit">1</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 12</td>
                                <td class="text-center">13000</td> 
                                <td class="text-right text-tit">1.1</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 13</td>
                                <td class="text-center">15000</td> 
                                <td class="text-right text-tit">1.2</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 14</td>
                                <td class="text-center">17000</td> 
                                <td class="text-right text-tit">1.3</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 15</td>
                                <td class="text-center">21000</td> 
                                <td class="text-right text-tit">1.4</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 16</td>
                                <td class="text-center">25000</td> 
                                <td class="text-right text-tit">1.5</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 17</td>
                                <td class="text-center">29000</td> 
                                <td class="text-right text-tit">1.6</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 18</td>
                                <td class="text-center">33000</td> 
                                <td class="text-right text-tit">1.7</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 19</td>
                                <td class="text-center">37000</td> 
                                <td class="text-right text-tit">1.8</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 20</td>
                                <td class="text-center">41000</td> 
                                <td class="text-right text-tit">1.9</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/silver_medal.png" alt="">VIP 21</td>
                                <td class="text-center">45000</td> 
                                <td class="text-right text-tit">2</td> 
                            </tr> 
                        </tbody> 
                    </table> 
                </div>

            </div>
            <!-- 골드 -->
            <div class="py-2 vip-info mt-3 bg-slate-200 dark:bg-back2 rounded">
                <div class="flex justify-between cursor-pointer" onclick="vipLevelHandle(this)">
                    <div class="vip-cont flex items-center gap-2">
                        <div class="vip_flag small gold w-7 "></div>
                        <p class="text-tit font-bold">골드 VIP 22-37</p>
                    </div>
                    <i class="p-2"><svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                </div>
                <div class="overflow-hidden">
                    <table class="table table-sm table-striped noline rounded"> 
                        <thead> 
                            <tr> 
                                <th class="whitespace-nowrap text-left">레벨</th> 
                                <th class="whitespace-nowrap text-center">XP 요구됨</th> 
                                <th class="whitespace-nowrap text-right">레벨업 보너스 (BCD)</th> 
                            </tr> 
                        </thead> 
                        <tbody> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 22</td>
                                <td class="text-center">49000</td> 
                                <td class="text-right text-tit">3</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 23</td>
                                <td class="text-center">59000</td> 
                                <td class="text-right text-tit">4</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 24</td>
                                <td class="text-center">69000</td> 
                                <td class="text-right text-tit">5</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 25</td>
                                <td class="text-center">79000</td> 
                                <td class="text-right text-tit">6</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 26</td>
                                <td class="text-center">89000</td> 
                                <td class="text-right text-tit">7</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 27</td>
                                <td class="text-center">99000</td> 
                                <td class="text-right text-tit">8</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 28</td>
                                <td class="text-center">109000</td> 
                                <td class="text-right text-tit">9</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 29</td>
                                <td class="text-center">119000</td> 
                                <td class="text-right text-tit">10</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 30</td>
                                <td class="text-center">129000</td> 
                                <td class="text-right text-tit">12</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 31</td>
                                <td class="text-center">153000</td> 
                                <td class="text-right text-tit">14</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 32</td>
                                <td class="text-center">177000</td> 
                                <td class="text-right text-tit">16</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 33</td>
                                <td class="text-center">201000</td> 
                                <td class="text-right text-tit">18</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 34</td>
                                <td class="text-center">225000</td> 
                                <td class="text-right text-tit">20</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 35</td>
                                <td class="text-center">249000</td> 
                                <td class="text-right text-tit">22</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 36</td>
                                <td class="text-center">273000</td> 
                                <td class="text-right text-tit">24</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/gold_medal.webp" alt="">VIP 37</td>
                                <td class="text-center">297000</td> 
                                <td class="text-right text-tit">26</td> 
                            </tr> 
                        </tbody> 
                    </table> 
                </div>

            </div>
            <!-- 플래티넘 1 -->
            <div class="py-2 vip-info mt-3 bg-slate-200 dark:bg-back2 rounded">
                <div class="flex justify-between cursor-pointer" onclick="vipLevelHandle(this)">
                    <div class="vip-cont flex items-center gap-2">
                        <div class="vip_flag small plat w-7 "></div>
                        <p class="text-tit font-bold">플래티넘 I VIP 38-55</p>
                    </div>
                    <i class="p-2"><svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                </div>
                <div class="overflow-hidden">
                    <table class="table table-sm table-striped noline rounded"> 
                        <thead> 
                            <tr> 
                                <th class="whitespace-nowrap text-left">레벨</th> 
                                <th class="whitespace-nowrap text-center">XP 요구됨</th> 
                                <th class="whitespace-nowrap text-right">레벨업 보너스 (BCD)</th> 
                            </tr> 
                        </thead> 
                        <tbody> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 38</td>
                                <td class="text-center">321000</td> 
                                <td class="text-right text-tit">30</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 39</td>
                                <td class="text-center">377000</td> 
                                <td class="text-right text-tit">35</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 40</td>
                                <td class="text-center">433000</td> 
                                <td class="text-right text-tit">40</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 41</td>
                                <td class="text-center">489000</td> 
                                <td class="text-right text-tit">50</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 42</td>
                                <td class="text-center">545000</td> 
                                <td class="text-right text-tit">60</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 43</td>
                                <td class="text-center">601000</td> 
                                <td class="text-right text-tit">70</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 44</td>
                                <td class="text-center">657000</td> 
                                <td class="text-right text-tit">80</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 45</td>
                                <td class="text-center">713000</td> 
                                <td class="text-right text-tit">90</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 46</td>
                                <td class="text-center">769000</td> 
                                <td class="text-right text-tit">100</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 47</td>
                                <td class="text-center">897000</td> 
                                <td class="text-right text-tit">110</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 48</td>
                                <td class="text-center">1025000</td> 
                                <td class="text-right text-tit">120</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 49</td>
                                <td class="text-center">1153000</td> 
                                <td class="text-right text-tit">130</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 50</td>
                                <td class="text-center">1281000</td> 
                                <td class="text-right text-tit">140</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 51</td>
                                <td class="text-center">1409000</td> 
                                <td class="text-right text-tit">150</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 52</td>
                                <td class="text-center">1537000</td> 
                                <td class="text-right text-tit">160</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 53</td>
                                <td class="text-center">1665000</td> 
                                <td class="text-right text-tit">170</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 54</td>
                                <td class="text-center">1793000</td> 
                                <td class="text-right text-tit">180</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 55</td>
                                <td class="text-center">2081000</td> 
                                <td class="text-right text-tit">190</td> 
                            </tr>
                        </tbody> 
                    </table> 
                </div>

            </div>
            <!-- 플래티넘 2 -->
            <div class="py-2 vip-info mt-3 bg-slate-200 dark:bg-back2 rounded">
                <div class="flex justify-between cursor-pointer" onclick="vipLevelHandle(this)">
                    <div class="vip-cont flex items-center gap-2">
                        <div class="vip_flag small plat w-7 "></div>
                        <p class="text-tit font-bold">플래티넘 II VIP 56-69</p>
                    </div>
                    <i class="p-2"><svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                </div>
                <div class="overflow-hidden">
                    <table class="table table-sm table-striped noline rounded"> 
                        <thead> 
                            <tr> 
                                <th class="whitespace-nowrap text-left">레벨</th> 
                                <th class="whitespace-nowrap text-center">XP 요구됨</th> 
                                <th class="whitespace-nowrap text-right">레벨업 보너스 (BCD)</th> 
                            </tr> 
                        </thead> 
                        <tbody> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 56</td>
                                <td class="text-center">2369000</td> 
                                <td class="text-right text-tit">200</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 57</td>
                                <td class="text-center">2657000</td> 
                                <td class="text-right text-tit">220</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 58</td>
                                <td class="text-center">2945000</td> 
                                <td class="text-right text-tit">240</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 59</td>
                                <td class="text-center">3233000</td> 
                                <td class="text-right text-tit">260</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 60</td>
                                <td class="text-center">3521000</td> 
                                <td class="text-right text-tit">280</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 61</td>
                                <td class="text-center">3809000</td> 
                                <td class="text-right text-tit">300</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 62</td>
                                <td class="text-center">4097000</td> 
                                <td class="text-right text-tit">350</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 63</td>
                                <td class="text-center">4737000</td> 
                                <td class="text-right text-tit">400</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 64</td>
                                <td class="text-center">5377000</td> 
                                <td class="text-right text-tit">450</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 65</td>
                                <td class="text-center">6017000</td> 
                                <td class="text-right text-tit">500</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 66</td>
                                <td class="text-center">6657000</td> 
                                <td class="text-right text-tit">550</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 67</td>
                                <td class="text-center">7297000</td> 
                                <td class="text-right text-tit">600</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 68</td>
                                <td class="text-center">7937000</td> 
                                <td class="text-right text-tit">700</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/platinum_medal.png" alt="">VIP 69</td>
                                <td class="text-center">8577000</td> 
                                <td class="text-right text-tit">800</td> 
                            </tr>
                        </tbody> 
                    </table> 
                </div>

            </div>
            <!-- 다이아몬드 1 -->
            <div class="py-2 vip-info mt-3 bg-slate-200 dark:bg-back2 rounded">
                <div class="flex justify-between cursor-pointer" onclick="vipLevelHandle(this)">
                    <div class="vip-cont flex items-center gap-2">
                        <div class="vip_flag small dia w-7 "></div>
                        <p class="text-tit font-bold">다이아몬드 I SVIP 1-15</p>
                    </div>
                    <i class="p-2"><svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                </div>
                <div class="overflow-hidden">
                    <table class="table table-sm table-striped noline rounded"> 
                        <thead> 
                            <tr> 
                                <th class="whitespace-nowrap text-left">레벨</th> 
                                <th class="whitespace-nowrap text-center">XP 요구됨</th> 
                                <th class="whitespace-nowrap text-right">레벨업 보너스 (BCD)</th> 
                            </tr> 
                        </thead> 
                        <tbody> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 1</td>
                                <td class="text-center">9217000</td> 
                                <td class="text-right text-tit">1200</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 2</td>
                                <td class="text-center">10625000</td> 
                                <td class="text-right text-tit">1300</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 3</td>
                                <td class="text-center">12033000</td> 
                                <td class="text-right text-tit">1400</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 4</td>
                                <td class="text-center">13441000</td> 
                                <td class="text-right text-tit">1500</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 5</td>
                                <td class="text-center">14849000</td> 
                                <td class="text-right text-tit">1600</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 6</td>
                                <td class="text-center">16257000</td> 
                                <td class="text-right text-tit">1700</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 7</td>
                                <td class="text-center">17665000</td> 
                                <td class="text-right text-tit">1800</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 8</td>
                                <td class="text-center">19073000</td> 
                                <td class="text-right text-tit">2000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 9</td>
                                <td class="text-center">20481000</td> 
                                <td class="text-right text-tit">2200</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 10</td>
                                <td class="text-center">23553000</td> 
                                <td class="text-right text-tit">2300</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 11</td>
                                <td class="text-center">26625000</td> 
                                <td class="text-right text-tit">2500</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 12</td>
                                <td class="text-center">29697000</td> 
                                <td class="text-right text-tit">2600</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 13</td>
                                <td class="text-center">32769000</td> 
                                <td class="text-right text-tit">2700</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 14</td>
                                <td class="text-center">35841000</td> 
                                <td class="text-right text-tit">2800</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 15</td>
                                <td class="text-center">38913000</td> 
                                <td class="text-right text-tit">3000</td> 
                            </tr>
                        </tbody> 
                    </table> 
                </div>

            </div>
            <!-- 다이아몬드 2 -->
            <div class="py-2 vip-info mt-3 bg-slate-200 dark:bg-back2 rounded">
                <div class="flex justify-between cursor-pointer" onclick="vipLevelHandle(this)">
                    <div class="vip-cont flex items-center gap-2">
                        <div class="vip_flag small dia w-7 "></div>
                        <p class="text-tit font-bold">다이아몬드 II SVIP 16-37</p>
                    </div>
                    <i class="p-2"><svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                </div>
                <div class="overflow-hidden">
                    <table class="table table-sm table-striped noline rounded"> 
                        <thead> 
                            <tr> 
                                <th class="whitespace-nowrap text-left">레벨</th> 
                                <th class="whitespace-nowrap text-center">XP 요구됨</th> 
                                <th class="whitespace-nowrap text-right">레벨업 보너스 (BCD)</th> 
                            </tr> 
                        </thead> 
                        <tbody> 
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 16</td>
                                <td class="text-center">41985000</td> 
                                <td class="text-right text-tit">3200</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 17</td>
                                <td class="text-center">45057000</td> 
                                <td class="text-right text-tit">3600</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 18</td>
                                <td class="text-center">51713000</td> 
                                <td class="text-right text-tit">4000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 19</td>
                                <td class="text-center">58369000</td> 
                                <td class="text-right text-tit">4500</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 20</td>
                                <td class="text-center">65025000</td> 
                                <td class="text-right text-tit">5000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 21</td>
                                <td class="text-center">71681000</td> 
                                <td class="text-right text-tit">5500</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 22</td>
                                <td class="text-center">78337000</td> 
                                <td class="text-right text-tit">6000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 23</td>
                                <td class="text-center">84993000</td> 
                                <td class="text-right text-tit">7000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 24</td>
                                <td class="text-center">91649000</td> 
                                <td class="text-right text-tit">8000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 25</td>
                                <td class="text-center">98305000</td> 
                                <td class="text-right text-tit">9000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 26</td>
                                <td class="text-center">112641000</td> 
                                <td class="text-right text-tit">10000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 27</td>
                                <td class="text-center">126977000</td> 
                                <td class="text-right text-tit">11000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 28</td>
                                <td class="text-center">141313000</td> 
                                <td class="text-right text-tit">12000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 29</td>
                                <td class="text-center">155649000</td> 
                                <td class="text-right text-tit">13000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 30</td>
                                <td class="text-center">169985000</td> 
                                <td class="text-right text-tit">15000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 31</td>
                                <td class="text-center">184321000</td> 
                                <td class="text-right text-tit">18000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 32</td>
                                <td class="text-center">198657000</td> 
                                <td class="text-right text-tit">20000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 33</td>
                                <td class="text-center">212993000</td> 
                                <td class="text-right text-tit">23000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 34</td>
                                <td class="text-center">243713000</td> 
                                <td class="text-right text-tit">26000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 35</td>
                                <td class="text-center">274433000</td> 
                                <td class="text-right text-tit">28000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 36</td>
                                <td class="text-center">305153000</td> 
                                <td class="text-right text-tit">31000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 37</td>
                                <td class="text-center">335873000</td> 
                                <td class="text-right text-tit">35000</td> 
                            </tr>
                        </tbody> 
                    </table> 
                </div>

            </div>
            <!-- 다이아몬드 3 -->
            <div class="py-2 vip-info mt-3 bg-slate-200 dark:bg-back2 rounded">
                <div class="flex justify-between cursor-pointer" onclick="vipLevelHandle(this)">
                    <div class="vip-cont flex items-center gap-2">
                        <div class="vip_flag small dia w-7 "></div>
                        <p class="text-tit font-bold">다이아몬드 III SVIP 38-55</p>
                    </div>
                    <i class="p-2"><svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                </div>
                <div class="overflow-hidden">
                    <table class="table table-sm table-striped noline rounded">
                        <thead> 
                            <tr> 
                                <th class="whitespace-nowrap text-left">레벨</th> 
                                <th class="whitespace-nowrap text-center">XP 요구됨</th> 
                                <th class="whitespace-nowrap text-right">레벨업 보너스 (BCD)</th> 
                            </tr> 
                        </thead> 
                        <tbody>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 38</td>
                                <td class="text-center">366593000</td> 
                                <td class="text-right text-tit">38000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 39</td>
                                <td class="text-center">397313000</td> 
                                <td class="text-right text-tit">40000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 40</td>
                                <td class="text-center">428033000</td> 
                                <td class="text-right text-tit">42000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 41</td>
                                <td class="text-center">458753000</td> 
                                <td class="text-right text-tit">45000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 42</td>
                                <td class="text-center">524289000</td> 
                                <td class="text-right text-tit">48000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 43</td>
                                <td class="text-center">589825000</td> 
                                <td class="text-right text-tit">50000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 44</td>
                                <td class="text-center">655361000</td> 
                                <td class="text-right text-tit">53000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 45</td>
                                <td class="text-center">720897000</td> 
                                <td class="text-right text-tit">56000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 46</td>
                                <td class="text-center">786433000</td> 
                                <td class="text-right text-tit">60000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 47</td>
                                <td class="text-center">851969000</td> 
                                <td class="text-right text-tit">65000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 48</td>
                                <td class="text-center">917505000</td> 
                                <td class="text-right text-tit">70000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 49</td>
                                <td class="text-center">983041000</td> 
                                <td class="text-right text-tit">75000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 50</td>
                                <td class="text-center">1122305000</td> 
                                <td class="text-right text-tit">80000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 51</td>
                                <td class="text-center">1261569000</td> 
                                <td class="text-right text-tit">90000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 52</td>
                                <td class="text-center">1400833000</td> 
                                <td class="text-right text-tit">100000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 53</td>
                                <td class="text-center">1540097000</td> 
                                <td class="text-right text-tit">120000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 54</td>
                                <td class="text-center">1679361000</td> 
                                <td class="text-right text-tit">180000</td> 
                            </tr>
                            <tr class="rounded">
                                <td class="text-left"><img class="w-4 h-4 inline-flex items-center mr-1" src="/assets/custom_img/profile/diamond_medal.webp" alt="">SVIP 55</td>
                                <td class="text-center">1818625000</td> 
                                <td class="text-right text-tit">250000</td> 
                            </tr>
                        </tbody> 
                    </table> 
                </div>

            </div>
        </div>
    </div>
</div>
