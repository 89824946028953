<div class="container_wrap">
    <ng-template [ngIf]="!currentUser">

        <div class="container md:!px-10 !px-0 pt-0 md:pt-6">

            <!-- 비주얼 -->
            <div class="affiliate_visual flex md:flex-row flex-col items-center">
                <div class="w-full md:w-1/2 py-10 text-center">
                    <h3 class="text-tit italic text-3xl xl:text-4xl font-extrabold">친구 초대 및 수익</h3>
                    <p class="mt-1 text-yellow-gradient text-2xl xl:text-3xl font-extrabold">₩1,318,462 + 25% 커미션</p>
                    <button class="w-48 h-12 mt-7 btn-green font-bold" (click)="modal.open($event, 'sign-up')">가입 및 수익</button>
                    <div class="mt-2">
                        <button (click)="modal.open($event, 'referral-info')">레퍼럴 이용약관</button>
                    </div>
                </div>
                <img class="block md:hidden" src="/assets/custom_img/affiliate/banner_bg_m.png" alt="">
            </div>
        </div>

        <div class="container">
            <div class="flex flex-col lg:flex-row gap-4 mt-6">
                <div class="w-full lg:w-1/2 h-auto sm:h-80 relative bg-backlight rounded p-5">
                    <div class="h-full w-full sm:w-4/5">
                        <h4 class="text-xl sm:text-3xl font-extrabold text-tit"><b class="text-yellow">₩1,294,414</b> 추천 보상 받기</h4>
                        <p class="w-full sm:w-3/5 mt-3 text-sm sm:text-base">당신이 초대하는 모든 친구는 당신에게 ₩1,294,414을(를) 얻을 것입니다. 더 많이 초대할수록 더 많은 것을 얻을 수 있습니다!</p>
                        <button class="sm:block hidden mt-8 bg-slate-300 bg-opacity-80 w-48 h-12 rounded text-tit font-bold" (click)="modal.open($event, 'referral-rule')">자세히보기 <svg class="inline-flex w-4 h-4 fill-title"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                    </div>
                    <div class="sm:absolute sm:right-4 sm:top-1/2 sm:-translate-y-1/2 sm:w-2/5 w-full sm:mt-0 mt-3">
                        <img class="w-1/2 sm:w-full mx-auto" src="/assets/custom_img/affiliate/referral.webp" alt="">
                        <button class="sm:hidden block mt-4 bg-slate-300 bg-opacity-80 w-full h-12 rounded text-tit font-bold" (click)="modal.open($event, 'referral-rule')">자세히보기 <svg class="inline-flex w-4 h-4 fill-title"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 h-auto sm:h-80 relative bg-backlight rounded p-5">
                    <div class="h-full w-full sm:w-4/5">
                        <h4 class="text-xl sm:text-3xl font-extrabold text-tit"><b class="text-yellow">25%</b> 커미션 보상 받기</h4>
                        <p class="w-full sm:w-3/5 mt-3 text-sm sm:text-base">친구가 게임을 기반으로 베팅할 때마다 커미션 보상을 받게 됩니다.</p>
                        <button class="sm:block hidden mt-8 bg-slate-300 bg-opacity-80 w-48 h-12 rounded text-tit font-bold" (click)="modal.open($event, 'commission-info')">자세히보기 <svg class="inline-flex w-4 h-4 fill-title"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                    </div>
                    <div class="sm:absolute sm:right-4 sm:top-1/2 sm:-translate-y-1/2 sm:w-2/5 w-full sm:mt-0 mt-3">
                        <img class="w-1/2 sm:w-full mx-auto" src="/assets/custom_img/affiliate/commission.webp" alt="">
                        <button class="sm:hidden block mt-4 bg-slate-300 bg-opacity-80 w-full h-12 rounded text-tit font-bold" (click)="modal.open($event, 'commission-info')">자세히보기 <svg class="inline-flex w-4 h-4 fill-title"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                    </div>
                </div>
            </div>

            <div class="mt-8 flex items-center gap-2">
                <span class="relative flex h-2.5 w-2.5">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-danger opacity-75"></span>
                    <span class="relative inline-flex rounded-full mt-0.5 ml-0.5 h-1.5 w-1.5 bg-danger"></span>
                </span>
                실시간 보상
            </div>
            <div class="mt-3 bg-stand flex flex-col md:flex-row rounded">
                <div class="w-full md:w-1/2 relative pl-5 md:pl-20 pr-5 py-5 text-center">
                    <img class="block md:hidden w-2/5 mx-auto" src="/assets/custom_img/affiliate/rewards_m.png" alt="">
                    <img class="absolute left-0 bottom-0 h-full md:block hidden" src="/assets/custom_img/affiliate/rewards.png" alt="">
                    <p>현재까지 전송된 총 보상</p>
                    <div class="w-full md:w-3/5 mt-1 mx-auto border border-slate-300 border-opacity-50 bg-back py-1 text-yellow text-xl font-bold rounded">₩31,161,417,405</div>
                </div>
                <div class="w-full md:w-1/2 p-5 md:border-l border-t border-slate-300 border-opacity-50">
                    <div class="live_slide_box overflow-hidden h-28 md:h-14">
                        <ul class="swiper-wrapper">
                            <li class="swiper-slide">
                                <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-2 gap-y-2 md:gap-y-3 py-0.5 overflow-hidden">
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/BCD.webp" alt=""><b class="text-tit truncate">Aegasev</b><span class="text-primary">₩181</span> 보상을 받았습니다</div>
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Adktqemujyb</b><span class="text-primary">₩25</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Nkcdqkstjyb</b><span class="text-primary">₩647</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/TRX.webp" alt=""><b class="text-tit truncate">Aslnmbopkwb</b><span class="text-primary">₩32,351</span> 보상을 받았습니다</div>
                                </div>
                            </li>
                            <li class="swiper-slide">
                                <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-2 gap-y-2 md:gap-y-3 py-0.5 overflow-hidden">
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/BCD.webp" alt=""><b class="text-tit truncate">Aegasev</b><span class="text-primary">₩181</span> 보상을 받았습니다</div>
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Adktqemujyb</b><span class="text-primary">₩25</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Nkcdqkstjyb</b><span class="text-primary">₩647</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/TRX.webp" alt=""><b class="text-tit truncate">Aslnmbopkwb</b><span class="text-primary">₩32,351</span> 보상을 받았습니다</div>
                                </div>
                            </li>
                            <li class="swiper-slide">
                                <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-2 gap-y-2 md:gap-y-3 py-0.5 overflow-hidden">
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/BCD.webp" alt=""><b class="text-tit truncate">Aegasev</b><span class="text-primary">₩181</span> 보상을 받았습니다</div>
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Adktqemujyb</b><span class="text-primary">₩25</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Nkcdqkstjyb</b><span class="text-primary">₩647</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/TRX.webp" alt=""><b class="text-tit truncate">Aslnmbopkwb</b><span class="text-primary">₩32,351</span> 보상을 받았습니다</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div class="relative flex items-center flex-col md:flex-row bg-stand mt-8 h-auto md:h-96">
                <div class="flex items-center justify-between w-full md:w-[30%] md:min-w-[320px] h-full md:pt-0 pt-14 affiliate_bg1">
                    <img class="w-4/5 mx-auto pl-10" src="/assets/custom_img/affiliate/program.png" alt="">
                </div>
                <div class="w-full pl-5 md:pl-0 pr-5 pt-0 pb-10 md:py-10">
                    <h4 class="absolute left-0 top-0 w-full md:relative text-base md:text-3xl font-bold text-tit md:text-left text-center md:pt-0 pt-5"><span class="text-primary">제휴 프로그램</span>에 대해 자세히 알아보세요.</h4>
                    <div class="relative pl-4 mt-4 md:text-sm text-xs">
                        <i class="absolute left-0 top-1.5 w-2 h-2 bg-sub rotate-45"></i>
                        유저와 팔로워가 많은 경우. 추천 프로그램을 맞춤화할 수 있는 특별한 조건이 있습니다!
                    </div>
                    <div class="relative pl-4 mt-3 md:text-sm text-xs">
                        <i class="absolute left-0 top-1.5 w-2 h-2 bg-sub rotate-45"></i>
                        플레이어를 초대할 수 있고 베팅 금액이 10억 달러 이상에 도달하면 나만의 맞춤형 카지노를 갖게 됩니다! 도메인과 디자인 스타일로 카지노 웹사이트를 설정할 수 있습니다.
                    </div>
                    <div class="mt-4">
                        <div class="md:text-sm text-xs">자세히 알려주세요! 고객센터:</div>
                        <button class="w-96 max-w-full px-3 py-4 mt-2 bg-back flex items-center justify-between hover:bg-gradient-green md:text-sm text-xs">
                            <span class="text-tit">business&#64;kbcgaming.com</span>
                            <span class="text-primary">지금 전송 <svg class="inline-flex w-4 h-4 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Mail"></use></svg></span>
                        </button>
                    </div>
                </div>
            </div>




        </div>



        <div class="bg-btndark py-10 ">
            <div class="container">
                
                <div class="text-tit text-center text-lg md:text-4xl font-extrabold">자주 묻는 질문</div>

                <div class="accordial_list mt-2">
                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            추천 시스템의 작동 방법
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            추천 링크를 친구, 가족과 공유하거나 링크를 광고하고 당사 사이트에서 플레이어 가입을 하면 해당 플레이어가 추천이 되며 KBC GAME에서 플레이함으로써 커미션과 추가 보상을 받게 됩니다.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            내 추천으로 얼마를 벌 수 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            베팅의 25% + 추천당 ₩1,318,034를 벌 수 있습니다.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            USD 보상이란 무엇이며 어떻게 작동하나요?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            ₩1,318,034에 가져오는 각 추천에 대해 KBC GAME USD 보상이 있습니다. 이 USD 보상은 추천 레벨이 VIP4에서 VIP 70으로 올라갈 때 10부분으로 제공됩니다.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            광고에 사용할 수 있는 배너가 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            팔로워가 많은데 어떻게 특별 거래를 얻을 수 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs"> 
                            트래픽이 좋은 웹사이트나 잠재고객이 많은 소셜 미디어 계정이 있는 경우 <a href="javascript:;" class="text-primary">support&#64;kbcgaming.com <svg class="inline-flex w-3 h-3 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a> 에서 특별 거래를 위해 연결할 수 있습니다.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            다른 웹사이트/계정이 있는 경우 얼마나 많은 제휴 링크를 만들 수 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            서로 다른 트래픽 소스에 대해 최대 20개의 고유 코드를 생성할 수 있습니다.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            내 추천 데이터를 볼 수 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            예, KBC GAME는 완전한 투명성을 믿으며 사용자 이름, 그들이 한 내기, 당신이 만든 커미션, 그들이 등록했을 때 그들이 사용한 당신의 링크와 같은 모든 데이터를 사용자에게 제공합니다. <a href="javascript:;" class="text-primary">제휴 대쉬보드 <svg class="inline-flex w-3 h-3 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a> 의 모든 것.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            내 추천인에게 팁이나 보상을 보낼 수 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            예, 지원되는 모든 코인으로 추천 팁을 보낼 수 있습니다.<a href="javascript:;" class="text-primary">여기를 클릭하세요 <svg class="inline-flex w-3 h-3 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a>
                        </div>
                    </div>

                    
                </div>
            </div>

        </div>
    </ng-template>

    <ng-template [ngIf]="currentUser">
        <div class="container pt-6 pb-20">

            <div class="flex flex-col xl:flex-row-reverse gap-5 rounded">
                <div class="w-full xl:w-1/3 bg-stand">
                    <div class="flex flex-col justify-evenly affiliate_visual2 text-tit p-5 text-sm md:text-base font-semibold">
                        <p class="text-lg md:text-2xl font-bold">친구를 초대하고 보상받기:</p>
                        <p><b class="text-yellow">₩1,295,485</b> 추천인 보상받기</p>
                        <p><b class="text-yellow">25%</b> 커미션 보상</p>
                    </div>
                    <div class="py-4 md:py-9 px-3 md:px-5">
                        <p class="text-tit font-bold text-xs md:text-sm">추천코드</p>
                        <div class="flex items-center justify-between w-full mt-2 bg-back rounded">
                            <p class="px-3 text-tit">uopower04</p>
                            <button class="w-12 h-12 bg-backlight rounded"><svg class="w-4 h-4 mx-auto fill-white"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Copy"></use></svg></button>
                        </div>

                        <p class="mt-4 md:mt-7 text-tit font-bold text-xs md:text-sm">추천인 링크</p>
                        <div class="flex items-center justify-between w-full mt-2 bg-back rounded">
                            <p class="px-3 text-tit">https://kbcgaming.com/i-uosdft04-n/</p>
                            <button class="w-12 h-12 bg-backlight rounded"><svg class="w-4 h-4 mx-auto fill-white"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Copy"></use></svg></button>
                        </div>

                        <div class="flex gap-4 mt-4">
                            <button class="flex items-center justify-center gap-1 w-1/2 btn-yellow h-12 md:h-14 font-bold text-xs md:text-sm">새 코드 발급 <svg class="w-4 h-4 fill-white"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></button>
                            <button class="w-1/2 h-12 md:h-14 bg-backlight rounded font-bold text-xs md:text-sm">소셜미디어로 공유하기</button>
                        </div>

                        <button class="flex items-center justify-end gap-1 w-full mt-4 text-right" (click)="modal.open($event, 'referral-info')">레퍼럴 이용약관 <svg class="w-4 h-4 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                    </div>
                </div>
                <div class="w-full xl:w-2/3 bg-stand p-3 md:p-5 rounded">
                    <div class="flex items-center py-2 bg-back">
                        <div class="flex items-center gap-3 w-1/2 px-4 md:px-2 border-r border-slate-500 border-opacity-50">
                            <img class="md:block hidden w-20" src="/assets/custom_img/affiliate/trophy.png" alt="">
                            <p class="text-tit">
                                총 레퍼럴 수익:<br/>
                                <b class="text-yellow text-2xl">₩0</b>
                            </p>
                        </div>
                        <div class="flex items-center gap-3 w-1/2 px-4 md:px-2">
                            <img class="md:block hidden w-16" src="/assets/custom_img/affiliate/usehead.png" alt="">
                            <p class="text-tit">
                                총 추천한 친구 수:<br/>
                                <b class="text-2xl">0</b>
                            </p>
                        </div>
                    </div>
                    <div class="flex items-center md:flex-row flex-col gap-3 md:gap-5 mt-3 md:mt-5">
                        <div class="w-full md:w-1/2 bg-back rounded">
                            <div class="flex items-center justify-between h-12 md:h-16 px-3 bg-backlight rounded">
                                <p class="flex items-center gap-2 text-tit ">
                                    <img class="w-5 md:w-8" src="/assets/custom_img/affiliate/network.png" alt="">
                                    <b class="text-xs md:text-sm">추천인 보상</b>
                                </p>
                                <button class="flex items-center gap-1 text-primary font-medium text-xs md:text-sm" (click)="modal.open($event, 'referral-rule')">자세히 <svg class="w-4 h-4 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                            </div>
                            <div class="p-4">
                                <p class="py-5 text-center text-yellow text-lg md:text-2xl font-bold">₩0</p>
                                <button class="flex items-center justify-center gap-1 w-full py-2 bg-tab text-primary rounded font-medium text-xs md:text-sm">출금 <svg class="w-3 h-3 md:w-4 md:h-4 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></button>
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 bg-back rounded">
                            <div class="flex items-center justify-between h-12 md:h-16 px-3 bg-backlight rounded">
                                <p class="flex items-center gap-2 text-tit ">
                                    <img class="w-5 md:w-8" src="/assets/custom_img/affiliate/discount.png" alt="">
                                    <b class="text-xs md:text-sm">커미션 보상</b>
                                </p>
                                <button class="flex items-center gap-1 text-primary font-medium text-xs md:text-sm" (click)="modal.open($event, 'commission-info')">자세히 <svg class="w-4 h-4 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                            </div>
                            <div class="p-4">
                                <p class="py-5 text-center text-yellow text-lg md:text-2xl font-bold">₩0</p>
                                <button class="flex items-center justify-center gap-1 w-full py-2 bg-tab text-primary rounded font-medium text-xs md:text-sm">출금 <svg class="w-3 h-3 md:w-4 md:h-4 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></button>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <div class="bg-backlight py-3 px-4 text-tit text-sm md:text-base font-bold rounded">보상 활동</div>
                        <div class="overflow-y-auto h-40 bg-back scrollbar rounded">
                            <div class="flex flex-col justify-center pb-5 text-center">
                                <img class="w-44 mx-auto -mt-6" src="/assets/custom_img/empty.webp">
                                <div class="-mt-6 text-basic opacity-70 text-xs md:text-sm">아직 초대한 친구가 없습니다! 지금 친구를 초대해 보세요.</div>
                            </div>
                        </div>
                    </div>
                    <button class="flex items-center justify-center gap-1 btn-purple w-full h-16 mt-5 font-bold"> 
                        <img class="w-4" src="/assets/custom_img/affiliate/dashboard.png" alt="">
                        대시보드로 이동 
                        <svg class="w-4 h-4 fill-white"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg>
                    </button>
                </div>
                
            </div>

            <div class="mt-8 flex items-center gap-2">
                <span class="relative flex h-2.5 w-2.5">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-danger opacity-75"></span>
                    <span class="relative inline-flex rounded-full mt-0.5 ml-0.5 h-1.5 w-1.5 bg-danger"></span>
                </span>
                실시간 보상
            </div>
            <div class="mt-3 bg-stand flex flex-col md:flex-row rounded">
                <div class="w-full md:w-1/2 relative pl-5 md:pl-20 pr-5 py-5 text-center">
                    <img class="block md:hidden w-2/5 mx-auto" src="/assets/custom_img/affiliate/rewards_m.png" alt="">
                    <img class="absolute left-0 bottom-0 h-full md:block hidden" src="/assets/custom_img/affiliate/rewards.png" alt="">
                    <p>현재까지 전송된 총 보상</p>
                    <div class="w-full md:w-3/5 mt-1 mx-auto border border-slate-300 border-opacity-50 bg-back py-1 text-yellow text-xl font-bold rounded">₩31,161,417,405</div>
                </div>
                <div class="w-full md:w-1/2 p-5 md:border-l border-t border-slate-300 border-opacity-50">
                    <div class="live_slide_box overflow-hidden h-28 md:h-14">
                        <ul class="swiper-wrapper">
                            <li class="swiper-slide">
                                <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-2 gap-y-2 md:gap-y-3 py-0.5 overflow-hidden">
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/BCD.webp" alt=""><b class="text-tit truncate">Aegasev</b><span class="text-primary">₩181</span> 보상을 받았습니다</div>
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Adktqemujyb</b><span class="text-primary">₩25</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Nkcdqkstjyb</b><span class="text-primary">₩647</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/TRX.webp" alt=""><b class="text-tit truncate">Aslnmbopkwb</b><span class="text-primary">₩32,351</span> 보상을 받았습니다</div>
                                </div>
                            </li>
                            <li class="swiper-slide">
                                <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-2 gap-y-2 md:gap-y-3 py-0.5 overflow-hidden">
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/BCD.webp" alt=""><b class="text-tit truncate">Aegasev</b><span class="text-primary">₩181</span> 보상을 받았습니다</div>
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Adktqemujyb</b><span class="text-primary">₩25</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Nkcdqkstjyb</b><span class="text-primary">₩647</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/TRX.webp" alt=""><b class="text-tit truncate">Aslnmbopkwb</b><span class="text-primary">₩32,351</span> 보상을 받았습니다</div>
                                </div>
                            </li>
                            <li class="swiper-slide">
                                <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-2 gap-y-2 md:gap-y-3 py-0.5 overflow-hidden">
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/BCD.webp" alt=""><b class="text-tit truncate">Aegasev</b><span class="text-primary">₩181</span> 보상을 받았습니다</div>
                                    <div class="flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Adktqemujyb</b><span class="text-primary">₩25</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/USDT.webp" alt=""><b class="text-tit truncate">Nkcdqkstjyb</b><span class="text-primary">₩647</span> 보상을 받았습니다</div>
                                    <div class="flex md:hidden xl:flex items-center gap-1 whitespace-nowrap"><img class="w-4" src="/assets/custom_img/coin/TRX.webp" alt=""><b class="text-tit truncate">Aslnmbopkwb</b><span class="text-primary">₩32,351</span> 보상을 받았습니다</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div class="relative flex items-center flex-col md:flex-row bg-stand mt-8 h-auto md:h-96">
                <div class="flex items-center justify-between w-full md:w-[30%] md:min-w-[320px] h-full md:pt-0 pt-14 affiliate_bg1">
                    <img class="w-4/5 mx-auto pl-10" src="/assets/custom_img/affiliate/program.png" alt="">
                </div>
                <div class="w-full pl-5 md:pl-0 pr-5 pt-0 pb-10 md:py-10">
                    <h4 class="absolute left-0 top-0 w-full md:relative text-base md:text-3xl font-bold text-tit md:text-left text-center md:pt-0 pt-5"><span class="text-primary">제휴 프로그램</span>에 대해 자세히 알아보세요.</h4>
                    <div class="relative pl-4 mt-4 md:text-sm text-xs">
                        <i class="absolute left-0 top-1.5 w-2 h-2 bg-sub rotate-45"></i>
                        유저와 팔로워가 많은 경우. 추천 프로그램을 맞춤화할 수 있는 특별한 조건이 있습니다!
                    </div>
                    <div class="relative pl-4 mt-3 md:text-sm text-xs">
                        <i class="absolute left-0 top-1.5 w-2 h-2 bg-sub rotate-45"></i>
                        플레이어를 초대할 수 있고 베팅 금액이 10억 달러 이상에 도달하면 나만의 맞춤형 카지노를 갖게 됩니다! 도메인과 디자인 스타일로 카지노 웹사이트를 설정할 수 있습니다.
                    </div>
                    <div class="mt-4">
                        <div class="md:text-sm text-xs">자세히 알려주세요! 고객센터:</div>
                        <button class="w-96 max-w-full px-3 py-4 mt-2 bg-back flex items-center justify-between hover:bg-gradient-green md:text-sm text-xs">
                            <span class="text-tit">business&#64;kbcgaming.com</span>
                            <span class="text-primary">지금 전송 <svg class="inline-flex w-4 h-4 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Mail"></use></svg></span>
                        </button>
                    </div>
                </div>
            </div>


            
           


        </div>

        <div class="bg-btndark py-10 ">
            <div class="container">
                
                <div class="text-tit text-center text-lg md:text-4xl font-extrabold">자주 묻는 질문</div>

                <div class="accordial_list mt-2">
                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            추천 시스템의 작동 방법
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            추천 링크를 친구, 가족과 공유하거나 링크를 광고하고 당사 사이트에서 플레이어 가입을 하면 해당 플레이어가 추천이 되며 KBC GAME에서 플레이함으로써 커미션과 추가 보상을 받게 됩니다.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            내 추천으로 얼마를 벌 수 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            베팅의 25% + 추천당 ₩1,318,034를 벌 수 있습니다.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            USD 보상이란 무엇이며 어떻게 작동하나요?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            ₩1,318,034에 가져오는 각 추천에 대해 KBC GAME USD 보상이 있습니다. 이 USD 보상은 추천 레벨이 VIP4에서 VIP 70으로 올라갈 때 10부분으로 제공됩니다.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            광고에 사용할 수 있는 배너가 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            팔로워가 많은데 어떻게 특별 거래를 얻을 수 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs"> 
                            트래픽이 좋은 웹사이트나 잠재고객이 많은 소셜 미디어 계정이 있는 경우 <a href="javascript:;" class="text-primary">support&#64;kbcgaming.com <svg class="inline-flex w-3 h-3 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a> 에서 특별 거래를 위해 연결할 수 있습니다.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            다른 웹사이트/계정이 있는 경우 얼마나 많은 제휴 링크를 만들 수 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            서로 다른 트래픽 소스에 대해 최대 20개의 고유 코드를 생성할 수 있습니다.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            내 추천 데이터를 볼 수 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            예, KBC GAME는 완전한 투명성을 믿으며 사용자 이름, 그들이 한 내기, 당신이 만든 커미션, 그들이 등록했을 때 그들이 사용한 당신의 링크와 같은 모든 데이터를 사용자에게 제공합니다. <a href="javascript:;" class="text-primary">제휴 대쉬보드 <svg class="inline-flex w-3 h-3 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a> 의 모든 것.
                        </div>
                    </div>

                    <div class="accordian_item border-b border-slate-300">
                        <div class="title flex items-center justify-between py-5 text-sm md:text-base text-tit font-bold cursor-pointer">
                            내 추천인에게 팁이나 보상을 보낼 수 있습니까?
                            <button class="shrink-0 plus_btn md:w-8 md:h-8 w-5 h-5 rounded-full">
                                <img class="off w-1/2 mx-auto" src="/assets/custom_img/bc/plus.webp" alt="">
                                <img class="on w-1/2 mx-auto" src="/assets/custom_img/bc/close.webp" alt="">
                            </button>
                        </div>
                        <div class="summary hidden pt-3 pb-5 font-medium md:text-sm text-xs">
                            예, 지원되는 모든 코인으로 추천 팁을 보낼 수 있습니다.<a href="javascript:;" class="text-primary">여기를 클릭하세요 <svg class="inline-flex w-3 h-3 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a>
                        </div>
                    </div>

                    
                </div>
            </div>

        </div>
    </ng-template>
</div>
