<!-- 스핀 후 당첨화면 2 -->
<div class="modal-content relative rounded">
    <div class="modal-body relative spin_prize">
        <img class="absolute ribbon_img" src="/assets/custom_img/rain_ribbon.webp" alt="">
        <div class="relative z-10 p-5 text-center">
            <div class="relative">
                <div class="relative z-10 my-8 prize type02">LUCKY<br/>BONUS</div>
                <img class="absolute animate-spin slow spin_img" src="/assets/custom_img/prize_spin.webp" alt="">
            </div>
            <div class="w-4/5 mx-auto">
                <div class="flex items-center justify-center gap-2 p-3 bg-black bg-opacity-30 rounded">
                    <img class="w-7" [src]="'/assets/custom_img/coin/' + options.code + '.webp'" alt="">
                    <p class="text-white font-bold text-xl">
                        <span class="text-primary">+ {{ options.amount }}</span>
                        <span>{{ options.code }}</span>
                    </p>
                </div>
                <div class="flex gap-2 mt-3">
                    <button class="w-4/5 h-12 btn-green font-bold" (click)="onClose.emit('close')">Collect Now</button>
                    <button class="w-1/5 h-12 btn-normal font-bold rounded"><svg class="w-5 h-5 mx-auto fill-white"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Share"></use></svg></button>
                </div>
            </div>
            <div class="p-2 py-4 bg-black bg-opacity-20 rounded mt-4">
                <div class="text-white">Share on social media</div>
                <div class="flex items-center flex-wrap justify-center gap-4 mt-2">
                    <a href="javascript:;"><img class="w-10" src="/assets/custom_img/share_icon/share_3.webp" alt=""></a>
                    <a href="javascript:;"><img class="w-10" src="/assets/custom_img/share_icon/share_8.webp" alt=""></a>
                    <a href="javascript:;"><img class="w-10" src="/assets/custom_img/share_icon/share_7.webp" alt=""></a>
                    <a href="javascript:;"><img class="w-10" src="/assets/custom_img/share_icon/share_11.webp" alt=""></a>
                    <a href="javascript:;"><img class="w-10" src="/assets/custom_img/share_icon/share_12.webp" alt=""></a>
                    <a href="javascript:;"><img class="w-10" src="/assets/custom_img/share_icon/share_13.webp" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</div>
