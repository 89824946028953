import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, ViewChild } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { ApiService } from '../../service/api.service';
import { CommonModule } from '@angular/common';
import { ModalService } from '../../common/modal/modal.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { SwiperNoticeComponent } from './swiper-notice.component';
import { PopupComponent } from '../../common/popup/popup.component';
import { PopupService } from '../../common/popup/popup.service';
import { ContestComponent } from './contest.component';


@Component({
    selector: 'app-main',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        SwiperNoticeComponent,
        ContestComponent,
        SwiperDirective,
        PopupComponent
    ],
    templateUrl: './main.component.html',
    styles: ``,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainComponent {
    private subs: Subscription[] = [];

    currentUser!:currentUserInfo | null;

    bigWonOptions!:SwiperOptions
    bigWonMobileOptions!:SwiperOptions
    bigWonItems!:any

    bestOfBestOptions!:SwiperOptions
    bestOfBestItems!:any

    get modal() { return this.modalService }
    constructor(
        private authService: AuthService,
        private apiService: ApiService,
        private modalService: ModalService,
        private router: Router,
        private popupService: PopupService
    ) {
        
    }

    ngOnInit(): void {
        // onSameUrlNavigation
        this.subs.push(
            this.router.events.subscribe((e: any) => {
                if(e instanceof NavigationEnd) {
                    this.fnGetData()
                }
            })
        )

        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )
        
        // 베스트 오브 베스트 게임
        this.bestOfBestOptions = {
            slidesPerView: 2,
            spaceBetween: 10,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                },
                815:{
                    slidesPerView: 4,
                },
                990:{
                    slidesPerView: 5,
                },
                1100:{
                    slidesPerView: 6,
                },
                1335:{
                    slidesPerView: 7,
                }
            },
            navigation: {
                nextEl: ".casino_con02 .swiper-next",
                prevEl: ".casino_con02 .swiper-prev",
            },
        }

        // 빅원
        this.bigWonOptions = {
            slidesPerView: 2,
            spaceBetween: 10,
            autoplay:{
                delay: 1500,
                disableOnInteraction: false,
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                },
                815:{
                    slidesPerView: 4,
                },
                990:{
                    slidesPerView: 5,
                },
                1100:{
                    slidesPerView: 6,
                },
                1335:{
                    slidesPerView: 7,
                }
            }
        }

        // 빅원 - 모바일
        this.bigWonMobileOptions = {
            direction: "vertical",
            slidesPerView: 3,
            autoplay:{
                delay: 2000,
                disableOnInteraction: false,
            },
        }

        this.bestOfBestItems = [
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_1.png` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_2.png` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_3.png` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_4.avif` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_5.avif` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_6.avif` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_7.avif` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_8.avif` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_9.avif` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_10.avif` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_11.avif` },
            { provider: `KBC Originals`, game: `Animals`, tags: [ `KBC Originals`, `Animals` ], rtp: `99`, win: `9900.00X`, image: `/assets/custom_img/casino/casino_12.avif` },
        ]

        this.bigWonItems = [
            { nick: 'donfoolio', is_show:true, hit_amount:'120000', image: '/assets/custom_img/casino/casino_1.png' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/casino_2.png' },
            { nick: 'donfoolio', is_show:true, hit_amount:'120000', image: '/assets/custom_img/casino/casino_3.png' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/casino_4.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/casino_5.avif' },
            { nick: 'donfoolio', is_show:true, hit_amount:'120000', image: '/assets/custom_img/casino/casino_6.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/casino_7.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/casino_8.avif' },
            { nick: 'donfoolio', is_show:true, hit_amount:'120000', image: '/assets/custom_img/casino/casino_9.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/casino_10.avif' },
            { nick: 'donfoolio', is_show:true, hit_amount:'120000', image: '/assets/custom_img/casino/casino_11.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/casino_12.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_1.avif' },
            { nick: 'donfoolio', is_show:true, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_2.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_3.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_4.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_5.avif' },
            { nick: 'donfoolio', is_show:true, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_6.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_7.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_8.avif' },
            { nick: 'donfoolio', is_show:true, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_9.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_10.avif' },
            { nick: 'donfoolio', is_show:false, hit_amount:'120000', image: '/assets/custom_img/casino/feature_buy_11.avif' },
        ]
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }


    fnGetData() {

    }

    Go($event:any = null, url:string='') {
        if($event) $event.preventDefault();
        if(!url) url = ($event.currentTarget as HTMLInputElement).getAttribute('href') || ''

        this.router.navigateByUrl(url);
    }
}
