import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'emoji-container',
    standalone: true,
    imports: [
        CommonModule
    ],
    templateUrl: './emoji.component.html',
    styles: `
.emoji-box-wrap {z-index:10; position:absolute; left:0; bottom:100%; width:280px; height:200px; background-color:var(--background-type-color); padding:10px 10px 40px; border-radius: 0.25rem;}    
.emoji-box-wrap button.btn_emoji_close { position: absolute;top:0rem;right:0rem;padding:.25rem; }    
.emoji-box-wrap button.btn_emoji_close svg { width:1rem;height:1rem;transition-duration:.5s;transition-property:all;transition-timing-function:cubic-bezier(.4,0,.2,1);fill:#98a7b5;}
.emoji-box-wrap button.btn_emoji_close:hover svg {
    --tw-rotate: -90deg;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    fill:#fff;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.emoji-box-wrap .emoji-box > div::-webkit-scrollbar { width:5px; }
.emoji-box-wrap .emoji-box > div::-webkit-scrollbar-thumb {background-color: #2D3035; border-radius:4px; }
.emoji-box-wrap .emoji-box > div::-webkit-scrollbar-track {background-color: transparent;}
.emoji-box-wrap .emoji-box > div {overflow:hidden; overflow-y:auto; display:none; height:150px; }
.emoji-box-wrap .emoji-box > div.active {display:flex; flex-wrap:wrap; align-content: flex-start; }
.emoji-box-wrap .emoji-box > div {overflow-y:auto; }
.emoji-box-wrap .emoji-box .emoji {width:36px; height:36px; font-size:25px; cursor:pointer; text-align:center; line-height:36px; }
.emoji-box-wrap .bot-nav {position:absolute; left:0; bottom:0; right:0; display:flex; justify-content: space-between; height:40px; line-height:40px; }
.emoji-box-wrap .bot-nav .emoji-icon {width:36px; height:36px; cursor:pointer; line-height:36px; text-align:center; }
.emoji-box-wrap .bot-nav .emoji-icon svg {width:24px; height:24px; margin:7px auto 0; fill:rgba(153,164,176,.6); }
.emoji-box-wrap .bot-nav .emoji-icon.active svg {fill:var(--primary-color)}
    `
})
export class EmojiComponent {
    @Output() choice:EventEmitter<any> = new EventEmitter();
    @Output() close:EventEmitter<any> = new EventEmitter();

    emojiList = [
        [],
        [`😀`,`😃`,`😄`,`😁`,`😆`,`😅`,`😂`,`🤣`,`😊`,`😇`,`🙂`,`🙃`,`😉`,`😌`,`😍`,`😘`,`😗`,`😙`,`😚`,`😋`,`😛`,`😝`,`😜`,`🤪`,`🤨`,`🧐`,`🤓`,`😎`,`🤩`,`😏`,`😒`,`😞`,`😔`,`😟`,`😕`,`🙁`,`😣`,`😖`,`😫`,`😩`,`😢`,`😭`,`😤`,`😠`,`😡`,`🤬`,`🤯`,`😳`,`😱`,`😨`,`😰`,`😥`,`😓`,`🤗`,`🤔`,`🤭`,`🤫`,`🤥`,`😶`,`😐`,`😑`,`😬`,`🙄`,`😯`,`😦`,`😧`,`😮`,`😲`,`😴`,`🤤`,`😪`,`😵`,`🤐`,`🤢`,`🤮`,`🤧`,`😷`,`🤒`,`🤕`,`🤑`,`🤠`,`😈`,`👿`,`👹`,`👺`,`🤡`,`💩`,`👻`,`💀`,`👽`,`👾`,`🤖`,`🎃`,`😺`,`😸`,`😹`,`😻`,`😼`,`😽`,`🙀`,`😿`,`😾`],
        [`🤲`,`👐`,`🙌`,`👏`,`🤝`,`👍`,`👎`,`👊`,`✊`,`🤛`,`🤜`,`🤞`,`🤟`,`🤘`,`👌`,`👈`,`👉`,`👆`,`👇`,`✋`,`🤚`,`🖖`,`👋`,`🤙`,`💪`,`🖕`,`🙏`],
        [`⚽`,`🏀`,`🏈`,`⚾`,`🎾`,`🏐`,`🏉`,`🎱`,`🏓`,`🏸`,`🥅`,`🏒`,`🎳`,`🎮`,`🎰`],
        [`🐶`,`🐱`,`🐭`,`🐹`,`🐰`,`🦊`,`🐻`,`🐼`,`🐨`,`🐯`,`🦁`,`🐮`,`🐷`,`🐽`,`🐸`,`🐵`,`🙈`,`🙉`,`🙊`,`🐒`,`🐔`,`🐳`,`🐋`,`🦈`,`🐊`,`💧`,`💦`,`☔`,`🌞`,`🌈`,`🌙`],
        [`🍏`,`🍎`,`🍐`,`🍊`,`🍋`,`🍌`,`🍉`,`🍇`,`🍓`,`🍈`,`🍒`,`🥐`,`🍞`],
        [`🧡`,`💔`,`🔞`,`📵`,`🚭`,`❗`,`❓`,`💤`,`🏧`,`🚾`,`🕐`,`🌹`,`🏴`,`🏁`,`🚩`,`☠`]
    ]

    emoji:number = 1

    constructor() {
        const recentItems = localStorage.getItem('emojiRecent')
        if(recentItems) this.emojiList[0] = JSON.parse(recentItems)
    }

    ngOnInit() {}

    ngOnDestory() {}

    onChoice(str:string) {
        this.recent(str)
        this.choice.emit(str)
    }

    recent(str:string) {
        let arr = []
        const recentItems = localStorage.getItem('emojiRecent')
        if(recentItems) arr = JSON.parse(recentItems)

        arr = arr.filter((v:any) => v != str) // 사용한 것을 제외하고
        arr.unshift(str)

        if(arr.length >= 20) arr.splice(20)
        this.emojiList[0] = arr

        localStorage.setItem(`emojiRecent`, JSON.stringify(arr))
    }

}
