import { environment, environmentOptions } from "../environments/environment";

export const passwordView = ($event:Event) => {
    const e = <HTMLElement>$event.currentTarget;
    if(e) {
        const svg = e.querySelector('svg use')

        if(e.classList.contains('on')){
            e.classList.remove('on')
            e.previousElementSibling?.setAttribute('type','password')
            svg?.setAttribute('xlink:href','/assets/custom_img/symbol-defs.svg#icon_View')
        }else{
            e.classList.add('on')
            e.previousElementSibling?.setAttribute('type','text')
            svg?.setAttribute('xlink:href','/assets/custom_img/symbol-defs.svg#icon_Hide')
        }
    }
}

export const viewAlert = (id:string) => {
    const alertBox = document.querySelector(`#${id}`)
    if(alertBox) {
        alertBox.classList.add('show')
    
        setTimeout(()=>{
            alertBox.classList.remove('show')
        },3000)
    }
}

export const GetLevelImage = (level:number | undefined) => {
    const LEVEL_IMAGE:environmentOptions = environment.LEVEL_IMAGE
    return (level) ? LEVEL_IMAGE[level] : ''
}