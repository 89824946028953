import { Component, Inject, isDevMode } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { CommonModalComponent } from '../common/modal/common-modal.component';
import { CommonLoadingComponent } from '../common/loading/common-loading.component';
import { CommonMessageComponent } from '../common/message/common-message.component';
import { SettingsService } from './auth/settings.service';
import { register } from 'swiper/element/bundle';



@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        CommonModalComponent,
        CommonLoadingComponent,
        CommonMessageComponent
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    private subs: Subscription[] = [];
    
    lang:string | null;
    configuration = environment.configuration
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private translateService: TranslateService,
        private settingsService: SettingsService,
        private activatedRoute: ActivatedRoute,
        private router: Router

    ) {
        register();

        this.translateService.addLangs(['en', 'ko'])
        this.translateService.setDefaultLang(`en`)

        // 로컬 스토리지에 lang가 저장되어 있지 않으면 사용자 환경
        let lang = localStorage.getItem('LANG')
        if(lang === 'undefined') {
            lang = (new Intl.NumberFormat()).resolvedOptions().locale
            localStorage.setItem('LANG', lang);
        }

        this.lang = (lang?.match(/ko/)) ? 'ko' : 'en'
        this.setLocale(this.lang)
    }

    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if(!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

        this.activatedRoute.queryParams.subscribe((params: Params) => {
            if(params['referral']) {
                sessionStorage.setItem(`referral`, params['referral'])
                this.router.navigateByUrl('/')
            }
        });
        
        if(!isDevMode() && this.configuration == 'production') this.disableDevMode()

        this.subs.push(
            this.settingsService.getValue().subscribe(v => {
                if(v) {
                    this.setDarkMode(v.dark_mode)
                    this.setLocale(v.lang)
                }
            })
        )

        this.createLivechat()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    setLocale(lang:string): void {
        this.translateService.use(lang)
        localStorage.setItem('LANG', lang);
    }

    setDarkMode(isDark:boolean = true) {
        if(!isDark) this.document.documentElement.classList.remove('dark')
        else {
            const contains = this.document.documentElement.classList.contains('dark')
            if(!contains) this.document.documentElement.classList.add('dark')
        }
    }

    disableDevMode() {
        const script = document.createElement('script');
        script.text = `!function n(){try{!function n(t){(1!==(""+t/t).length||t%20==0)&&(function(){}).constructor("debugger")(),n(++t)}(0)}catch(t){setTimeout(n,500)}}();`
        document.head.appendChild(script);
    }

    createLivechat() {
        return; // livechat support angular element

        (window as any).__lc = (window as any).__lc || {};
        (window as any).__lc.license = environment.LIVECHAT;
        (window as any).__lc.integration_name = "manual_onboarding";
        (window as any).__lc.product_name = "livechat";

        const script = document.createElement('script');
        script.text = `
            ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))    
        `
        document.head.appendChild(script);
    }
}



