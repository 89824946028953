import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-bcd-info-modal',
  standalone: true,
  imports: [],
  templateUrl: './bcd-info-modal.component.html',
  styles: ``
})
export class BcdInfoModalComponent {
    constructor() {
    }
}
