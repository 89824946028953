<!-- 레퍼럴 보상 규칙 -->
<div class="modal-content">
    <div class="modal-body bg-modaldark relative rounded referral_rule_modal">
        <div class="relative flex items-center justify-between p-4">
            <p class="text-tit font-extrabold text-base">레퍼럴 보상 규칙</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="max-h-[660px] overflow-y-auto scrollbar font-medium">
            <div class="p-3 md:p-5 pb-10">
                <p class="text-center text-tit">레퍼럴 보상을 받는 방법</p>
                <div class="flex items-center flex-col md:flex-row md:gap-0 gap-3 mt-4">
                    <div class="flex justify-center flex-col p-5 md:pr-10 bg1 w-full md:w-1/3 md:h-[152px] rounded">
                        <p class="text-lg md:text-4xl font-extrabold text-primary">1</p>
                        <p class="text-tit text-sm md:text-base">친구에게 <span class="text-primary">공유</span></p>
                        <p class="text-xs md:text-sm">친구에게 추천 링크 및 코드 공유</p>
                    </div>
                    <div class="flex justify-center flex-col p-5 md:pl-10 bg2 w-full md:w-1/3 md:h-[152px] rounded">
                        <p class="text-lg md:text-4xl font-extrabold text-primary">2</p>
                        <p class="text-tit text-sm md:text-base">Get <span class="text-primary">$1000</span></p>
                        <p class="text-xs md:text-sm">지금은 귀하의 보상이 잠겨있습니다.</p>
                    </div>
                    <div class="flex justify-center flex-col p-5 md:pl-10 bg3 w-full md:w-1/3 md:h-[152px] rounded">
                        <p class="text-lg md:text-4xl font-extrabold text-primary">3</p>
                        <p class="text-tit text-sm md:text-base">레벨 업 및 <span class="text-primary">받기!</span></p>
                        <p class="text-xs md:text-sm">친구의 VIP 레벨에 따라 보상이 잠금 해제됩니다(아래 규칙 참조).</p>
                    </div>
                </div>
            </div>
            <div class="p-3 md:p-5 pb-10 bg-sponsor">
                <div class="flex items-center justify-center gap-2">
                    <span class="block w-12 h-[2px]" style="background:linear-gradient(90deg,#98A7B5 0%,rgba(152,167,181,0) 100%); transform:scaleX(-1)"></span>
                    <p class="text-tit">잠금해제 규칙</p>
                    <span class="block w-12 h-[2px]" style="background:linear-gradient(90deg,#98A7B5 0%,rgba(152,167,181,0) 100%);"></span>
                </div>
                <div class="bg-modaldark p-3">
                    <table class="table table-striped noline mo_pad-s rounded text-xs md:text-base"> 
                        <thead> 
                            <tr> 
                                <th class="whitespace-nowrap text-left">친구 레벨</th> 
                                <th class="whitespace-nowrap text-center">총 롤링</th> 
                                <th class="whitespace-nowrap text-right">잠금해제된 금액</th> 
                            </tr> 
                        </thead> 
                        <tbody> 
                            <tr class="rounded">
                                <td class="text-left text-tit font-bold">VIP 04</td>
                                <td class="text-center font-semibold">1000</td> 
                                <td class="text-right text-yellow font-extrabold"><img class="inline-flex w-5 mr-1 mb-1" src="/assets/custom_img/coin/USD.webp" alt="">+0.50</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left text-tit font-bold">VIP 08</td>
                                <td class="text-center font-semibold">5000</td> 
                                <td class="text-right text-yellow font-extrabold"><img class="inline-flex w-5 mr-1 mb-1" src="/assets/custom_img/coin/USD.webp" alt="">+2.50</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left text-tit font-bold">VIP 14</td>
                                <td class="text-center font-semibold">17000</td> 
                                <td class="text-right text-yellow font-extrabold"><img class="inline-flex w-5 mr-1 mb-1" src="/assets/custom_img/coin/USD.webp" alt="">+5.00</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left text-tit font-bold">VIP 22</td>
                                <td class="text-center font-semibold">49000</td> 
                                <td class="text-right text-yellow font-extrabold"><img class="inline-flex w-5 mr-1 mb-1" src="/assets/custom_img/coin/USD.webp" alt="">+12.00</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left text-tit font-bold">VIP 30</td>
                                <td class="text-center font-semibold">129000</td> 
                                <td class="text-right text-yellow font-extrabold"><img class="inline-flex w-5 mr-1 mb-1" src="/assets/custom_img/coin/USD.webp" alt="">+25.00</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left text-tit font-bold">VIP 38</td>
                                <td class="text-center font-semibold">321000</td> 
                                <td class="text-right text-yellow font-extrabold"><img class="inline-flex w-5 mr-1 mb-1" src="/assets/custom_img/coin/USD.webp" alt="">+50.00</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left text-tit font-bold">VIP 46</td>
                                <td class="text-center font-semibold">769000</td> 
                                <td class="text-right text-yellow font-extrabold"><img class="inline-flex w-5 mr-1 mb-1" src="/assets/custom_img/coin/USD.webp" alt="">+80.00</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left text-tit font-bold">VIP 54</td>
                                <td class="text-center font-semibold">1793000</td> 
                                <td class="text-right text-yellow font-extrabold"><img class="inline-flex w-5 mr-1 mb-1" src="/assets/custom_img/coin/USD.webp" alt="">+120.00</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left text-tit font-bold">VIP 62</td>
                                <td class="text-center font-semibold">4097000</td> 
                                <td class="text-right text-yellow font-extrabold"><img class="inline-flex w-5 mr-1 mb-1" src="/assets/custom_img/coin/USD.webp" alt="">+205.00</td> 
                            </tr> 
                            <tr class="rounded">
                                <td class="text-left text-tit font-bold">VIP 70</td>
                                <td class="text-center font-semibold">9217000</td> 
                                <td class="text-right text-yellow font-extrabold"><img class="inline-flex w-5 mr-1 mb-1" src="/assets/custom_img/coin/USD.webp" alt="">+500.00</td> 
                            </tr> 
                        </tbody> 
                    </table> 
                </div>
            </div>
        </div>
    </div>
</div>
