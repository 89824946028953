import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-rolling-info-modal',
  standalone: true,
  imports: [],
  templateUrl: './rolling-info-modal.component.html',
  styles: ``
})
export class RollingInfoModalComponent {
}
