import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../../service/api.service';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
    private subject:BehaviorSubject<Array<any>> = new BehaviorSubject<any>([]);

    constructor(
        private apiService: ApiService
    ) {
        this.onInit()
    }

    public getData() {
        return this.subject.asObservable() 
    }

    private onInit() {
        this.apiService.get('/site/popup', false).subscribe({
            next: (jsondata) => {
                if(jsondata.success) {
                    this.subject.next(jsondata.data)
                }

                // 새로운 공지사항이 있는지 10분마다 체크
                setTimeout(() => {
                    this.onInit()
                }, 1000 * 60 * 30);
            }, error: (err) => {
                setTimeout(() => {
                    this.onInit()
                }, 1000 * 60 * 30);
            }
        })
    }
}