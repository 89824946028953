<!-- 입금 모달 -->
<div class="modal-content overflow-hidden relative rounded">
    <ng-template [ngIf]="isLoading">
        <app-loading />
    </ng-template>
    <!-- 기본 -->
    <div class="modal-body relative">
        <div class="relative px-5 bg-modaldark rounded-t">
            <div class="flex items-center justify-between py-3">
                <p class="text-tit font-extrabold text-base">입금</p>
                <button class="basic-hover px-2 ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
            </div>
            <div class="sm:px-5 pb-3">
                <ul class="nav nav-boxed-tabs bg-slate-200 dark:bg-back" role="tablist">
                    <li id="deposit-1-tab" class="nav-item w-full" role="presentation">
                        <button class="w-full h-9" [ngClass]="{'active': tab == 'currency'}"  type="button" (click)="tab='currency'">법정화폐</button>
                    </li>
                    <li id="deposit-2-tab" class="nav-item w-full" role="presentation">
                        <button class="w-full h-9" [ngClass]="{'active': tab == 'crypto'}" type="button" (click)="tab='crypto'">가상화폐</button>
                    </li>
                </ul>
            </div>

        </div>
        <div class="tab-content overflow-y-auto scrollbar h-auto sm:h-[680px] text-sub">
            <div class="tab-pane leading-relaxed p-6 sm:px-10" [ngClass]="{'active': tab == 'currency'}" >
                <form role="form" [formGroup]="form1" (ngSubmit)="onSubmit()">
                    <div class="modal-tab-wrap">
                        <!-- 입금 -->
                        <div class="deposit-detail">
                            <div class="flex items-center mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold" *ngIf="in_progress">
                                <div class="flex-1">입금신청이 진행중 입니다.</div>
                                <button type="button" class="btn btn-purple2 p-1">취소하기</button>
                            </div>
                            <div class="mt-3">입금계좌는 수시로 변경되므로, 충전시 마다 확인해주셔야 합니다!</div>
                            <p>
                                계좌번호 확인을 원하시면 비밀번호를 입력하고 <b class="text-tit">[입금계좌확인]</b> 버튼을 눌러주세요.
                            </p>
                            <div class="flex items-center justify-between bg-modaldark mt-3">
                                <div class="flex flex-1 items-center text-base">
                                    <input type="password" formControlName="pwd" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" placeholder="비밀번호" autocomplete="false" />
                                </div>
                                <button type="button" class="btn-yellow font-bold p-1" (click)="fnGetAccount()">입금계좌확인</button>
                            </div>
                            <div class="flex items-center justify-between mt-1 bg-modaldark p-1 rounded">
                                <div class="flex items-center gap-4 text-base">
                                    <p class="pl-4 text-tit">₩</p>
                                    <input type="text" formControlName="amount" class="form-control nofocus !bg-transparent pl-2 text-tit text-base" placeholder="" required autocomplete="off" (keyup)="fnSetBonus()" numbersOnly />
                                </div>
                                <div class="text-primary">추가 +₩{{ bonus | number }}</div>
                            </div>
                            <div class="flex items-center mt-3">
                                <div class="flex items-center gap-2">
                                    <label class="form-check-label ml-0" for="wallet-0"><b class="text-tit">300%</b>이상 롤링에 동의하며 보너스를 받겠습니다. </label> 
                                    <div class="form-check form-switch"> 
                                        <input formControlName="is_bonus_rolling" class="form-check-input small_check" type="checkbox" (change)="fnSetBonus()" (click)="bonus_check()" (keydown)="bonus_check()"> 
                                    </div>
                                </div>
                                <div class="tooltip_custom ml-1" *ngIf="is_first">
                                    <svg class="inline-flex w-5 h-5 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg>
                                    <div class="hover_box top w-56 shadow p-2 rounded bg-back">
                                        <p class="text-tit">입금 보너스는 프로모션 페이지에서 신청 바랍니다.</p>
                                        <!-- <span>180% 최대 0.53700060 BTC<br/>최소 입금 0.001 BTC</span> -->
                                    </div>
                                </div>
                            </div>
                            <p *ngIf="!bonus_check()">
                                보유금액이 <b class="text-tit">10,000원</b> 이하일 때 롤링 변경이 가능합니다.
                            </p>
                            <div class="cash_btn_box mt-2">
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(10000)">₩ 10,000</button>
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(50000)">₩ 50,000</button>
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(100000)">₩ 100,000</button>
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(300000)">₩ 300,000</button>
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(500000)">₩ 500,000</button>
                                <button type="button" class="cash_btn text-base text-tit rounded" (click)="fnSetAmount(1000000)">₩ 1,000,000</button>
                                <button type="button" class="btn-green h-12 font-bold" (click)="f['amount'].patchValue(0); bonus=0">초기화</button>
                            </div>

                            <div class="mt-3" *ngIf="is_show_charge">입금 계좌</div>
                            <input *ngIf="is_show_charge" class="mt-1 form-control bg-modaldark nofocus pl-2 text-tit" value="{{ charge_bank_name }} {{ charge_bank_account }} {{ charge_bank_owner }}" />
                            <div class="text-center mt-10">
                                <button type="submit" class="btn-green w-1/2 h-12 font-bold" [disabled]="in_progress">입금 신청</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="tab-pane leading-relaxed p-6 sm:px-10" [ngClass]="{'active': tab == 'crypto'}">
                <div class="flex items-center mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold" *ngIf="in_progress_crypto">
                    <div class="flex-1">입금신청이 진행중 입니다.</div>
                </div>
                <div class="mb-2 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold">
                    참고: <span class="text-tit">입금주소가 매번 변경됩니다. 입금주소를 꼭 확인하세요.</span>
                </div>
                <div>입금화폐</div>
                <div class="flex flex-wrap gap-1 items-center mt-1 font-medium">
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="setCtypto('USDT')"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/USDT.webp" /> USDT</button>
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="btnCrypto.click()"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/TRX.webp" /> TRX</button>
                    <!-- <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="setCtypto('XRP')"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/XRP.webp" /> XRP</button> -->
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="btnCrypto.click()"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/ETH.webp" /> ETH</button>
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="btnCrypto.click()"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/BTC.webp" /> BTC</button>
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="btnCrypto.click()"><img class="w-3.5 mr-1" src="/assets/custom_img/coin/BNB.webp" /> BNB</button>
                    <button class="flex items-center h-7 border border-solid border-slate-300 px-3 rounded-3xl hover:bg-slate-300 hover:bg-opacity-50" (click)="btnCrypto.click()">더보기 <img class="w-3.5 ml-1" src="/assets/custom_img/coin/TRX.webp" /><img class="w-3.5 -ml-1" src="/assets/custom_img/coin/DOGE.webp" /><img class="w-3.5 -ml-1" src="/assets/custom_img/coin/LTC.webp" /> <svg class="w-3 ml-1 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                </div>
                <div class="md:flex items-center justify-between gap-1">
                    <div class="flex-1">
                        <div class="mt-3">Deposit Currency</div>
                        <div class="ng-select2 flex-1 mt-3" ng-select>
                            <button class="btn w-32 h-12 flex px-4 items-center justify-between border-none bg-modaldark" #btnCrypto>
                                <div class="flex items-center gap-3 text-tit name text-base ">
                                    <img class="w-7" [src]="'/assets/custom_img/coin/' + currentCrypto +'.webp'" />
                                    {{currentCrypto}}
                                </div>
                                <div class="flex items-center gap-1">
                                    <!-- 잔고현황 -->
                                    <!-- <div class="cash text-tit">{{currentUser?.Wallets | wallet:currentCrypto || '0.0000'}}</div> -->
                                    <i class="ml-2"><svg class="w-3.5 h-3.5 fill-basic arr"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                                </div>
                            </button>
                            <div class="bg-white dark:bg-back2 shadow-lg">
                                <div class="p-4 pb-2">
                                    <div class="relative w-full">
                                        <label>
                                            <svg class="absolute left-3 top-2.5 w-5 h-5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Search"></use></svg>
                                            <input type="text" class="form-control bg-modaldark pl-10" placeholder="검색" [(ngModel)]="keyword" />
                                        </label>
                                    </div>
                                </div>
                                <ul class="overflow-y-auto scrollbar h-64 p-2 rounded text-sub">
                                    <li class="p-2 px-4 flex items-center justify-between" *ngFor="let item of cryptoItems | filter:'code':keyword" (click)="OnSelect2($event, item)" [ngClass]="{'on':item.code == currentCrypto}">
                                        <div class="flex items-center gap-2 text-tit font-extrabold name">
                                            <img class="w-7" [src]="'/assets/custom_img/coin/' + item.code +'.webp'" />
                                            {{ item.code }}
                                        </div>
                                        <div class="text-right text-xs">
                                            <!-- <b class="block text-tit text-sm">{{currentUser?.Wallets | wallet:item.code || '0.0000'}}</b> -->
                                            <!-- {{currentUser?.wallets[item.code]}} -->
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1">
                        <div class="mt-3">Choose Network</div>
                        <div class="ng-select flex-1 mt-3" ng-select>
                            <button class="btn w-32 h-12 flex px-4 items-center justify-between border-none bg-modaldark">
                                <span>{{ currentNetwork }}</span>
                                <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                            </button>
                            <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                                <li class="py-2" *ngFor="let item of cryptoNetworks" (click)="OnSelect($event, item)">{{ item }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <form role="form" [formGroup]="form1" (ngSubmit)="onCryptoSubmit()">
                    <div class="flex items-center justify-between mt-1 bg-modaldark p-1 rounded">
                        <div class="flex items-center gap-4 text-base">
                            <p class="pl-4 text-tit">{{ currentCrypto }}</p>
                            <input type="text" formControlName="amount" class="form-control nofocus !bg-transparent pl-2 text-tit " placeholder="" required autocomplete="off" numbersOnly />
                        </div>
                        <div class="text-primary">
                            <button type="button" class="btn-primary  px-1" (click)="currencyRate()">예상금액조회</button>
                        </div>
                    </div>
                    <div class="mt-4 bg-primary p-2 px-4 rounded bg-opacity-20 text-primary text-xs font-semibold">
                        예상금액: <span class="text-yellow mr-1">{{currency}}</span> <span class="text-tit">{{ currentRateCoin }} </span> <span>적용환율: <span class="text-tit">{{currentRate}}</span></span>
                    </div>
                    <div class="text-center mt-10">
                        <button type="submit" class="btn-green w-1/2 h-12 font-bold" [disabled]="in_progress_crypto">입금주소확인</button>
                    </div>
                </form>
                <div class="bg-[#f5f6fa] dark:bg-[#1b1c1f] flex gap-4 p-4" *ngIf="crypto_info?.qrImage">
                    <img class="w-36" [src]="crypto_info.qrImage" alt="">
                    <div>
                        <div class="flex">
                            <span class="text-tit">{{ crypto_info.wallet_code }}</span>
                        </div>
                        <div class="flex">
                            <span class="text-tit">네트워크</span>
                            <span class="text-yellow ml-1">{{ crypto_info.chain_info }}</span>
                            <span class="text-tit ml-2">입금시간</span>
                            <span class="text-yellow ml-1" remain [time]="crypto_info.expiredAt" (expire)="expired()"></span>
                        </div>
                        <div class="flex items-center gap-2 bg-back2 py-3 px-2">
                            <p class="break-all">{{ crypto_info.address }}</p>
                            <button type="submit" class="btn-normal basic-hover w-24 h-10 rounded" (click)="copyAddress(crypto_info.address)">복사 하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
