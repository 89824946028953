import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TimerService } from '../../service/timer.service';
import { Subscription } from 'rxjs';
import moment from 'moment-timezone';

@Component({
  selector: 'app-upcoming',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './upcoming.component.html',
  styles: ``
})
export class UpcomingComponent {
    private subs: Subscription[] = [];
    
    evt_btn:boolean = false
    uptime:moment.Moment | null = null
    countdown:string = ''
    constructor(
        private timerService: TimerService
    ) {
        // this.uptime = moment().add(1, `minutes`)
    }

    ngOnInit(): void {
        this.subs.push(
            this.timerService.getDate().subscribe(d => {

                    const diff = (this.uptime) ? this.uptime.diff(d, `seconds`) : 0;

                    if(diff > 0) {
                        const mind = diff % (60 * 60);
                        const hours = Math.floor(diff / (60 * 60));
                        const minutes = Math.floor(mind / 60);
                        const seconds = Math.ceil(mind % 60);

                        this.countdown = `${hours.toString().padStart(2, `0`)}:${minutes.toString().padStart(2, `0`)}:${seconds.toString().padStart(2, `0`)}`
                    } else {
                        this.countdown = `Comming Soon...`
                    }
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    btnEvent() {
        this.evt_btn = !this.evt_btn
    }
}
