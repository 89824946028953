<!-- 좌측메뉴 - 퀘스트허브 -->
<div class="modal-content overflow-hidden relative rounded" [ngClass]="{ 'on': modalTab }">
    <!-- 기본 -->
    <div class="modal-body relative">

        <div class="relative flex items-center justify-between p-4 bg-modaldark rounded-t">
            <p class="text-tit font-extrabold text-base">퀘스트허브</p>
            
            <button class="basic-hover px-2 ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>

        <div class="p-5 bg-gradient-purple overflow-y-auto scrollbar h-[660px]">
            <div class="text-white dark:text-purple-400">월요일</div>
            <div class="flex items-center justify-between mt-5">
                <div class="text-tit">
                    <p>누적 보상:</p>
                    <div class="flex items-center gap-1 text-2xl font-extrabold"><img class="w-5" src="/assets/custom_img/coin/BCD.webp"/> 0 <i>BCD</i></div>
                </div>
                <button class="btn-normal btn-top w-36 h-10" (click)="modalInHandle('prev_quest-body')">이전 퀘스트</button>
            </div>

            <ul class="nav nav-link-tabs mt-2" role="tablist">
                <li id="quest-1-tab" class="nav-item flex-1" role="presentation"> 
                    <button class="nav-link w-full py-2 active" data-tw-toggle="pill" data-tw-target="#quest-tab-1" type="button" role="tab" aria-controls="quest-tab-1" aria-selected="true"> 
                        일일 퀘스트
                    </button> 
                </li>
                <li id="quest-2-tab" class="nav-item flex-1" role="presentation"> 
                    <button class="nav-link w-full py-2" data-tw-toggle="pill" data-tw-target="#quest-tab-2" type="button" role="tab" aria-controls="quest-tab-2" aria-selected="false">
                        주간 미션
                    </button> 
                </li>
            </ul>
            <div class="tab-content mt-5">
                <div id="quest-tab-1" class="tab-pane leading-relaxed active" role="tabpanel" aria-labelledby="quest-1-tab"> 
                    <div class="flex items-center gap-4">만료 <b class="text-tit">23 : 28 : 22</b></div>

                    <div class="flex items-center gap-4 py-4 p-3 bg-back rounded mt-4">
                        <div>
                            <svg class="w-16 h-16" viewBox="0 0 64 64">
                                <circle w="64" h="64" stroke-width="7" r="28.5" cx="32" cy="32" fill="transparent" stroke="#46434C"></circle>
                                <circle w="64" h="64" stroke-width="0" r="20" cx="32" cy="32" fill="#46434C"></circle>
                                <circle w="64" h="64" stroke-width="7" r="28.5" cx="32" cy="32" fill="transparent" stroke="#8651FA" stroke-dasharray="179.0707812546182" stroke-dashoffset="240" style="transform: rotate(-90deg); transform-origin: center center;"></circle>
                                <text fill="#ffffff" x="32" y="37" text-anchor="middle" style="font-size: 12px;">0/3</text>
                            </svg>
                        </div>
                        <div>
                            <p class="text-tit font-bold">룰렛 멀티플레이어 마스터</p>
                            <div class="flex items-center justify-between gap-2 py-1">
                                <p class="text-xs text-sub">$ 0.4 이상의 베팅으로 룰렛 멀티플레이어에서 3 라운드 연속 승리.</p>
                                <button class="btn-green w-40 h-8">이동하기</button>
                            </div>
                            <div class="flex items-center gap-1">
                                <img class="w-5" src="/assets/custom_img/coin/BCD.webp"/>
                                <p class="text-yellow text-base font-extrabold">수익 <i>0.1 BCD</i></p>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center gap-4 py-4 p-3 bg-back rounded mt-2">
                        <div>
                            <img class="w-16" src="/assets/custom_img/refer.webp"/>
                        </div>
                        <div>
                            <div class="flex items-center gap-2 text-tit font-bold">
                                빅 윈!
                                <div class="tooltip_custom">
                                    <svg class="w-5 h-5 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg>
                                    <div class="hover_box top w-72 p-2 bg-back text-xs rounded">좋아하는 게임에 가서 게임이 50 이상의 지불금을 받을 수 있는지 확인하십시오. 그렇다면 이길 때까지 $ 0.4보다 큰 금액으로 계속 베팅하십시오.</div>
                                </div>
                            </div>
                            <div class="flex items-center justify-between py-1">
                                <p class="text-xs text-sub">$ 0.4 이상의 베팅으로 모든 게임에서 50x 이상의 지불금을 획득하십시오.</p>
                            </div>
                            <div class="flex items-center gap-1">
                                <img class="w-5" src="/assets/custom_img/coin/BCD.webp"/>
                                <p class="text-yellow text-base font-extrabold">수익 <i>0.2 BCD</i></p>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center gap-4 py-4 p-3 bg-back rounded mt-2">
                        <div>
                            <svg class="w-16 h-16" viewBox="0 0 64 64">
                                <circle w="64" h="64" stroke-width="7" r="28.5" cx="32" cy="32" fill="transparent" stroke="#46434C"></circle>
                                <circle w="64" h="64" stroke-width="0" r="20" cx="32" cy="32" fill="#46434C"></circle>
                                <circle w="64" h="64" stroke-width="7" r="28.5" cx="32" cy="32" fill="transparent" stroke="#8651FA" stroke-dasharray="179.0707812546182" stroke-dashoffset="179.0707812546182" style="transform: rotate(-90deg); transform-origin: center center;"></circle>
                                <text fill="#ffffff" x="32" y="37" text-anchor="middle" style="font-size: 12px;">0/100</text>
                            </svg>
                        </div>
                        <div>
                            <div class="flex items-center gap-2 text-tit font-bold">
                                롤링금액 달성
                                <div class="tooltip_custom">
                                    <svg class="w-5 h-5 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg>
                                    <div class="hover_box top w-72 p-2 bg-back text-xs rounded">오늘 모든 게임에서 누적 베팅 총액이 $100에 도달할 때까지 계속 베팅하세요.</div>
                                </div>
                            </div>
                            <div class="flex items-center justify-between py-1">
                                <p class="text-xs">일일 롤링 $ 100에 도달하세요.</p>
                            </div>
                            <div class="flex items-center gap-1">
                                <img class="w-5" src="/assets/custom_img/coin/BCD.webp"/>
                                <p class="text-yellow text-base font-extrabold">수익 <i>0.2 BCD</i></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="quest-tab-2" class="tab-pane leading-relaxed" role="tabpanel" aria-labelledby="quest-2-tab">
                    <div class="flex items-center gap-4">만료 <b class="text-tit">5D 23 : 28 : 22</b></div>

                    <div class="flex items-center gap-4 py-4 p-3 bg-back rounded mt-4">
                        <div>
                            <svg class="w-16 h-16" viewBox="0 0 64 64">
                                <circle w="64" h="64" stroke-width="7" r="28.5" cx="32" cy="32" fill="transparent" stroke="#46434C"></circle>
                                <circle w="64" h="64" stroke-width="0" r="20" cx="32" cy="32" fill="#46434C"></circle>
                                <circle w="64" h="64" stroke-width="7" r="28.5" cx="32" cy="32" fill="transparent" stroke="#8651FA" stroke-dasharray="179.0707812546182" stroke-dashoffset="179.0707812546182" style="transform: rotate(-90deg); transform-origin: center center;"></circle>
                                <text fill="#ffffff" x="32" y="37" text-anchor="middle" style="font-size: 12px;">0/700</text>
                            </svg>
                        </div>
                        <div>
                            <div class="flex items-center gap-2 text-tit font-bold">
                                주간 롤링
                                <div class="tooltip_custom">
                                    <svg class="w-5 h-5 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg>
                                    <div class="hover_box top w-72 p-2 bg-back text-xs rounded">이번 주 내 모든 게임에서 누적 베팅 총액이 $700에 도달할 때까지 베팅을 계속하세요.</div>
                                </div>
                            </div>
                            <div class="flex items-center justify-between py-1">
                                <p class="text-xs text-sub">주간 롤링 $ 700에 도달하세요.</p>
                            </div>
                            <div class="flex items-center gap-1">
                                <img class="w-5" src="/assets/custom_img/coin/BCD.webp"/>
                                <p class="text-yellow text-base font-extrabold">수익 <i>0.7 BCD</i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>

    <!-- 이전퀘스트 -->
    <div class="prev_quest-body modal-in modal-body relative rounded bg-stand" [ngClass]="{ 'open': modalTab == 'prev_quest-body'}">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('prev_quest-body')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">이전 퀘스트</p>
            </div>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="w-full p-6 px-4 overflow-y-auto scrollbar h-[660px]">
            <div class="flex flex-col justify-center h-full text-center">
                <img class="w-48 mx-auto -mt-32" src="/assets/custom_img/empty.webp">
                <div class="-mt-5 text-basic opacity-70">웁스! 아직 데이터가 없습니다!</div>
            </div>
        </div>
    </div>
</div>
