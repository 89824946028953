import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingComponent } from '../../common/loading/loading.component';
import { ApiService } from '../../service/api.service';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';

@Component({
    selector: 'app-memo-read',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent,
        MomentPipe
    ],
    templateUrl: './memo-read.component.html',
    styles: ``
})
export class MemoReadComponent {
    @Input() idx!:number;

    @Output() onList: EventEmitter<number> = new EventEmitter<number>();

    item:any
    isLoading:boolean = true;
    constructor(
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.fnGetData()
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.get(`/memo/read/${this.idx}`, false).subscribe({
            next: (jsondata) => {
                this.isLoading = false;
                if(jsondata.success) this.item = jsondata.data
            }, error: (err) => {
                this.isLoading = false;
            }
        })

    }

    list() {
        this.onList.emit()
    }
}
