import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '../../../common/modal/modal.service';

@Component({
  selector: 'app-vip-level-modal',
  standalone: true,
  imports: [],
  templateUrl: './vip-level-modal.component.html',
  styles: ``
})
export class VipLevelModalComponent {
    @Input() options:any;

    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    
    constructor(
        private modalService: ModalService
    ) {
    }

    ngOnDestroy(): void {
        
    }
    
 
    GoModal(next:string, $event:any = null): void {
        if($event) $event.preventDefault()

        this.onClose.emit()
        this.modalService.open(null, next)
    }
}
