import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-referral-rule-modal',
  standalone: true,
  imports: [],
  templateUrl: './referral-rule-modal.component.html',
  styles: ``
})
export class ReferralRuleModalComponent {
    constructor() {
    }
    
}
