<!-- Provably Fair 모달 -->
<div class="modal-content overflow-hidden relative rounded" [ngClass]="{ 'on': modalTab }">
    <!-- provably -->
    <div class="modal-body bg-stand relative rounded">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">Provably Fair</p>
            <div>
                <button (click)="modalInHandle('fair_modal')">
                    <svg class="w-5 h-5 fill-basic opacity-70"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Fairness"></use></svg>
                </button>
                <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
            </div>
        </div>
        <div class="p-4 py-6 h-[660px] overflow-y-auto scrollbar">
            Lottery is drawn in 5+1 balls rule, with five regular balls taken from 36 numbers and a jackpot ball taken from 10 numbers.<br/><br/>
            Server seed: Each game generates a new server seed and publishes the string after Hash256. Client Seeds: Get the ETH height at the end of the daily ticket purchase deadline, and use Hash after 10 blocks as the client seed.<br/><br/>
            First, use HMAC_SHA256 to calculate the hash value, which gives us a 64-bit character hex string:
            <div class="p-3 my-2 border border-slate-300 border-opacity-50 bg-back text-primary">
                hash = HMAC_SHA256 (clientSeed, serverSeed)
            </div>
            Then, take the 8 characters of hash and convert it to an int32 value. We divide the converted value by the 0x100000000, multiply by the number of balls, and the number is the winning position, according to which the corresponding ball is obtained.
        </div>
    </div>

    <!-- 공정성 -->
    <div class="fair_modal modal-in modal-body bg-stand relative rounded" [ngClass]="{ 'open': modalTab == 'fair_modal'}">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex gap-2 items-center">
                <button (click)="modalInHandle('fair_modal')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="./assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">복권 공정성</p>
            </div>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-4 py-6 max-h-[660px] overflow-y-auto scrollbar">
            <div class="border border-primary rounded bg-primary bg-opacity-10 px-5 py-3 text-xs text-primary">
                BC.GAME Lottery is provably fair which means you can examine the results usingand <a class="underline text-sm font-bold" href="javascript:;">온라인 검증 <svg class="inline-flex w-3 h-3 mx-auto fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a> following data. The whole data including the file with purchased tickets is available only when ticket sales stopped.
            </div>
            <div class="mt-3 flex items-center gap-2">
                서버시드 (hash)
                <div class="tooltip_custom pt-1.5">
                    <button><svg class="w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>
                    <div class="hover_box top bg-back w-40 rounded p-2 text-center">서버 시드의 SHA-256</div>
                </div>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02" value="vaser15wersgwer6asdgerhy6uty" readonly>
                <button class="absolute right-3 top-[14px] basic-hover" onclick="viewAlert('copy_alert')"><svg class="w-4 h-4"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Copy"></use></svg></button>
            </div>

            <div class="mt-3 flex items-center gap-2">
                서버시드 
                <div class="tooltip_custom pt-1.5">
                    <button><svg class="w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>
                    <div class="hover_box top bg-back w-40 rounded p-2 text-center">서버에서 무작위로 생성된 문자열</div>
                </div>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02" value="vaser15wersgwer6asdgerhy6uty" readonly>
            </div>

            <div class="mt-3 flex items-center gap-2">
                블록 멈추기 
                <div class="tooltip_custom pt-1.5">
                    <button><svg class="w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>
                    <div class="hover_box top bg-back w-40 rounded p-2 text-center">추첨일 14:55 UTC+0의 ETH 블록체인 높이</div>
                </div>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02" value="56198651" readonly>
            </div>

            <div class="mt-3 flex items-center gap-2">
                고객 시드 잠김
                <div class="tooltip_custom pt-1.5">
                    <button><svg class="w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>
                    <div class="hover_box top bg-back w-40 rounded p-2 text-center">스톱 블록에서 10 블록 후 ETH 블록 체인 높이</div>
                </div>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02" value="56198651" readonly>
            </div>

            <div class="mt-3 flex items-center gap-2">
                클라이언트 시드(hashed)를 선택하세요.
                <div class="tooltip_custom pt-1.5">
                    <button><svg class="w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>
                    <div class="hover_box top bg-back w-40 rounded p-2 text-center">ETH 블록체인의 목표 블록</div>
                </div>
                <button class="hover:text-primary"><svg class="inline-flex w-4 h-4 mx-auto fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Download"></use></svg> 모든 티켓 목록 파일</button>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02" value="56198651" readonly>
            </div>
            <div class="mt-10 text-center">
                <button class="btn-green w-2/3 h-12 font-bold">인증 <svg class="inline-flex w-3 h-3 mx-auto fill-title"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></button>
            </div>
        </div>
    </div>
</div>
