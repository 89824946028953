<!-- 커미션 보상 규칙 -->
<div class="modal-content">
    <div class="modal-body bg-stand relative rounded comission_modal">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-base">커미션 보상 규칙</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-4 py-6 max-h-[660px] overflow-y-auto scrollbar font-medium">
            <ul class="list-disc pl-4">
                <li>커미션 비율은 게임에 따라 다릅니다.</li>
            </ul>

            <div class="mt-5 p-5 rounded bg1">
                <div class="flex items-center gap-3">
                    <div class="flex flex-start gap-1">
                        <b class="text-tit text-4xl font-bold">7%</b>
                        <div class="tooltip_custom cursor-pointer">
                            <b class="flex items-center justify-center w-5 h-5 rounded-full bg-[#D9D9D9] dark:bg-[#656B74] text-back">?</b>
                            <div class="hover_box left_side w-60 shadow bg-modaldark rounded p-3">
                                계산 예: 내기 100<br/>
                                커미션= 100 ✕ 1% ✕ 7% = 0.07
                            </div>
                        </div>
                    </div>
                    1% 베팅 의
                </div>
                <p class="mt-2 text-sub">게임: <span class="text-basic">오리지날 게임</span></p>
            </div>
            <div class="mt-4 p-5 rounded bg2">
                <div class="flex items-center gap-3">
                    <div class="flex flex-start gap-1">
                        <b class="text-tit text-4xl font-bold">15%</b>
                        <div class="tooltip_custom cursor-pointer">
                            <b class="flex items-center justify-center w-5 h-5 rounded-full bg-[#D9D9D9] dark:bg-[#656B74] text-back">?</b>
                            <div class="hover_box left_side w-60 shadow bg-modaldark rounded p-3">
                                계산 예: 내기 100<br/>
                                커미션= 100 ✕ 1% ✕ 15% = 0.15
                            </div>
                        </div>
                    </div>
                    1% 베팅 의
                </div>
                <p class="mt-2 text-sub">게임: <span class="text-basic">슬롯, 라이브 카지노</span></p>
            </div>
            <div class="mt-4 p-5 rounded bg3">
                <div class="flex items-center gap-3">
                    <div class="flex flex-start gap-1">
                        <b class="text-tit text-4xl font-bold">25%</b>
                        <div class="tooltip_custom cursor-pointer">
                            <b class="flex items-center justify-center w-5 h-5 rounded-full bg-[#D9D9D9] dark:bg-[#656B74] text-back">?</b>
                            <div class="hover_box left_side w-60 shadow bg-modaldark rounded p-3">
                                계산 예: 내기 100<br/>
                                커미션= 100 ✕ 1% ✕ 25% = 0.25
                            </div>
                        </div>
                    </div>
                    1% 베팅 의
                </div>
                <p class="mt-2 text-sub">게임: <span class="text-basic">스포츠</span></p>
            </div>

            <div class="bg-back py-2 px-4 flex items-center gap-4 mt-5">
                <b class="flex items-center justify-center w-5 h-5 rounded-full bg-[#D9D9D9] dark:bg-[#656B74] text-back">!</b>
                <div>
                    커미션 요율을 사용자 정의한 경우 여기에서 요율을 확인하십시오.<br/>
                    <a href="javascript:;" class="text-primary font-bold">내 커미션 비율 보기 <svg class="inline-flex w-3 h-3 ml-1 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a>
                </div>
            </div>

            <ul class="list-disc pl-4 mt-5">
                <li>모든 공공 환경(예: 대학, 학교, 도서관, 사무실 공간)에서는 컴퓨터와 IP 주소는 다른 사람과 공유됩니다.</li>
                <li class="mt-5">베팅 추첨에 대한 우리의 결정은 입금이 이루어지고 베팅이 성공적으로 완료된 후 전적으로 우리의 재량에 따라 결정됩니다.</li>
                <li class="mt-5">커미션은 언제든지 대시보드에서 내부 KBC GAME 지갑으로 인출할 수 있습니다. (대시보드에서 커미션 추출을 확인하고 지갑에서 잔액을 확인하세요).</li>
                <li class="mt-5">우리는 시장에서 대부분의 통화를 지원합니다.</li>
                <li class="mt-5">시스템은 24시간마다 커미션을 계산합니다.</li>
            </ul>
            <div class="mt-8 rounded border border-slate-300 border-opacity-60 p-4 text-center">
                규정에 대해 궁금한 사항이 있으시면 <a href="javascript:;" class="text-primary">고객센터<svg class="inline-flex w-3 h-3 ml-1 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewWindow"></use></svg></a>
            </div>
        </div>
    </div>
</div>
