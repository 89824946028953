<!-- 코인드롭 모달 -->
<div class="modal-content overflow-hidden relative rounded" [ngClass]="{ 'on' : modalTab }">
    <!-- 기본 -->
    <div class="modal-body relative rounded">
        <div class="relative flex items-center justify-between bg-modaldark p-4">
            <p class="text-tit font-extrabold text-base">코인드롭</p>
            <button class="basic-hover" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="p-6 pb-20 h-[670px]">
            <div class="flex items-center justify-between">
                <p>양</p>
                <p>최소: 0.00002645 BTC</p>
            </div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02 !h-14 text-tit" value="0.00002645">
                <button class="absolute right-0 top-2 flex items-center gap-2 h-10 px-6 text-base font-extrabold border-l border-solid border-slate-300 text-tit" (click)="modalInHandle('rain_pot-body')">
                    <img class="w-7" src="/assets/custom_img/coin/BTC.webp" alt="">
                    BTC
                    <svg class="w-3 h-3 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                </button>
            </div>
            <div class="flex justify-between mt-2">
                <div>이용 가능 :<b class="text-tit">₩0.00</b></div>
                <div><span class="underline cursor-pointer" (click)="modalInHandle('rain_lock-body')">잠김 금액 :</span><b class="text-tit">₩0.00</b></div>
            </div>
            <div class="mt-5">사람 수</div>
            <div class="relative mt-2">
                <input type="text" class="form-control type02 !h-14 text-tit" value="50">
                <div class="absolute right-0 top-2 flex items-center h-10 px-6  text-sub">
                    1~100
                </div>
            </div>
            <div class="mt-5">메세지 (옵션)</div>
            <div class="relative mt-2">
                <span class="absolute right-0 bottom-0 p-4 text-sub">0/20</span>
                <textarea class="form-control type02 !h-32 text-tit resize-none"></textarea>
            </div>
            <div class="text-center py-6 text-tit text-2xl font-extrabold">
                1.003295 <b class="text-primary">USDC</b>
            </div>
            <div class="text-center">
                <button class="btn-green w-2/3 h-12 font-bold" disabled>코인드롭 시작하기</button>
            </div>
        </div>
    </div>

    <!-- 화폐 -->
    <div class="rain_pot-body modal-body relative modal-in bg-stand rounded" [ngClass]="{ 'open': modalTab == 'rain_pot-body'}">
        <div class="relative flex items-center justify-between p-4 py-2 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('rain_pot-body')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">자산 포트폴리오</p>
            </div>
            <div class="flex items-center gap-2">
                <div class="relative w-full">
                    <label for="cash_input"><svg class="absolute left-3 top-2.5 w-5 h-5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Search"></use></svg></label>
                    <input type="text" id="cash_input" class="form-control type02 pl-10" placeholder="검색">
                </div>
                <button class="basic-hover ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
            </div>
        </div>
        <div class="w-full p-6 px-4">

            <div class="px-4 pb-4">
                <ul class="nav nav-boxed-tabs bg-back rounded p-0.5" role="tablist">
                    <li id="deposit-pot_tab01" class="nav-item w-full" role="presentation"> 
                        <button class="w-full py-1 text-basic active" data-tw-toggle="pill" data-tw-target="#deposit-pot_tab01" type="button" role="tab" aria-controls="deposit-pot_tab01" aria-selected="true">화폐</button>
                    </li>
                    <li id="deposit-pot_tab02" class="nav-item w-full" role="presentation"> 
                        <button class="w-full py-1 text-basic" data-tw-toggle="pill" data-tw-target="#deposit-pot_tab02" type="button" role="tab" aria-controls="deposit-pot_tab02" aria-selected="false">mNFT</button>
                    </li>
                </ul>
            </div>
            <div class="overflow-y-auto scrollbar h-[525px] p-4 px-4">
                <div class="tab-content">
                    <div id="deposit-pot_tab01" class="tab-pane active" role="tabpanel" aria-labelledby="deposit-pot_tab01" >
                        <div class="py-1 wallet_tit">법정화폐</div>
                        <div class="flex justify-between p-3 rounded cursor-pointer border border-solid border-primary">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/KRW.webp" alt=""><b class="text-tit font-extrabold">KRW</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/ARS.webp" alt=""><b class="text-tit font-extrabold">ARS</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/CLP.webp" alt=""><b class="text-tit font-extrabold">CLP</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/IDR.webp" alt=""><b class="text-tit font-extrabold">IDR</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/AUD.webp" alt=""><b class="text-tit font-extrabold">AUD</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/AZN.webp" alt=""><b class="text-tit font-extrabold">AZN</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/BDT.webp" alt=""><b class="text-tit font-extrabold">BDT</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/CAD.webp" alt=""><b class="text-tit font-extrabold">CAD</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/COP.webp" alt=""><b class="text-tit font-extrabold">COP</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/EGP.webp" alt=""><b class="text-tit font-extrabold">EGP</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/EUR.webp" alt=""><b class="text-tit font-extrabold">EUR</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/GBP.webp" alt=""><b class="text-tit font-extrabold">GBP</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/GHS.webp" alt=""><b class="text-tit font-extrabold">GHS</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/JPY.webp" alt=""><b class="text-tit font-extrabold">JPY</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/KES.webp" alt=""><b class="text-tit font-extrabold">KES</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                        <div class="flex justify-between p-3 rounded  cursor-pointer">
                            <p class="flex gap-2 items-center"><img class="w-7" src="/assets/custom_img/coin/KGS.webp" alt=""><b class="text-tit font-extrabold">KGS</b></p>
                            <p class="text-right"><strong class="block text-tit font-extrabold">₩ 0.00</strong><span class="block text-xs">0.00</span></p>
                        </div>
                    </div>
                    <div id="deposit-pot_tab02" class="tab-pane" role="tabpanel" aria-labelledby="deposit-pot_tab02">
                        <div class="h-80 py-10 text-center ">
                            <img class="w-48 mx-auto" src="/assets/custom_img/empty.webp" alt="">
                            <p class="-mt-5 text-basic opacity-70">코인 또는 토큰을 찾을 수 없음</p>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="p-6 px-4 border-t border-slate-200 border-solid flex items-center justify-between">
                <div>
                    <div class="form-check form-switch"> 
                        <input id="top-cash_check" class="form-check-input legal_check" type="checkbox" checked> 
                        <label class="form-check-label" for="top-cash_check">법정화폐로 보기</label> 
                    </div>
                </div>
                <div>
                    <div class="form-check form-switch"> 
                        <input id="top-cash_check02" class="form-check-input small_check" type="checkbox"> 
                        <label class="form-check-label" for="top-cash_check02">작게보기</label> 
                    </div>
                </div>
            </div>

            
            
        </div>
    </div>

    <!-- 롤오버 개요 -->
    <div class="rain_lock-body modal-body relative modal-in bg-stand rounded" [ngClass]="{ 'open': modalTab == 'rain_lock-body' }">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('rain_lock-body')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">롤오버 개요</p>
            </div>
            <button class="basic-hover ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="w-full">
            <div class="flex items-center gap-3 px-4 py-6">
                <div class="custom_select w-48">
                    <button class="btn w-32 h-9 flex px-4 items-center justify-between border-none bg-white dark:bg-back">
                        <span>모든 유형</span>
                        <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                    </button>
                    <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                        <li class="py-2 on">모든 유형</li>
                        <li class="py-2">입금</li>
                        <li class="py-2">보너스</li>
                    </ul>
                </div>
                <div class="custom_select w-48">
                    <button class="btn w-32 h-9 flex px-4 items-center justify-between border-none bg-white dark:bg-back">
                        <span>모든 상태</span>
                        <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                    </button>
                    <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                        <li class="py-2 on">모든 상태</li>
                        <li class="py-2">아직 시작하지 않았습니다.</li>
                        <li class="py-2">진행 중</li>
                        <li class="py-2">완료</li>
                        <li class="py-2">취소</li>
                        <li class="py-2">만료되었습니다.</li>
                    </ul>
                </div>
            </div>
            <div class="mt-4 px-4 h-[490px] overflow-y-auto scrollbar">
                <table class="table table-hover noline pad-s text-xs sm:text-sm">   
                    <colgroup>
                        <col width="25%">
                        <col width="25%">
                        <col class="hidden sm:table-cell" width="25%">
                        <col width="25%">
                    </colgroup>
                    <thead class="bg-back">
                        <tr class="text-center">
                            <th class="whitespace-nowrap text-left">유형</th>
                            <th class="whitespace-nowrap text-center">양</th>
                            <th class="whitespace-nowrap text-center hidden sm:table-cell">시간</th>
                            <th class="whitespace-nowrap text-right">상태</th>
                        </tr>
                    </thead>
                    <tbody class="cursor-pointer">
                        <tr (click)="modalInHandle('rain_detail-body')">
                            <td class="text-left text-tit">신규 럭키 스핀 보너스</td>
                            <td class="text-center">
                                <div class="flex items-center justify-center gap-1">₩ 6,429.02 <img class="w-3 h-3" src="/assets/custom_img/coin/USDC.webp" alt=""></div>
                            </td>
                            <td class="text-center hidden sm:table-cell">2023. 11. 8. 오전 10:21:12</td>
                            <td class="text-right text-tit">
                                <div class="flex items-center justify-end gap-2">
                                    <i class="w-1.5 h-1.5 bg-[#9ba7b4] rounded-full"></i>
                                    만료되었습니다.
                                    <svg class="w-3 h-3 ml-1 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                                </div>
                            </td>
                        </tr>
                        <tr (click)="modalInHandle('rain_detail-body')">
                            <td class="text-left text-tit">신규 럭키 스핀 보너스</td>
                            <td class="text-center">
                                <div class="flex items-center justify-center gap-1">₩ 6,429.02 <img class="w-3 h-3" src="/assets/custom_img/coin/USDC.webp" alt=""></div>
                            </td>
                            <td class="text-center hidden sm:table-cell">2023. 11. 8. 오전 10:21:12</td>
                            <td class="text-right text-tit">
                                <div class="flex items-center justify-end gap-2">
                                    <i class="w-1.5 h-1.5 bg-[#9ba7b4] rounded-full"></i>
                                    만료되었습니다.
                                    <svg class="w-3 h-3 ml-1 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex gap-2 items-center justify-end mt-4 py-4 px-4 bg-back2">
                <p class="text-xs">총 1</p>
                <div class="flex gap-0 text-base px-4 py-1 bg-back2 rounded font-medium">
                    <button class="w-6 h-6 basic-hover active font-extrabold">1</button>
                    <button class="w-6 h-6 basic-hover">2</button>
                    <button class="w-6 h-6 basic-hover">3</button>
                </div>
                <div class="flex gap-1">
                    <button class="btn-normal w-8 h-8 basic-hover rounded"><svg class="w-4 h-4 mx-auto rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                    <button class="btn-normal w-8 h-8 basic-hover rounded"><svg class="w-4 h-4 mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                </div>
            </div>

        </div>
    </div>

    <!-- 롤오버 디테일 -->
    <div class="rain_detail-body modal-body relative modal-in bg-stand rounded" [ngClass]="{ 'open': modalTab == 'rain_detail-body' }">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('rain_detail-body')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">롤오버 디테일</p>
            </div>
            <button class="basic-hover ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="w-full p-4">
            <div class="text-center pt-2 pb-6">
                <img class="w-12 mx-auto" src="./assets/custom_img/coin/USDC.webp" alt="">
                <p class="mt-1 text-tit text-base">+ <b>5.00383700</b> USDC</p>
            </div>

            <div class="flex items-center justify-between py-2">
                <div>상태</div>
                <div class="text-tit"><i class="inline-flex w-1.5 h-1.5 bg-[#9ba7b4] rounded-full"></i> 만료되었습니다.</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>유형</div>
                <div class="text-tit">신규 럭키 스핀 보너스</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>가능 게임</div>
                <div class="text-primary cursor-pointer underline" (click)="modalInHandle('rain_bonusgame-body')">게임 보기</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>생성일</div>
                <div class="text-tit">2023. 11. 8. 오전 10:21:12</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>만료</div>
                <div class="text-tit">2023. 11. 15. 오전 10:21:12</div>
            </div>

            <div class="my-3 border-t border-solid border-slate-300"></div>

            <div class="flex items-center justify-between py-2">
                <div>롤오버 배수</div>
                <div class="text-tit">60.00x</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>총 필요 롤링</div>
                <div class="text-tit">₩386,262.04</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>롤링 완료</div>
                <div class="text-tit">₩0.00</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>롤링 필요</div>
                <div class="text-tit">₩386,262.04</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>출금 가능 자금</div>
                <div class="text-tit">₩6,437.70
                </div>
            </div>
        </div>

    </div>

    <!-- 보너스를 위한 게임 -->
    <div class="rain_bonusgame-body modal-body relative modal-in bg-stand rounded" [ngClass]="{ 'open': modalTab == 'rain_bonusgame-body' }">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('rain_bonusgame-body')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">보너스를 위한 게임</p>
            </div>
            <button class="basic-hover ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="h-[670px] overflow-y-auto scrollbar w-full p-4">
            <ul class="grid grid-cols-4 gap-4">
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img1.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img2.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img3.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img4.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img5.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img6.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus/p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img7.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img8.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img1.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img2.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img3.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img4.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img5.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img6.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus/p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img7.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img8.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img1.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img2.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img3.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img4.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img5.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img6.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus/p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img7.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img8.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img1.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img2.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img3.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img4.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img5.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img6.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus/p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img7.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img8.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
            </ul>
        </div>
    </div>

</div>
