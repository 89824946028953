import { ChangeDetectorRef, Component } from "@angular/core";
import { MessageComponent } from "./message.component";
import { CommonModule } from "@angular/common";
import { Subscription } from "rxjs";
import { MessageService } from "./message.service";

@Component({
    selector: 'common-message',
    standalone: true,
    imports: [
      CommonModule,
      MessageComponent
    ],
    templateUrl: './common-message.component.html',
    styles: ``
  })
  export class CommonMessageComponent {
    private subs: Subscription[] = [];
    currentMessagess: any[] = [];

    constructor(
        private messageService: MessageService,
        private changeDetectorRef: ChangeDetectorRef
    ){}

    ngOnInit() {
        this.subs.push(
            this.messageService.getData().subscribe((v:any)=> {
                if(v) {
                    const message = v['message']
                    const onEvt = v['onEvt']
                    
                    this.currentMessagess.push({ 
                        message: message,
                        onEvt: onEvt
                    })
    
                }
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
  
  
    onEvent(index: number, evt:any) {
          this.currentMessagess[index]?.onEvt?.emit(evt)
          if(evt == 'close') {
              this.currentMessagess.splice(index, 1);
              this.changeDetectorRef.detectChanges();
          }
      }
  }