<!-- 게임 공유하기 모달 -->
<div class="modal-content">
    <div class="modal-body bg-modaldark relative rounded">
        <div class="relative flex items-center justify-end p-4">
            <button class="basic-hover" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>

        <div class="px-6 pb-10 font-semibold">
            <p class="text-tit font-extrabold text-lg text-center">게임 공유하기</p>

            <div class="flex items-center flex-wrap justify-center gap-4 mt-5">
                <a href="https://www.facebook.com/sharer.php?title={{ title }}&amp;text=&amp;u={{ hostname }}" target="_blank" rel="noopener noreferrer"><img class="w-10" src="/assets/custom_img/share_icon/share_3.webp" alt=""></a>
                <a href="https://twitter.com/share?title={{ title }}&amp;text=&amp;url={{ hostname }}" target="_blank" rel="noopener noreferrer"><img class="w-10" src="/assets/custom_img/share_icon/share_8.webp" alt=""></a>
                <a href="https://t.me/share?title={{ title }}&amp;text=&amp;url={{ hostname }}" target="_blank" rel="noopener noreferrer"><img class="w-10" src="/assets/custom_img/share_icon/share_7.webp" alt=""></a>
                <a href="https://vk.com/share.php?url={{ hostname }}&amp;title={{ title }}&amp;text=" target="_blank" rel="noopener noreferrer"><img class="w-10" src="/assets/custom_img/share_icon/share_11.webp" alt=""></a>
                <a href="https://lineit.line.me/share/ui?url={{ hostname }}&amp;title={{ title }}&amp;text=" target="_blank" rel="noopener noreferrer"><img class="w-10" src="/assets/custom_img/share_icon/share_12.webp" alt=""></a>
                <a href="https://web.skype.com/share?url={{ hostname }}&amp;title={{ title }}&amp;text=" target="_blank" rel="noopener noreferrer"><img class="w-10" src="/assets/custom_img/share_icon/share_13.webp" alt=""></a>
                <a href="https://connect.ok.ru/offer?url=url={{ hostname }}&amp;title={{ title }}&amp;imageUrl=https%3A%2F%2Fbcgame.ph%2Fshare_image.jpg" target="_blank" rel="noopener noreferrer"><img class="w-10" src="/assets/custom_img/share_icon/share_14.webp" alt=""></a>
                <a href="https://www.linkedin.com/sharing/share-offsite/?url={{ hostname }}" target="_blank" rel="noopener noreferrer"><img class="w-10" src="/assets/custom_img/share_icon/share_16.webp" alt=""></a>
                <a href="https://api.whatsapp.com/send?url={{ hostname }}&amp;title={{ title }}&amp;text=" target="_blank" rel="noopener noreferrer"><img class="w-10" src="/assets/custom_img/share_icon/share_17.webp" alt=""></a>
            </div>
        </div>
    </div>
</div>
