import { ChangeDetectorRef, Component } from '@angular/core';
import { ModalService } from './modal.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';

@Component({
  selector: 'common-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent
  ],
  templateUrl: './common-modal.component.html',
  styles: ``
})
export class CommonModalComponent {
    private subs: Subscription[] = [];
    currentModals: any[] = [];

    constructor(
        private modalService: ModalService,
        // private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subs.push(
            this.modalService.getData().subscribe((v:any)=> {
                if(v) {
                    if(v['component'] == `sign-in`) { 
                        v['optParam'] = { ...v['optParam'], tab: 'signin' } // 로그인 탭
                    } else if(v['component'] == `spin`) { // spin이 떠있으면 다시 띄우지 않는다.
                        // const k = this.currentModals.find(v => v.component == 'spin')
                        if(this.currentModals.length > 0) return; //  다른 모달이 떠 있으면 띄우지 않는다.
                    }

                    this.currentModals.push({ 
                        component: v['component'],
                        optParam: v['optParam'],
                        eventEmitter: v['eventEmitter'],
                    })
                } else {
                    this.closeAll()
                }
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    onOpen(index:number, evt:any) {
        if(this.currentModals[index].eventEmitter) {
            const { component, optParam } = this.currentModals[index]
            this.currentModals[index].eventEmitter.emit({ component: component, event: evt, optParam: optParam });
        }
    }

    onClose(index:number, evt:any) {
        if(this.currentModals[index].eventEmitter) {
            const { component, optParam } = this.currentModals[index]
            this.currentModals[index].eventEmitter.emit({ component: component, event: evt, optParam: optParam });
        }
        
        this.currentModals.splice(index, 1);
    }

    closeAll() {
        document.querySelectorAll('.modal').forEach((el)  => {
            const modal = (window as any).tailwind.Modal.getOrCreateInstance(el)
            if(modal) modal.hide()

            // const myModal = bootstrap.Modal.getOrCreateInstance(modalElem);
            // myModal.hide();
        });
        // this.changeDetectorRef.detectChanges();
    }
}
