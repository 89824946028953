<!-- 프로필 - 추천 그리고 보상 -->
<div class="modal-content">
    <div class="modal-head">
        <div class="flex items-center justify-between p-4">
            <p class="text-tit font-extrabold text-base">{{ optParam?.title }}</p>
            <button class="basic-hover" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
    </div>
    <div class="modal-body rounded overflow-hidden dark:bg-back2 bg-white">
        <div class="p-5 font-semibold">
            {{ optParam?.message }}
        </div>
        <div class="p-5">
            <div class="text-center mt-10">
                <button class="btn-green w-2/3 h-12 font-bold" data-tw-dismiss="modal">확인</button>
            </div>
        </div>
    </div>
</div>
