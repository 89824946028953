<!-- 롤오버 개요 모달 -->
<div class="modal-content overflow-hidden relative rounded" [ngClass]="{'on': modalTab }">
    <div class="modal-body relative bg-stand rounded">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <p class="text-tit font-extrabold text-base">롤오버 개요</p>
            </div>
            <button class="basic-hover ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="w-full">
            <div class="flex items-center gap-3 px-4 py-6">
                <div class="custom_select w-48">
                    <button class="btn w-32 h-9 flex px-4 items-center justify-between border-none bg-white dark:bg-back">
                        <span>모든 유형</span>
                        <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                    </button>
                    <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                        <li class="py-2 on">모든 유형</li>
                        <li class="py-2">입금</li>
                        <li class="py-2">보너스</li>
                    </ul>
                </div>
                <div class="custom_select w-48">
                    <button class="btn w-32 h-9 flex px-4 items-center justify-between border-none bg-white dark:bg-back">
                        <span>모든 상태</span>
                        <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                    </button>
                    <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                        <li class="py-2 on">모든 상태</li>
                        <li class="py-2">아직 시작하지 않았습니다.</li>
                        <li class="py-2">진행 중</li>
                        <li class="py-2">완료</li>
                        <li class="py-2">취소</li>
                        <li class="py-2">만료되었습니다.</li>
                    </ul>
                </div>
            </div>
            <div class="mt-4 px-4 h-[490px] overflow-y-auto scrollbar">
                <table class="table table-hover noline pad-s text-xs sm:text-sm">   
                    <colgroup>
                        <col width="25%">
                        <col width="25%">
                        <col class="hidden sm:table-cell" width="25%">
                        <col width="25%">
                    </colgroup>
                    <thead class="bg-back">
                        <tr class="text-center">
                            <th class="whitespace-nowrap text-left">유형</th>
                            <th class="whitespace-nowrap text-center">양</th>
                            <th class="whitespace-nowrap text-center hidden sm:table-cell">시간</th>
                            <th class="whitespace-nowrap text-right">상태</th>
                        </tr>
                    </thead>
                    <tbody class="cursor-pointer">
                        <tr (click)="modalInHandle('rain-detail-body')">
                            <td class="text-left text-tit">신규 럭키 스핀 보너스</td>
                            <td class="text-center">
                                <div class="flex items-center justify-center gap-1">₩ 6,429.02 <img class="w-3 h-3" src="/assets/custom_img/coin/USDC.webp" alt=""></div>
                            </td>
                            <td class="text-center hidden sm:table-cell">2023. 11. 8. 오전 10:21:12</td>
                            <td class="text-right text-tit">
                                <div class="flex items-center justify-end gap-2">
                                    <i class="w-1.5 h-1.5 bg-[#9ba7b4] rounded-full"></i>
                                    만료되었습니다.
                                    <svg class="w-3 h-3 ml-1 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                                </div>
                            </td>
                        </tr>
                        <tr (click)="modalInHandle('rain-detail-body')">
                            <td class="text-left text-tit">신규 럭키 스핀 보너스</td>
                            <td class="text-center">
                                <div class="flex items-center justify-center gap-1">₩ 6,429.02 <img class="w-3 h-3" src="/assets/custom_img/coin/USDC.webp" alt=""></div>
                            </td>
                            <td class="text-center hidden sm:table-cell">2023. 11. 8. 오전 10:21:12</td>
                            <td class="text-right text-tit">
                                <div class="flex items-center justify-end gap-2">
                                    <i class="w-1.5 h-1.5 bg-[#9ba7b4] rounded-full"></i>
                                    만료되었습니다.
                                    <svg class="w-3 h-3 ml-1 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex gap-2 items-center justify-end mt-4 py-4 px-4 bg-back2">
                <p class="text-xs">총 1</p>
                <div class="flex gap-0 text-base px-4 py-1 bg-back2 rounded font-medium">
                    <button class="w-6 h-6 basic-hover active font-extrabold">1</button>
                    <button class="w-6 h-6 basic-hover">2</button>
                    <button class="w-6 h-6 basic-hover">3</button>
                </div>
                <div class="flex gap-1">
                    <button class="btn-normal w-8 h-8 basic-hover rounded"><svg class="w-4 h-4 mx-auto rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                    <button class="btn-normal w-8 h-8 basic-hover rounded"><svg class="w-4 h-4 mx-auto"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                </div>
            </div>

        </div>
    </div>

    <div class="rain-detail-body modal-body relative modal-in bg-stand rounded" [ngClass]="{'open': modalTab == 'rain-detail-body'}">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('rain-detail-body')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">롤오버 디테일</p>
            </div>
            <button class="basic-hover ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="w-full p-4">
            <div class="text-center pt-2 pb-6">
                <img class="w-12 mx-auto" src="/assets/custom_img/coin/USDC.webp" alt="">
                <p class="mt-1 text-tit text-base">+ <b>5.00383700</b> USDC</p>
            </div>

            <div class="flex items-center justify-between py-2">
                <div>상태</div>
                <div class="text-tit"><i class="inline-flex w-1.5 h-1.5 bg-[#9ba7b4] rounded-full"></i> 만료되었습니다.</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>유형</div>
                <div class="text-tit">신규 럭키 스핀 보너스</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>가능 게임</div>
                <div class="text-primary cursor-pointer underline" (click)="modalInHandle('rain-bonusgame-body')">게임 보기</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>생성일</div>
                <div class="text-tit">2023. 11. 8. 오전 10:21:12</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>만료</div>
                <div class="text-tit">2023. 11. 15. 오전 10:21:12</div>
            </div>

            <div class="my-3 border-t border-solid border-slate-300"></div>

            <div class="flex items-center justify-between py-2">
                <div>롤오버 배수</div>
                <div class="text-tit">60.00x</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>총 필요 롤링</div>
                <div class="text-tit">₩386,262.04</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>롤링 완료</div>
                <div class="text-tit">₩0.00</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>롤링 필요</div>
                <div class="text-tit">₩386,262.04</div>
            </div>
            <div class="flex items-center justify-between py-2">
                <div>출금 가능 자금</div>
                <div class="text-tit">₩6,437.70
                </div>
            </div>
        </div>

    </div>

        <!-- 보너스를 위한 게임 -->
        <div class="rain-bonusgame-body modal-body relative modal-in bg-stand rounded" [ngClass]="{'open': modalTab == 'rain-bonusgame-body'}">
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <div class="flex items-center gap-2">
                <button (click)="modalInHandle('rain-bonusgame-body')"><svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                <p class="text-tit font-extrabold text-base">보너스를 위한 게임</p>
            </div>
            <button class="basic-hover ml-4" data-tw-dismiss="modal"><svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>
        </div>
        <div class="h-[660px] overflow-y-auto scrollbar w-full p-4">
            <ul class="grid grid-cols-4 gap-4">
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img1.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img2.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img3.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img4.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img5.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img6.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus/p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img7.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img8.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img1.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img2.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img3.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img4.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img5.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img6.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus/p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img7.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img8.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img1.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img2.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img3.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img4.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img5.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img6.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus/p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img7.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img8.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img1.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img2.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img3.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img4.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img5.png" alt="">
                        <p class="py-2 text-center text-tit">FA CHAI</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img6.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus/p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img7.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
                <li class="bg-modaldark">
                    <a href="javascript:;">
                        <img src="/assets/custom_img/game/game_img8.png" alt="">
                        <p class="py-2 text-center text-tit">Platipus</p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
