import { Component, EventEmitter, Input, Output } from '@angular/core';
import { passwordView } from '../../../common/common';
import { CommonModule } from '@angular/common';
import { CountdownDirective } from '@app/shared/lib/directive/countdown.directive';
import { ApiService } from '../../../service/api.service';
import moment from 'moment-timezone';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingComponent } from '../../../common/loading/loading.component';
import { Subscription } from 'rxjs';
import { ValidatorService } from '../../../service/validator.service';


@Component({
    selector: 'app-password-change-modal',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CountdownDirective,
        LoadingComponent
    ],
    templateUrl: './password-change-modal.component.html',
    styles: ``
})
export class PasswordChangeModalComponent {
    private subs: Subscription[] = [];

    @Input() options:any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    
    form1!: FormGroup;
    
    modalTab = ''
    requesting = false;
    requested = false; // 인증번호 전송
    err_message:string| null = null
    smsExpiredAt:string = '';
    submitted = false;
    sigining:boolean = false;
    isLoading:boolean = true;
    hp!:string;

    
    get passwordView() { return passwordView }
    get f() { return this.form1.controls; }
    constructor(
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private validatorService: ValidatorService,
    ) {
        this.form1 = this.formBuilder.group({
            current: ['', Validators.required],
            pwd: ['', Validators.required,],
            pwd_confirm: ['', Validators.required, this.validatorService.compare('pwd')],
            auth_code: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.fnGetData()
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.post('/settings/password', {}, false).subscribe({
            next: (jsondata) => {
                if(jsondata.success) {
                    this.hp = jsondata.data.hp;
                }
                this.err_message = jsondata.message
                this.isLoading = false;
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    onSubmit() {
        this.submitted = true;
        if(this.form1.invalid) return;

        this.sigining = true;
        this.apiService.post(`/settings/password-update`, this.form1.value, false).subscribe({
            next: (jsondata) => {
                this.sigining = false;

                if(jsondata.success) {
                    this.submitted = false;
                    this.onClose.emit('close')
                } else {
                    if(jsondata.message) this.err_message = jsondata.message
                } 
            },
            error: (err) => {
                this.sigining = false;
            },
        })
    }

    modalInHandle($event:any) {
        this.modalTab = (this.modalTab == $event) ? '' : $event;
    }

    onAuthCode() {
        this.requesting = true;
        this.apiService.post('/settings/password-code', {}, false).subscribe({
            next: (jsondata) => {
                if(jsondata.success) {
                    this.requested = true;

                    this.smsExpiredAt = moment.tz(jsondata.data.expiredAt, 'Asia/Seoul').format()
                }
                this.err_message = jsondata.message
                this.requesting = false
            }, 
            error: (e) => {
                this.requesting = false
            }
        })
    }
}
