<!-- 언어 / 법정화폐 모달 -->
<div class="modal-content">
    <div class="modal-body flex flex-wrap content-start relative">
        <button class="absolute z-10 right-5 top-5 basic-hover" data-tw-dismiss="modal"><svg class="w-5 h-5 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg></button>

        <ul class="nav nav-link-tabs type02 w-full" role="tablist">
            <li class="nav-item w-1/3 sm:w-1/4" role="presentation">
                <button class="nav-link w-full py-2 h-16" [ngClass]="{ 'active': options?.tab == 'lang-tab-1' }" type="button" (click)="modalTab('lang-tab-1')">언어</button> 
            </li>
            <li class="nav-item w-1/3 sm:w-1/4" role="presentation">
                <button class="nav-link w-full py-2 h-16" [ngClass]="{ 'active': options?.tab == 'lang-tab-2' }" type="button" (click)="modalTab('lang-tab-2')">법정화폐로 보기</button> 
            </li>
            <li class="nav-item flex-1"><div class="nav-link h-16"></div></li>
        </ul> 
        <div class="tab-content w-full p-6 overflow-y-auto scrollbar h-auto sm:h-[504px]"> 
            <div class="tab-pane leading-relaxed overflow-y-auto" [ngClass]="{ 'active': options?.tab == 'lang-tab-1' }">
                <ul class="grid grid-cols-2 sm:grid-cols-4 w-full gap-3">
                    <li *ngFor="let item of langOption">
                        <button class="btn-top w-full p-5 py-4 text-left" [ngClass]="{ 'active': lang == item.code}" (click)="setLang(item.code)">{{ item.name }}</button>
                    </li>
                </ul>
            </div>
            <div class="tab-pane leading-relaxed" [ngClass]="{ 'active': options?.tab == 'lang-tab-2' }">
                <ul class="grid grid-cols-2 sm:grid-cols-4 w-full gap-3">
                    <li><button class="btn-top w-full flex items-center gap-2 h-14 px-4 active">
                        <img class="w-8 h-8" [src]="'/assets/custom_img/coin/' + currency + '.webp'">
                        <p class="text-tit">{{ currency }}</p>
                    </button></li>
                </ul>
                <ul class="grid grid-cols-2 sm:grid-cols-4 w-full gap-3 mt-4">
                    <li *ngFor="let item of currencyOption">
                        <button class="btn-top w-full flex items-center gap-2 h-14 px-4" [ngClass]="{ 'active': currency == item.code}" (click)="setCurrency(item.code)">
                            <img class="w-8 h-8" [src]="'/assets/custom_img/coin/' + item.image">
                            <p>{{ item.code }}</p>
                            <span class="text-sub truncate">{{ item.name }}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
