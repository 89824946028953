import { Component, ElementRef, HostListener, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { LoadingComponent } from '../loading/loading.component';
import moment from 'moment-timezone';
import { environment } from '../../environments/environment';
import { ApiService } from '../../service/api.service';
import { CommonModule } from '@angular/common';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';
import { EmojiComponent } from '../emoji/emoji.component';
import { GiphyComponent } from '../emoji/giphy.component';
import { FormsModule } from '@angular/forms';
import { currentUserInfo } from '../../app/auth/auth.service';
import { ProfileDirective } from '@app/shared/lib/directive/profile.directive';
import { Subscription } from 'rxjs';
import { DirectMessageService } from './direct-message.service';

@Component({
    selector: 'app-direct-message',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        LoadingComponent,
        MomentPipe,
        EmojiComponent,
        GiphyComponent,
        ProfileDirective
    ],
    templateUrl: './direct-message.component.html',
    styles: `
.send-input > div {width:100%; transition:width 0.3s; }
.send-input.focus-on > div {width:calc(100% - 65px); }
.send-input button.send_btn {position:absolute; right:0; top:0; width:60px; height:100%; transform:translateX(100px); transition:transform 0.3s; }
.send-input.focus-on button.send_btn {transform:translateX(0); }

    `,
    encapsulation: ViewEncapsulation.Emulated
})
export class DirectMessageComponent {
    private subs: Subscription[] = [];

    currentUser!:currentUserInfo | null;
    
    giphyItems:any = environment.GIPHY_ITEMS

    showEmoji = false
    showGiphy = false
    chat_focus = false;
    chat_input:string = ''
    directMessgage!:any

    isLoading:boolean = false;
    
    @ViewChildren('chatItems') chatItems!: QueryList<any>;
    @ViewChild('chatContent') chatContent!: ElementRef;
    @ViewChild('chatInputEl') chatInputEl!: ElementRef;
    @ViewChild('chatInputEmoji') chatInputEmoji!: ElementRef;
    @ViewChild('chatInputGiphy') chatInputGiphy!: ElementRef;
    @ViewChild('chatInputSend') chatInputSend!: ElementRef;
    
    @HostListener('document:click', ['$event'])
    document_click($event:any) {
        if(this.chatInputEl.nativeElement.contains($event.target)) {
        } else if(this.chatInputEmoji.nativeElement.contains($event.target)) {
        } else if(this.chatInputGiphy.nativeElement.contains($event.target)) {
        } else if(this.chatInputSend.nativeElement.contains($event.target)) {
        } else {
            // 입력내용이 없으면
            if(!this.chat_input) this.chat_focus = false;
        }
    }
    
    get chatInput() { return this.chatInputEl.nativeElement }
    constructor(
        private apiService: ApiService,
        private directMessageService: DirectMessageService
    ) {}

    ngOnInit() {
        this.subs.push(
            this.directMessageService.getData().subscribe(v => {
                this.directMessgage = v;
            })
        )
    }

    ngAfterViewInit(): void {
        this.subs.push(
            this.chatItems.changes.subscribe(v => {
                this.chatContent.nativeElement.scrollTop = this.chatContent.nativeElement.scrollHeight;
            })
        )
        // console.log(`ngAfterViewInit`)
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    showDateLine(index:number){
        if(index == 0) return true;

        const d1 = moment(this.directMessgage[index].createdAt).format('YYYYMMDD')
        const d2 = moment(this.directMessgage[index-1].createdAt).format('YYYYMMDD')
        
        return (d1 == d2) ? false : true;
    }

    // keydown 이벤트로 잡아야 함
    onEnter($event:any) {
        if ($event.isComposing || $event.keyCode === 229) return; 
        if ($event.key === "Enter") this.sendMessage()

    }

    sendMessage() {
        this.showEmoji=false;
        this.showGiphy=false;
        if(!this.chat_input) return;

        const message = this.chat_input;
        this.isLoading = true;
        this.apiService.post('/direct-message/send', { message: message }, false).subscribe({
            next: jsondata => {
                this.isLoading = false;
                if(jsondata.success) {
                    this.chat_input = '' 
                    this.directMessgage.push({ CMD:'MESSAGE', ...jsondata.data })
                } else {
                    this.directMessgage.push({ 
                        CMD: 'SYSTEM', 
                        message: jsondata.message,
                        createdAt: moment()
                    })
                    console.log(this.directMessgage)
                }
                
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    getGiphyImage(str:string) {
        const regex = /^\/(giphy)\s+\[(\d+)\]$/;
        const match = str.match(regex);
        if(match) {
            const num = match[2]
            return this.giphyItems[num]
        }

        return ''
    }
}
