import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: 'app-message',
    standalone: true,
    imports: [
    ],
    templateUrl: './message.component.html',
    styles: ``
  })
  export class MessageComponent {
    @Input() message: string = '';

    @Output() onEvent: EventEmitter<any> = new EventEmitter<any>()

    @ViewChild('messageEl', { static: true }) alterEl!: ElementRef;
    
    constructor() {}

    ngAfterContentInit() {
        setTimeout(() => {
            this.alterEl.nativeElement.classList.add('show');  
            
            setTimeout(() => {
                this.alterEl.nativeElement.classList.remove('show'); 
                this.onEvent.emit('close') 
            }, 4000);
        }, 500);
    }
}
  